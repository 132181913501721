<template>
  <div v-if="enabled" class="changelog">
    <wklr-icon-btn type="changelog" size="normal" />
    <AnnounceKit catch-click=".changelog" :widget="widgetUri" :widget-style="style" />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'nuxt-property-decorator';
import WklrIconBtn from '@/components/shared/wklr-icon-btn.vue';
import AnnounceKit from 'announcekit-vue';
import { AnnounceKit as AnnounceKitConstants } from '@/constants';

@Component({ components: { AnnounceKit, WklrIconBtn } })
export default class ChangelogBadge extends Vue {
  get style(): Record<string, string> {
    return {
      position: 'absolute',
      top: '-4px',
      right: '0px',
    };
  }

  get widgetUri(): string {
    return `https://announcekit.co/widgets/v2/${AnnounceKitConstants.WidgetToken}`;
  }

  get enabled(): boolean {
    return AnnounceKitConstants.Enabled;
  }
}
</script>

<style lang="scss">
.changelog {
  position: relative;
  padding-left: 2px;
  padding-top: 2px;

  &:hover {
    cursor: pointer;
  }
}
</style>
