import type { UsersApi } from '@gen/wklr-backend-api/v1/api/users-api';
import type {
  DocRecordTiny,
  GetMeResponse,
  ListPracticesForUserProfile200Response,
  OrganizationOrganizationType,
  PutUserProfileParamsBodyPracticesInner,
  GetPerDocumentQuotaResponse,
  UserNewsCategoriesResponse,
} from '@gen/wklr-backend-api/v1/model';
import { CacheTTL } from '../constants';

export type OrganizationType =
  // 1: 企業法務部
  | 'corporate-legal'
  // 2: 法律事務所
  | 'law-office'
  // 3: 士業事務所
  | 'professional-office'
  // 999, null: その他
  | 'other';

export class UsersRepository {
  constructor(private api: UsersApi) {}
  private organizationType: OrganizationType | null = null;

  async getMe(): Promise<GetMeResponse> {
    // キャッシュせず、常に最新の結果を取りに行く
    const res = await this.api.getMe();
    this.setOrganizationType(res.data.organization.organizationType);
    return res.data;
  }

  async putUserProfile(
    apprenticePeriod: number | null,
    practices: PutUserProfileParamsBodyPracticesInner[],
  ): Promise<void> {
    const res = await this.api.putUserProfile({
      apprenticePeriod,
      practices: practices.map((p) => ({ practiceId: p.practiceId, experienceId: p.experienceId })),
    });
    return res.data;
  }

  async skipInputUserProfile(): Promise<void> {
    await this.api.skipInputUserProfile();
  }

  async listPracticesForUserProfile(): Promise<ListPracticesForUserProfile200Response> {
    const res = await this.api.listPracticesForUserProfile();
    return res.data;
  }

  async getOrganizationType(): Promise<OrganizationType> {
    return (
      this.organizationType ||
      (await (async () => {
        await this.getMe();
        return this.organizationType!;
      })())
    );
  }

  async purchasedDocuments(size: number): Promise<DocRecordTiny[]> {
    const { data } = await this.api.getPurchasedDocuments(0, size);
    return data;
  }

  async getPerDocumentQuota(docId: string): Promise<GetPerDocumentQuotaResponse> {
    const { data } = await this.api.getPerDocumentQuota(docId);
    return data;
  }

  async getUserNewsCategories(): Promise<UserNewsCategoriesResponse> {
    const { data } = await this.api.getUserNewsCategories();
    return data;
  }

  async putUserNewsCategories(categoryId: string, insertIndex: number): Promise<void> {
    await this.api.putUserNewsCategories({ categoryId, insertIndex });
  }

  setOrganizationType(type: OrganizationOrganizationType | null): void {
    switch (type?.id) {
      case '1':
        this.organizationType = 'corporate-legal';
        break;
      case '2':
        this.organizationType = 'law-office';
        break;
      case '3':
        this.organizationType = 'professional-office';
        break;
      default:
        this.organizationType = 'other';
    }
  }
}
