/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PublicRequestSearchQuery } from '../model';
// @ts-ignore
import { PublicSearchResult } from '../model';
// @ts-ignore
import { RequestSearchQuery } from '../model';
// @ts-ignore
import { SearchResult } from '../model';
/**
 * SearchesApi - axios parameter creator
 * @export
 */
export const SearchesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 検索フォームに入力されている内容に応じてサジェストを返す
         * @summary 検索フォームに入力されている内容に応じてサジェストを返す
         * @param {string} q 検索クエリ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompleteSearch: async (q: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getCompleteSearch', 'q', q)
            const localVarPath = `/complete/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検索ワードや条件に合致する書籍のリストが取得され、配列として返される。 `/search` とは異なりパブリックなアクセスを前提とする
         * @summary 検索条件に合致する書籍の配列を取得する公開エンドポイント
         * @param {PublicRequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
         * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicSearchDocuments: async (q?: PublicRequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検索ワードや条件に合致する書籍のリストが、契約しているプラン内容に応じて取得され、配列として返される
         * @summary 検索条件に合致する書籍の配列を取得する
         * @param {RequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
         * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDocuments: async (q?: RequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchesApi - functional programming interface
 * @export
 */
export const SearchesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchesApiAxiosParamCreator(configuration)
    return {
        /**
         * 検索フォームに入力されている内容に応じてサジェストを返す
         * @summary 検索フォームに入力されている内容に応じてサジェストを返す
         * @param {string} q 検索クエリ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompleteSearch(q: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompleteSearch(q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検索ワードや条件に合致する書籍のリストが取得され、配列として返される。 `/search` とは異なりパブリックなアクセスを前提とする
         * @summary 検索条件に合致する書籍の配列を取得する公開エンドポイント
         * @param {PublicRequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
         * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicSearchDocuments(q?: PublicRequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicSearchDocuments(q, from, size, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検索ワードや条件に合致する書籍のリストが、契約しているプラン内容に応じて取得され、配列として返される
         * @summary 検索条件に合致する書籍の配列を取得する
         * @param {RequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
         * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchDocuments(q?: RequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchDocuments(q, from, size, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchesApi - factory interface
 * @export
 */
export const SearchesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchesApiFp(configuration)
    return {
        /**
         * 検索フォームに入力されている内容に応じてサジェストを返す
         * @summary 検索フォームに入力されている内容に応じてサジェストを返す
         * @param {string} q 検索クエリ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompleteSearch(q: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getCompleteSearch(q, options).then((request) => request(axios, basePath));
        },
        /**
         * 検索ワードや条件に合致する書籍のリストが取得され、配列として返される。 `/search` とは異なりパブリックなアクセスを前提とする
         * @summary 検索条件に合致する書籍の配列を取得する公開エンドポイント
         * @param {PublicRequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
         * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicSearchDocuments(q?: PublicRequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options?: any): AxiosPromise<PublicSearchResult> {
            return localVarFp.publicSearchDocuments(q, from, size, orderBy, order, options).then((request) => request(axios, basePath));
        },
        /**
         * 検索ワードや条件に合致する書籍のリストが、契約しているプラン内容に応じて取得され、配列として返される
         * @summary 検索条件に合致する書籍の配列を取得する
         * @param {RequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
         * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchDocuments(q?: RequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options?: any): AxiosPromise<SearchResult> {
            return localVarFp.searchDocuments(q, from, size, orderBy, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchesApi - interface
 * @export
 * @interface SearchesApi
 */
export interface SearchesApiInterface {
    /**
     * 検索フォームに入力されている内容に応じてサジェストを返す
     * @summary 検索フォームに入力されている内容に応じてサジェストを返す
     * @param {string} q 検索クエリ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchesApiInterface
     */
    getCompleteSearch(q: string, options?: AxiosRequestConfig): AxiosPromise<Array<string>>;

    /**
     * 検索ワードや条件に合致する書籍のリストが取得され、配列として返される。 `/search` とは異なりパブリックなアクセスを前提とする
     * @summary 検索条件に合致する書籍の配列を取得する公開エンドポイント
     * @param {PublicRequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
     * @param {number} [from] 検索結果の何件目から表示するのか
     * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
     * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
     * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchesApiInterface
     */
    publicSearchDocuments(q?: PublicRequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options?: AxiosRequestConfig): AxiosPromise<PublicSearchResult>;

    /**
     * 検索ワードや条件に合致する書籍のリストが、契約しているプラン内容に応じて取得され、配列として返される
     * @summary 検索条件に合致する書籍の配列を取得する
     * @param {RequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
     * @param {number} [from] 検索結果の何件目から表示するのか
     * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
     * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
     * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchesApiInterface
     */
    searchDocuments(q?: RequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options?: AxiosRequestConfig): AxiosPromise<SearchResult>;

}

/**
 * SearchesApi - object-oriented interface
 * @export
 * @class SearchesApi
 * @extends {BaseAPI}
 */
export class SearchesApi extends BaseAPI implements SearchesApiInterface {
    /**
     * 検索フォームに入力されている内容に応じてサジェストを返す
     * @summary 検索フォームに入力されている内容に応じてサジェストを返す
     * @param {string} q 検索クエリ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchesApi
     */
    public getCompleteSearch(q: string, options?: AxiosRequestConfig) {
        return SearchesApiFp(this.configuration).getCompleteSearch(q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検索ワードや条件に合致する書籍のリストが取得され、配列として返される。 `/search` とは異なりパブリックなアクセスを前提とする
     * @summary 検索条件に合致する書籍の配列を取得する公開エンドポイント
     * @param {PublicRequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
     * @param {number} [from] 検索結果の何件目から表示するのか
     * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
     * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
     * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchesApi
     */
    public publicSearchDocuments(q?: PublicRequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return SearchesApiFp(this.configuration).publicSearchDocuments(q, from, size, orderBy, order, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検索ワードや条件に合致する書籍のリストが、契約しているプラン内容に応じて取得され、配列として返される
     * @summary 検索条件に合致する書籍の配列を取得する
     * @param {RequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
     * @param {number} [from] 検索結果の何件目から表示するのか
     * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
     * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
     * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchesApi
     */
    public searchDocuments(q?: RequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return SearchesApiFp(this.configuration).searchDocuments(q, from, size, orderBy, order, options).then((request) => request(this.axios, this.basePath));
    }
}
