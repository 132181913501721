import type { AxiosResponse } from 'axios';
import type { HistoriesApi } from '@gen/wklr-backend-api/v1/api/histories-api';
import { throttle } from 'throttle-debounce';
import type {
  DocBrowseEventExcerpt,
  GetHistoriesDocumentsResponse,
  GetHistoriesSearchesResponse,
  SearchEvent,
} from '@gen/wklr-backend-api/v1/model';
import { getKeyFromSeq } from '../utility';
import { parseStringToPartialSearchQuery } from '../utils/searchUtils';
import type { PartialSearchQuery } from '../types/SearchQuery';

/** 履歴の範囲情報をフロントエンドで埋め込むための型 */
export interface DocBrowseEventRange {
  /** 開始ページのページ番号 (0-index) */
  fromSeq: number;
  /** 終了ページのページ番号 (0-index) */
  toSeq: number;
  /** 開始ページのセクション */
  fromKey: number | undefined;
  /** 終了ページのセクション */
  toKey: number | undefined;
}

/** API のレスポンスに範囲情報を追加した型 */
export type DocBrowseEventExcerptWithRange = DocBrowseEventExcerpt & {
  ranges?: DocBrowseEventRange[];
};

/** API のレスポンスの 'excerpt' をフロントで追加した物で置き換える型 */
type GetHistoriesDocumentsResponseWithRange = Omit<GetHistoriesDocumentsResponse, 'excerpt'> & {
  excerpt: DocBrowseEventExcerptWithRange[];
};

/** API レスポンスの query が文字列になっている部分をオブジェクトとしてパースした型 */
export type ParsedSearchEvent = Omit<SearchEvent, 'query'> & { query: PartialSearchQuery };

/** API のレスポンスの `excerpt` をフロントでパースしたもので置き換えた型 */
type GetHistoriesDocumentsResponseWithParsedQuery = Omit<GetHistoriesSearchesResponse, 'excerpt'> & {
  excerpt: ParsedSearchEvent[];
  /** excerpt の内、不正な値が含まれる数 */
  // FIXME: api-history を利用しているため不正な値が入ってくる場合があるためにその差分をフロントで保持している
  invalidCount: number;
};

export class HistoriesRepository {
  constructor(private api: HistoriesApi) {}

  /**
   * ドキュメントのページを閲覧したタイミングで発生させるヒストリーイベント
   * pdf viewer のみで実行されている
   *
   * TODO: 0-index と 1-index の実際を調査して正しく実装する
   *
   * @param docId 閲覧しているドキュメントの ID
   * @param eventId イベントの発生元を同定させるための ID
   * @param seenPages 閲覧しているページ番号の配列（ 1-index ）
   * @returns
   */
  documentBrowsePages = throttle(
    2000, // interval
    async (docId: string, eventId: string, seenPages: number[]): Promise<AxiosResponse<void>> => {
      const pages = seenPages.map((n) => n - 1); // API 側は 0-index を期待している（？）ので変換する
      return this.api.postHistoriesDocuments({ docId, eventId, seqs: JSON.stringify(pages) });
    },
    { noTrailing: false },
  );

  async getDocHistories(offset: number, size: number): Promise<GetHistoriesDocumentsResponseWithRange> {
    const res = await this.api.getBrowseHistoryDocuments(offset, size);

    const excerpt: DocBrowseEventExcerptWithRange[] = Array.from(res.data.excerpt)
      .filter(({ document }) => document)
      .map((entry) => {
        const ranges: { from: number; to: number }[] = [];
        let currentRanges!: { from: number; to: number };

        [...entry.seqs, /** sentinel */ Infinity].forEach((seq) => {
          if (currentRanges === void 0) {
            // 初期化
            currentRanges = { from: seq, to: seq };
          } else if (seq < currentRanges.from) {
            // 閲覧したページが連続していなくても、ある程度 (10) より離れていなければ一区間にくっつけるという戦略とのこと
            // FIXME: 10とは？
            if (currentRanges.from - seq > 10) {
              // 離れすぎているので切り離す
              ranges.push(currentRanges);
              currentRanges = { from: seq, to: seq };
            } else {
              // まだ十分近いのでくっつけてしまう
              currentRanges.from = seq;
            }
          } else if (currentRanges.to < seq) {
            if (seq - currentRanges.from > 10) {
              // 離れすぎているので切り離す
              ranges.push(currentRanges);
              currentRanges = { from: seq, to: seq };
            } else {
              // まだ十分近いのでくっつけてしまう
              currentRanges.to = seq;
            }
          } else {
            // currentRangesの範囲内での閲覧なので更新なし
          }
        });

        return {
          ...entry,
          ranges: ranges.map<DocBrowseEventRange>(({ from, to }) => ({
            fromSeq: from,
            toSeq: to,
            fromKey: getKeyFromSeq(entry.document.toc, from),
            toKey: getKeyFromSeq(entry.document.toc, to),
          })),
        };
      });

    return { ...res.data, excerpt };
  }

  async getSearchHistories(offset: number, size: number): Promise<GetHistoriesDocumentsResponseWithParsedQuery> {
    const res = await this.api.getHistorySearchConditions(offset, size);
    const excerpt: ParsedSearchEvent[] = res.data.excerpt
      .map((excerpt) => {
        try {
          const query = parseStringToPartialSearchQuery(excerpt.query);
          return { ...excerpt, query };
        } catch (e) {
          console.error(e);
          return undefined;
        }
      })
      .filter((x): x is ParsedSearchEvent => x !== undefined);
    return { ...res.data, excerpt, invalidCount: res.data.excerpt.length - excerpt.length };
  }
}
