import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import VueLib from 'vue';
import merge from 'lodash.mergewith';
import * as Sentry from '@sentry/browser';
import { Dedupe, ExtraErrorData, ReportingObserver, RewriteFrames, Vue } from '@sentry/integrations';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
export default function (ctx, inject) {
  /* eslint-disable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  var config = {
    dsn: "https://fe153aaa8b8f422dbdf70ff2eea9ff4a@o1262758.ingest.sentry.io/6441919",
    environment: "production",
    ignoreErrors: ["DOMNodeInserted"],
    release: "dc0a1af64765582d276eff75b1be94bfdfe5294c",
    tracesSampleRate: 0.01
  };
  var runtimeConfigKey = "sentry";
  if (ctx.$config && runtimeConfigKey && ctx.$config[runtimeConfigKey]) {
    merge(config, ctx.$config[runtimeConfigKey].config, ctx.$config[runtimeConfigKey].clientConfig);
  }
  config.integrations = [new Dedupe(), new ExtraErrorData(), new ReportingObserver(), new RewriteFrames(), new Vue(_objectSpread({
    Vue: VueLib
  }, {
    "attachProps": true,
    "logErrors": false,
    "tracing": true,
    "tracingOptions": {
      "hooks": ["mount", "update"],
      "timeout": 2000,
      "trackComponents": true
    }
  }))];
  config.integrations.push(new TracingIntegrations.BrowserTracing({
    "beforeNavigate": function beforeNavigate(context) {
      return _objectSpread(_objectSpread({}, context), {}, {
        name: location.pathname.replace(/\/(document|binder)\/[^/?#]+/, '/$1/:id').replace(/\/directories\/\d+\/\d+/, '/directories/:groupId/:areaId')
      });
    }
  }));

  /* eslint-enable object-curly-spacing, quote-props, quotes, key-spacing, comma-spacing */
  Sentry.init(config);
  inject('sentry', Sentry);
  ctx.$sentry = Sentry;
}