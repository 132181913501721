import Vue from 'vue';
import { IncrementalIdGenerator } from '@/utility';

const observingEntries: { [key: string]: any } = {};
const idGenerator = new IncrementalIdGenerator();

const observer: IntersectionObserver | undefined = (() => {
  if (typeof IntersectionObserver !== 'function') return undefined;
  return new IntersectionObserver((entries, _) => {
    entries.forEach((entry) => {
      const uid = (entry.target as HTMLElement).dataset['uid'];
      if (uid && observingEntries[uid]) {
        observingEntries[uid].isInview = entry.isIntersecting;
      }
    });
  });
})();

Vue.prototype.$registerIntersectionObserver = (target: HTMLElement, component: any): string => {
  if (observer === undefined) throw new Error('intersection observer is not supported');

  const uniqueId: string | undefined = idGenerator.newId.toString();

  target.dataset['uid'] = uniqueId;

  if (!Object.prototype.hasOwnProperty.call(component, 'isInview') && typeof component.isInview === 'boolean') {
    throw new Error('you need to implement isInview property as boolean for component to observe');
  }

  observingEntries[uniqueId] = component;
  observer.observe(target);
  return uniqueId;
};

Vue.prototype.$resignIntersectionObserver = (uid: string) => {
  delete observingEntries[uid];
};
