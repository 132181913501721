import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.ends-with.js";
import "core-js/modules/es.string.replace.js";
import { getDebugHost } from '@/utils/debugUtils';
import Vue from 'vue';
var getHost = process.env.NODE_ENV === 'development' ? function () {
  var host = getDebugHost();
  return host || location.host;
} : function () {
  return location.host;
};
// TODO: featureToggle に移行する
export var isProd = function isProd() {
  return getHost().endsWith('.legalscape.jp');
};
export var hostEquals = function hostEquals() {
  var host = getHost();
  if (host.endsWith('.dev.legalscape.dev')) {
    host = host.replace(/\.dev\.legalscape\.dev$/, '.legalscape.jp');
  }
  if (host.endsWith('.stage.legalscape.dev')) {
    host = host.replace(/\.stage\.legalscape\.dev$/, '.legalscape.jp');
  }
  for (var _len = arguments.length, hosts = new Array(_len), _key = 0; _key < _len; _key++) {
    hosts[_key] = arguments[_key];
  }
  return hosts.some(function (h) {
    return h === host;
  });
};

/** アクセスされたドメインに基づくconfiguration群 */
export var $domain = {
  /** 献本用のモード（一冊の書籍の閲覧だけができる）かどうか */
  isKenpon: hostEquals('kenpon.legalscape.jp'),
  /** MHM 様社内ドメイン */
  isMHM: hostEquals('mhm-global.legalscape.jp'),
  /** ??? */
  isLite: hostEquals('lite.legalscape.jp'),
  /** 西村あさひ */
  isNA: hostEquals('jurists.legalscape.jp'),
  /** MHMマイページ会員 */
  isMHMMypage: hostEquals('mhm-portal.legalscape.jp'),
  /** 商事法務会員 */
  isSHKommentar: hostEquals('shojihomu-kommentar.legalscape.jp'),
  /** 商事法務会員（図書館向け） */
  isSHKommentarLibrary: hostEquals('shojihomu-kommentar-library.legalscape.jp'),
  /** 電子コンメトライアル */
  isKommentarTrial: hostEquals('kommentar-trial.legalscape.jp'),
  /** パーソナル */
  isPersonal: hostEquals('personal.legalscape.jp')
};
Vue.prototype.$domain = $domain;
export var useDomain = function useDomain() {
  var _useNuxtApp = useNuxtApp(),
    $domain = _useNuxtApp.$domain;
  return $domain;
};