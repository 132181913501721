<template>
  <v-menu content-class="help-menu" offset-x offset-y right>
    <template #activator="{ on, value }">
      <v-btn icon class="help-button" :class="value && '-active'" title="ヘルプ" v-on="on">
        <v-icon size="18">mdi-help</v-icon>
      </v-btn>
    </template>
    <v-list two-line>
      <v-list-item v-if="manualLink" :href="manualLink" target="_blank">
        <v-list-item-content>
          <v-list-item-title><v-icon left>mdi-comment-question</v-icon>マニュアル・よくある質問</v-list-item-title>
          <v-list-item-subtitle> 使い方が分からない場合は、まずはこちらをご覧ください </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :href="supportEmailURI('sjis')" target="_blank">
        <v-list-item-content>
          <v-list-item-title><v-icon left>mdi-email-outline</v-icon>ユーザーサポート</v-list-item-title>
          <v-list-item-subtitle>
            <template v-if="manualLink">
              プログラムの不具合や、マニュアルを見ても解決しない疑問をお送りください
            </template>
            <template v-else> 製品の利用に関して不明な点がございましたらメールにてお送りください </template>
            <p v-if="showAttention" class="attention">
              ※メールソフトが起動します。文面が文字化けする場合は<a :href="supportEmailURI('utf-8')">こちら</a
              >をクリックしてください
            </p>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item :href="bookListURL" target="_blank">
        <v-list-item-content>
          <v-list-item-title><v-icon left>mdi-book-open-variant</v-icon>掲載書籍一覧</v-list-item-title>
          <v-list-item-subtitle> 掲載書籍一覧です(Google スプレッドシートに移動します) </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <!-- <v-list-item v-if="isForumLinkShown">
        <v-list-item-avatar>
          <v-icon x-large>mdi-forum</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>フォーラム</v-list-item-title>
          <v-list-item-subtitle>
            準備中
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item> -->

      <v-list-item>
        <v-list-item-action class="action-area">
          <v-btn text color="primary">閉じる</v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { bookListURL as _bookListURL } from '@/constants';

@Component
export default class SearchBarHelpMenu extends Vue {
  bookListURL = _bookListURL;

  @Prop()
  supportEmailURI!: (encoding: 'utf-8' | 'sjis') => string;

  @Prop()
  showAttention!: boolean;

  @Prop()
  manualLink!: string | null;

  @Prop()
  isForumLinkShown!: boolean;
}
</script>

<style lang="scss">
@import '@/../node_modules/vuetify/src/styles/styles.sass';
.help-button {
  background: white;
  border: 2px solid #8f8f8f;
  margin-left: 8px;

  &.-active {
    border-color: $primary;

    .v-icon {
      color: $primary !important;
    }

    &:before {
      background: white !important;
    }
  }

  // Vuetify のスタイルを上書き
  margin-top: 8px;
  height: 28px !important;
  width: 28px !important;
  // Vuetify のスタイルを上書き
}

.help-menu {
  width: 412px;

  a {
    text-decoration: none;
  }

  .action-area {
    flex-grow: 1;
    justify-content: flex-end;
    margin: 0 !important;

    .v-btn {
      font-weight: bold;
      margin-right: -8px;
    }
  }

  // Vuetify のスタイルを上書き
  .v-list {
    padding-bottom: 0;
  }

  .v-list-item {
    padding: 0 24px;

    &:before {
      background: $primary !important;
    }

    &:hover {
      &:before {
        opacity: 0.1 !important;
      }

      .v-icon {
        color: $primary;
      }
      .v-list-item__title {
        color: $primary;
      }
    }

    .v-list-item__title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 12px !important;
    }

    .v-list-item__subtitle {
      overflow: visible;
      white-space: normal;

      > .attention {
        font-size: 12px;
        margin: 8px 0 0;
      }
    }

    .v-list-item__content {
      padding: 16px 0;
      font-size: 14px;
    }
  }
  // Vuetify のスタイルを上書き
}

body.is-home {
  .help-menu {
    margin-top: 16px;
  }
}

body:not(.is-home) {
  .help-menu {
    top: calc(var(--header-height) + 16px) !important;
    right: 28px !important;
    left: auto !important;
  }
}
</style>
