import { isDebugModeEnabled } from '@/utils/debugUtils';
import Vue from 'vue';
export default Vue.extend({
  render: function render(h) {
    // プロダクションにデバッグ用のコードが混ざらないように process.env を参照して tree shaking を効かせる
    var Component = process.env.NODE_ENV === 'development' && isDebugModeEnabled() ? h('nuxt-link', {
      class: 'debug-label',
      props: {
        to: '/debug'
      }
    }, 'DEBUG') : h();
    return Component;
  }
});