export const byKeySorter = <T>(key: keyof T): ((lhv: T, rhv: T) => -1 | 0 | 1) => {
  return (lhv, rhv) => {
    if (lhv[key] < rhv[key]) return -1;
    if (lhv[key] > rhv[key]) return 1;
    return 0;
  };
};

export const uniqArray = <T>(array: T[]): T[] => {
  const uniquedArray = [];
  for (const elem of array) {
    if (uniquedArray.indexOf(elem) < 0) uniquedArray.push(elem);
  }
  return uniquedArray;
};

export const getMappedObject = <K, T extends { key: K }>(array: T[]): Map<K, T> =>
  new Map(array.map((k) => [k.key, k]));
