/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ResponseSearchQuery } from './response-search-query';
import { SearchResultEntry } from './search-result-entry';

/**
 * 検索結果の情報
 * @export
 * @interface SearchResult
 */
export interface SearchResult {
    /**
     * 
     * @type {ResponseSearchQuery}
     * @memberof SearchResult
     */
    'query': ResponseSearchQuery;
    /**
     * 検索にヒットした資料の件数
     * @type {number}
     * @memberof SearchResult
     */
    'total': number;
    /**
     * 検索結果を何件目から表示させるのか
     * @type {number}
     * @memberof SearchResult
     */
    'from': number;
    /**
     * 表示開始位置から何件まで表示させるのか
     * @type {number}
     * @memberof SearchResult
     */
    'size': number;
    /**
     * 検索結果の並び替えのルール
     * @type {string}
     * @memberof SearchResult
     */
    'orderBy'?: SearchResultOrderByEnum;
    /**
     * 検索結果を昇順・降順どちらで表示させるのか
     * @type {string}
     * @memberof SearchResult
     */
    'order': SearchResultOrderEnum;
    /**
     * 検索にヒットした資料の配列
     * @type {Array<SearchResultEntry>}
     * @memberof SearchResult
     */
    'entries': Array<SearchResultEntry>;
}

export const SearchResultOrderByEnum = {
    PublishedOn: 'publishedOn',
    Title: 'title',
    Authors: 'authors',
    Publisher: 'publisher'
} as const;

export type SearchResultOrderByEnum = typeof SearchResultOrderByEnum[keyof typeof SearchResultOrderByEnum];
export const SearchResultOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;

export type SearchResultOrderEnum = typeof SearchResultOrderEnum[keyof typeof SearchResultOrderEnum];


