/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CountsByKey } from '../model';
// @ts-ignore
import { TotalCount } from '../model';
/**
 * GuidelinesApi - axios parameter creator
 * @export
 */
export const GuidelinesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ガイドラインの発行者別カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuidelineCountByPublisher: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/guidelines/publishers/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ガイドラインの全数カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuidelinesCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/guidelines/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GuidelinesApi - functional programming interface
 * @export
 */
export const GuidelinesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GuidelinesApiAxiosParamCreator(configuration)
    return {
        /**
         * ガイドラインの発行者別カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuidelineCountByPublisher(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountsByKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuidelineCountByPublisher(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ガイドラインの全数カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGuidelinesCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGuidelinesCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GuidelinesApi - factory interface
 * @export
 */
export const GuidelinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GuidelinesApiFp(configuration)
    return {
        /**
         * ガイドラインの発行者別カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuidelineCountByPublisher(options?: any): AxiosPromise<CountsByKey> {
            return localVarFp.getGuidelineCountByPublisher(options).then((request) => request(axios, basePath));
        },
        /**
         * ガイドラインの全数カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGuidelinesCount(options?: any): AxiosPromise<TotalCount> {
            return localVarFp.getGuidelinesCount(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GuidelinesApi - interface
 * @export
 * @interface GuidelinesApi
 */
export interface GuidelinesApiInterface {
    /**
     * ガイドラインの発行者別カウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidelinesApiInterface
     */
    getGuidelineCountByPublisher(options?: AxiosRequestConfig): AxiosPromise<CountsByKey>;

    /**
     * ガイドラインの全数カウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidelinesApiInterface
     */
    getGuidelinesCount(options?: AxiosRequestConfig): AxiosPromise<TotalCount>;

}

/**
 * GuidelinesApi - object-oriented interface
 * @export
 * @class GuidelinesApi
 * @extends {BaseAPI}
 */
export class GuidelinesApi extends BaseAPI implements GuidelinesApiInterface {
    /**
     * ガイドラインの発行者別カウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidelinesApi
     */
    public getGuidelineCountByPublisher(options?: AxiosRequestConfig) {
        return GuidelinesApiFp(this.configuration).getGuidelineCountByPublisher(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ガイドラインの全数カウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GuidelinesApi
     */
    public getGuidelinesCount(options?: AxiosRequestConfig) {
        return GuidelinesApiFp(this.configuration).getGuidelinesCount(options).then((request) => request(this.axios, this.basePath));
    }
}
