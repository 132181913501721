/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 文献の種別
 * @export
 * @enum {string}
 */

export const DocumentTypeEnum = {
    Book: 'book',
    Pdf: 'pdf',
    Guideline: 'guideline',
    Pubcom: 'pubcom',
    Law: 'law',
    Case: 'case',
    Extinv: 'extinv'
} as const;

export type DocumentTypeEnum = typeof DocumentTypeEnum[keyof typeof DocumentTypeEnum];



