/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetHistoriesDocumentsResponse } from '../model';
// @ts-ignore
import { GetHistoriesSearchesResponse } from '../model';
// @ts-ignore
import { PostHistoriesDocumentsParamsBody } from '../model';
/**
 * HistoriesApi - axios parameter creator
 * @export
 */
export const HistoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザーが閲覧した資料の一覧を配列で取得する。 何件目から何件目までを表示させるのかはパラメータに応じる。
         * @summary 資料の閲覧履歴を取得する
         * @param {number} from 検索結果の何件目から表示させるのか
         * @param {number} size 検索結果の表示開始位置から何件目まで表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrowseHistoryDocuments: async (from: number, size: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getBrowseHistoryDocuments', 'from', from)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getBrowseHistoryDocuments', 'size', size)
            const localVarPath = `/histories/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーが行った検索履歴の一覧を取得する。履歴の取得開始位置と件数はパラメータで指定する。
         * @summary 検索履歴一覧を取得する
         * @param {number} from 検索結果の何件目から表示させるのか
         * @param {number} size 検索結果の表示開始位置から何件目まで表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistorySearchConditions: async (from: number, size: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getHistorySearchConditions', 'from', from)
            // verify required parameter 'size' is not null or undefined
            assertParamExists('getHistorySearchConditions', 'size', size)
            const localVarPath = `/histories/searches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーがどの資料を閲覧したのかという履歴を作成する
         * @summary 資料の閲覧履歴を作成する
         * @param {PostHistoriesDocumentsParamsBody} [postHistoriesDocumentsParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postHistoriesDocuments: async (postHistoriesDocumentsParamsBody?: PostHistoriesDocumentsParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/histories/documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postHistoriesDocumentsParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HistoriesApi - functional programming interface
 * @export
 */
export const HistoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HistoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * ユーザーが閲覧した資料の一覧を配列で取得する。 何件目から何件目までを表示させるのかはパラメータに応じる。
         * @summary 資料の閲覧履歴を取得する
         * @param {number} from 検索結果の何件目から表示させるのか
         * @param {number} size 検索結果の表示開始位置から何件目まで表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBrowseHistoryDocuments(from: number, size: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHistoriesDocumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBrowseHistoryDocuments(from, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーが行った検索履歴の一覧を取得する。履歴の取得開始位置と件数はパラメータで指定する。
         * @summary 検索履歴一覧を取得する
         * @param {number} from 検索結果の何件目から表示させるのか
         * @param {number} size 検索結果の表示開始位置から何件目まで表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHistorySearchConditions(from: number, size: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHistoriesSearchesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHistorySearchConditions(from, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーがどの資料を閲覧したのかという履歴を作成する
         * @summary 資料の閲覧履歴を作成する
         * @param {PostHistoriesDocumentsParamsBody} [postHistoriesDocumentsParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postHistoriesDocuments(postHistoriesDocumentsParamsBody?: PostHistoriesDocumentsParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postHistoriesDocuments(postHistoriesDocumentsParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HistoriesApi - factory interface
 * @export
 */
export const HistoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HistoriesApiFp(configuration)
    return {
        /**
         * ユーザーが閲覧した資料の一覧を配列で取得する。 何件目から何件目までを表示させるのかはパラメータに応じる。
         * @summary 資料の閲覧履歴を取得する
         * @param {number} from 検索結果の何件目から表示させるのか
         * @param {number} size 検索結果の表示開始位置から何件目まで表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBrowseHistoryDocuments(from: number, size: number, options?: any): AxiosPromise<GetHistoriesDocumentsResponse> {
            return localVarFp.getBrowseHistoryDocuments(from, size, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーが行った検索履歴の一覧を取得する。履歴の取得開始位置と件数はパラメータで指定する。
         * @summary 検索履歴一覧を取得する
         * @param {number} from 検索結果の何件目から表示させるのか
         * @param {number} size 検索結果の表示開始位置から何件目まで表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistorySearchConditions(from: number, size: number, options?: any): AxiosPromise<GetHistoriesSearchesResponse> {
            return localVarFp.getHistorySearchConditions(from, size, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーがどの資料を閲覧したのかという履歴を作成する
         * @summary 資料の閲覧履歴を作成する
         * @param {PostHistoriesDocumentsParamsBody} [postHistoriesDocumentsParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postHistoriesDocuments(postHistoriesDocumentsParamsBody?: PostHistoriesDocumentsParamsBody, options?: any): AxiosPromise<void> {
            return localVarFp.postHistoriesDocuments(postHistoriesDocumentsParamsBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HistoriesApi - interface
 * @export
 * @interface HistoriesApi
 */
export interface HistoriesApiInterface {
    /**
     * ユーザーが閲覧した資料の一覧を配列で取得する。 何件目から何件目までを表示させるのかはパラメータに応じる。
     * @summary 資料の閲覧履歴を取得する
     * @param {number} from 検索結果の何件目から表示させるのか
     * @param {number} size 検索結果の表示開始位置から何件目まで表示させるのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoriesApiInterface
     */
    getBrowseHistoryDocuments(from: number, size: number, options?: AxiosRequestConfig): AxiosPromise<GetHistoriesDocumentsResponse>;

    /**
     * ユーザーが行った検索履歴の一覧を取得する。履歴の取得開始位置と件数はパラメータで指定する。
     * @summary 検索履歴一覧を取得する
     * @param {number} from 検索結果の何件目から表示させるのか
     * @param {number} size 検索結果の表示開始位置から何件目まで表示させるのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoriesApiInterface
     */
    getHistorySearchConditions(from: number, size: number, options?: AxiosRequestConfig): AxiosPromise<GetHistoriesSearchesResponse>;

    /**
     * ユーザーがどの資料を閲覧したのかという履歴を作成する
     * @summary 資料の閲覧履歴を作成する
     * @param {PostHistoriesDocumentsParamsBody} [postHistoriesDocumentsParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoriesApiInterface
     */
    postHistoriesDocuments(postHistoriesDocumentsParamsBody?: PostHistoriesDocumentsParamsBody, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * HistoriesApi - object-oriented interface
 * @export
 * @class HistoriesApi
 * @extends {BaseAPI}
 */
export class HistoriesApi extends BaseAPI implements HistoriesApiInterface {
    /**
     * ユーザーが閲覧した資料の一覧を配列で取得する。 何件目から何件目までを表示させるのかはパラメータに応じる。
     * @summary 資料の閲覧履歴を取得する
     * @param {number} from 検索結果の何件目から表示させるのか
     * @param {number} size 検索結果の表示開始位置から何件目まで表示させるのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoriesApi
     */
    public getBrowseHistoryDocuments(from: number, size: number, options?: AxiosRequestConfig) {
        return HistoriesApiFp(this.configuration).getBrowseHistoryDocuments(from, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーが行った検索履歴の一覧を取得する。履歴の取得開始位置と件数はパラメータで指定する。
     * @summary 検索履歴一覧を取得する
     * @param {number} from 検索結果の何件目から表示させるのか
     * @param {number} size 検索結果の表示開始位置から何件目まで表示させるのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoriesApi
     */
    public getHistorySearchConditions(from: number, size: number, options?: AxiosRequestConfig) {
        return HistoriesApiFp(this.configuration).getHistorySearchConditions(from, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーがどの資料を閲覧したのかという履歴を作成する
     * @summary 資料の閲覧履歴を作成する
     * @param {PostHistoriesDocumentsParamsBody} [postHistoriesDocumentsParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HistoriesApi
     */
    public postHistoriesDocuments(postHistoriesDocumentsParamsBody?: PostHistoriesDocumentsParamsBody, options?: AxiosRequestConfig) {
        return HistoriesApiFp(this.configuration).postHistoriesDocuments(postHistoriesDocumentsParamsBody, options).then((request) => request(this.axios, this.basePath));
    }
}
