/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * クイックアクセス要素の Web ビュー固有のプロパティ 
 * @export
 * @interface QuickAccessItemTypeWebProperties
 */
export interface QuickAccessItemTypeWebProperties {
    /**
     * クイックアクセスに登録されたときのビューを識別する文字列
     * @type {string}
     * @memberof QuickAccessItemTypeWebProperties
     */
    'viewType': QuickAccessItemTypeWebPropertiesViewTypeEnum;
    /**
     * クイックアクセスに登録された section を指し示す key
     * @type {number}
     * @memberof QuickAccessItemTypeWebProperties
     */
    'key': number;
    /**
     * この要素が登録された箇所を特定するのに参考情報の文字列表現。 Web ビューの場合は セクションの見出し となる。 
     * @type {string}
     * @memberof QuickAccessItemTypeWebProperties
     */
    'headingText': string;
}

export const QuickAccessItemTypeWebPropertiesViewTypeEnum = {
    Web: 'web'
} as const;

export type QuickAccessItemTypeWebPropertiesViewTypeEnum = typeof QuickAccessItemTypeWebPropertiesViewTypeEnum[keyof typeof QuickAccessItemTypeWebPropertiesViewTypeEnum];


