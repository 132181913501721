import type { LabsApi } from '@gen/wklr-backend-api/v1/api';
import type { PostLabsWandhEvaluationParamsBody } from '@gen/wklr-backend-api/v1/model';
import { isAxiosError } from '../utils/axiosUtis';
import type { components, paths } from '@gen/labs-wandh';
import type { AxiosError } from 'axios';

export interface LabsWandhQuestionHistoryListItem {
  id: string;
  questionSummary: string | null;
  model: LabsWandhModel | null;
  askedAt: string;
}

type NonNullableProperties<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export type NonNullableLabsWandhQuestionHistoryListItem = NonNullableProperties<LabsWandhQuestionHistoryListItem>;

export function isNonNullableLabsWandhQuestionHistoryListItem(
  item: LabsWandhQuestionHistoryListItem,
): item is NonNullableLabsWandhQuestionHistoryListItem {
  return item.questionSummary !== null && item.model !== null;
}

export interface LabsWandhQuestionHistory {
  id: string;
  parameters: QuestionParams;
  apiResponses: paths['/question/']['post']['responses'][200]['content']['application/json'][];
  evaluation: Evaluation | null;
}

export interface LabsWandhStreamParams {
  query: PostLabsWandhQuestionParams;
  host: string;
  token: string | undefined;
  user: string;
  modelId: LabsWandhModel;
  reservedQuotaId: string;
  historyId: string;
  includesHolmes: boolean;
}

export class LabsRepository {
  constructor(private api: LabsApi) {}

  async listLabsWandhQuestionHistories(page: number, pageSize: number): Promise<LabsWandhQuestionHistoryListItem[]> {
    const { data } = await this.api.listQuestionHistories(page, pageSize);
    return data as LabsWandhQuestionHistoryListItem[];
  }

  async getLabsWandhQuestionHistory(id: string): Promise<LabsWandhQuestionHistory> {
    const {
      data: { parameters, apiResponses, evaluation },
    } = await this.api.getQuestionHistoryDetail(id);

    if (
      typeof parameters !== 'object' ||
      typeof parameters.question !== 'string' ||
      typeof parameters.model !== 'string'
    ) {
      throw new Error('Invalid types for parameters');
    }

    const castedParameters = parameters as unknown as QuestionParams;

    if (!Array.isArray(apiResponses)) {
      throw new Error('apiResponses must be an array');
    }

    for (const response of apiResponses) {
      if (typeof response !== 'object' || !('role' in response) || typeof response.role !== 'string') {
        throw new Error('Invalid type for an entry in apiResponses');
      }
    }

    const castedApiResponses =
      apiResponses as unknown as paths['/question/']['post']['responses'][200]['content']['application/json'][];

    if (evaluation !== null && (typeof evaluation.holmes !== 'object' || typeof evaluation.holmes !== 'object')) {
      throw new Error('Invalid type for evaluation');
    }

    const castedEvaluation = evaluation as unknown as Evaluation;

    return { id, parameters: castedParameters, apiResponses: castedApiResponses, evaluation: castedEvaluation };
  }

  async preparePostLabsWandhQuestion(query: PostLabsWandhQuestionParams): Promise<LabsWandhStreamParams> {
    try {
      const {
        data: { host, token, user, modelId, reservedQuotaId, historyId, includesHolmes },
      } = await this.api.postLabsWandhQuestion({ model: query.model });
      return {
        query,
        host,
        token,
        user,
        modelId: modelId as LabsWandhModel,
        reservedQuotaId,
        historyId,
        includesHolmes,
      };
    } catch (error) {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          switch (axiosError.response.status) {
            case 429:
              throw new Error('検索の間隔が短すぎます。少し待ってから再度お試しください。');
            default:
              throw new Error(`通信中に問題が発生しました。コード: ${axiosError.response.status}`);
          }
        } else if (axiosError.request) {
          throw new Error('検索リクエストが送信されましたが、応答がありませんでした。');
        } else {
          throw new Error('検索リクエストの設定中に問題が発生しました。');
        }
      } else {
        throw new Error('予期せぬ問題が発生しました。');
      }
    }
  }

  async getLabsWandhStream(params: LabsWandhStreamParams) {
    const { query, host, token, user, reservedQuotaId, historyId, includesHolmes } = params;
    const controller = new AbortController();

    return {
      controller,
      async stream() {
        const response = await fetch(host.replace(/\/+$/, '') + '/question/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
          body: JSON.stringify({ ...query, user, reservedQuotaId, historyId, includesHolmes }),
          signal: controller.signal,
        });

        if (!response.ok || !response.body) {
          throw response;
        }

        return {
          async *[Symbol.asyncIterator]() {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- truthyチェック済
            const reader = response.body!.getReader();
            const utf8Decoder = new TextDecoder();
            let queue = '';
            let readResult = await reader.read();
            while (!readResult.done) {
              queue += utf8Decoder.decode(readResult.value, { stream: true });

              if (queue.includes('\n')) {
                const lines = queue.split('\n');
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- includesチェック済
                queue = lines.pop()!;
                yield* lines.map(
                  (line) =>
                    JSON.parse(line) as paths['/question/']['post']['responses'][200]['content']['application/json'],
                );
              }

              readResult = await reader.read();
            }
          },
        };
      },
    };
  }

  async getLabsWandhAgreement() {
    const { data } = await this.api.getLabsWandhAgreement();
    return data;
  }

  async postLabsWandhAgreement(ids: string[]) {
    const { data } = await this.api.postLabsWandhAgreement(ids);
    return data;
  }

  async postLabsWandhEvaluation(body: PostLabsWandhEvaluationParamsBody) {
    const { data } = await this.api.postLabsWandhEvaluation(body);
    return data;
  }

  async getLabsWandhQuota() {
    const { data } = await this.api.getLabsWandhQuota();
    return data;
  }
}

export type QuestionParams = Omit<components['schemas']['QuestionParams'], 'model'> & { model: LabsWandhModel };
export type PostLabsWandhQuestionParams = Omit<
  components['schemas']['PostQuestionParams'],
  'user' | 'historyId' | 'model'
> & { model: LabsWandhModel };
export type PostLabsWandhQuestionReference = components['schemas']['PineconeHit'];
export type CaseReferenceMetadata = components['schemas']['CaseReferenceMetadata'];
export type GuidelineReferenceMetadata = components['schemas']['GuidelineReferenceMetadata'];
export type Evaluation = Omit<PostLabsWandhEvaluationParamsBody, 'session'>;
/** W&Hのモデルのバージョンとバリアントを指定する文字列（バリアントを公開しないべき場合、 `auto`, `standard`, `advanced` に置換済み） */
export type LabsWandhModel = `${string}_${string}` | `${string}-${string}_${string}`;
