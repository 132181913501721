import { Context } from '@nuxt/types';

export default async function ({ app, store, redirect, route }: Context) {
  if (route.path.startsWith('/auth')) return;
  if (route.path.startsWith('/profile')) return;

  const { flags } = await app.$repositories.users.getMe();

  // スキップ不可の場合はどんなルートであっても入力ウィザード画面に遷移させる
  if (flags.shouldShowInputProfile && !flags.canSkipInputProfile) {
    redirect(`/profile/wizard?redirect_url=${encodeURIComponent(route.fullPath)}`);
  }
}
