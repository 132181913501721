export default (function (_ref, inject) {
  var app = _ref.app,
    store = _ref.store;
  var router = app.router;
  if (router === undefined) return;
  router.beforeEach(function (_to, _from, next) {
    store.commit('startRouteChange');
    next();
  });
  router.afterEach(function () {
    store.commit('endRouteChange');
  });
});