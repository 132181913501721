import { Context } from '@nuxt/types';
// app 初期化前なので直接 import して使う
import { $domain } from '@/plugins/domain';

export default async function ({ route, redirect }: Context): Promise<void> {
  if (
    ($domain.isSHKommentar || $domain.isSHKommentarLibrary) &&
    // 文献以外閲覧不可能
    !route.path.startsWith('/document/')
  ) {
    redirect(302, '/document/kommentar_shojihomu_kaishaho_1');
  }
}
