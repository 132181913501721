import { Context } from '@nuxt/types';

export default async function ({ app: { $repositories }, route, redirect }: Context): Promise<void> {
  const match = route.path.match(/^\/e-gov\/public-comment\/(\d+)$/);
  if (!match) {
    return;
  }
  const url = await $repositories.eGov.getEGovPublicCommentURL(match[1]);
  redirect(301, url);
}
