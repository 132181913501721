<template>
  <v-dialog v-model="show" v-bind="{ persistent: !agreement }" max-width="720">
    <v-card v-if="agreement !== null && show">
      <v-card-title class="headline grey lighten-2">
        <v-icon left>mdi-file-sign</v-icon> 新検索機能ベータ版のご利用に関する免責事項
      </v-card-title>
      <v-card-text class="my-4">
        <p>
          この度はLegalscapeの新検索機能ベータ版をご利用くださいまして、誠にありがとうございます。ご利用にあたり、以下の事項についてご確認いただきますようお願い申し上げます。
        </p>

        <ul>
          <li>
            Watson & Holmes (W&amp;H)
            は、リーガルリサーチプラットフォームLegalscapeの法律文献その他の情報（以下、法律文献等）内の記載を従来より効率的に探す新検索機能であって、資格を持った弁護士に代わるものではありません。W&amp;Hを通じて提供される検索結果は、Watson部分（画面右側）がユーザーによって入力された質問に関連する法律文献等、及び、法律文献等内の記載を言語情報に基づく処理（自然言語処理）によって抽出し、その抽出された記載を質問内容に応じてHolmes部分（画面左側）が生成AIにより要約する形で提供されるものです。したがって、W&amp;Hはユーザーが入力する個別の事案に対しての回答を示すことはなく、いかなる法律事務を行うものでもありません。
          </li>
          <li>
            W&amp;Hを通じて提供される検索結果のうち、Watson部分の出力は法律文献等からの引用のみで構成される一方、Holmes部分（生成AI）による出力には、生成AIの性能等に起因する誤りが含まれる可能性があります。当社は、Holmes部分（生成AI）による出力結果の誤りに関連又は起因して生じたユーザーの損害について一切の責任を負いません。当該出力結果について正確を期する場合、弁護士等の専門家にご相談ください。
          </li>
          <li>
            W&amp;Hには個人情報保護法第2条第1項に定義される個人情報（氏名、生年月日など、特定の個人を識別できる情報を指し、他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）の入力はお控えください。
          </li>
          <li>
            W&amp;Hを通じて提供される検索結果は法律文献等内の記載を抽出し、生成AIにより要約したものです。そのため、当該検索結果には、抽出された法律文献等の著作権者等の第三者が保有する著作権が及ぶ著作物が含まれる可能性があります。当社は、ユーザーが当該検索結果に含まれる著作物を複製、公衆送信するなどして利用したことに関連又は禁止して生じたユーザーの損害について一切の責任を負いません。
          </li>
        </ul>

        <v-checkbox
          v-if="!agreement"
          v-model="checklist[0]"
          :rules="[requiredValidator]"
          hide-details
          label="以上の免責事項を理解した上で機能を利用することに同意します。"
          class="mt-12"
        />
      </v-card-text>
      <v-card-actions class="pa-6">
        <v-btn text href="mailto:user-support.labs@legalscape.co.jp" target="_blank" rel="noopener" color="primary">
          <v-icon size="medium" color="gray" class="mr-1">mdi-email-outline</v-icon>
          お問い合わせ
        </v-btn>

        <v-spacer />

        <v-btn v-if="agreement" class="px-8" @click="show = false">閉じる</v-btn>
        <template v-else>
          <v-btn color="secondary" text nuxt to="/">利用しない</v-btn>
          <v-btn color="primary" :disabled="!allChecked" class="px-3" @click="confirmHandler">
            同意をデータベースに記録し利用を開始
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
    <v-card v-else class="text-center pa-12">
      <nekomimi />
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue, VModel } from 'nuxt-property-decorator';
import Nekomimi from '@/components/labs/nekomimi.vue';

const Agreements = ['disclaimer_20230904'];

@Component({ components: { Nekomimi } })
export default class AgreementDialog extends Vue {
  /** ダイアログの表示・非表示 */
  @VModel() show!: boolean;

  /** 秘密保持誓約の同意記録（有・無・確認中） */
  agreement: boolean | null = null;

  checklist = [false];
  requiredValidator = (v: string) => !!v || '同意が必要です';

  get allChecked() {
    return this.checklist.every(Boolean);
  }

  mounted() {
    this.$repositories.labs.getLabsWandhAgreement().then((agreements) => {
      const agreed = new Set(agreements.map(({ id }) => id));
      this.agreement = Agreements.every((a) => agreed.has(a));
      this.show = !this.agreement;
    });
  }

  confirmHandler() {
    if (this.allChecked) {
      this.$repositories.labs.postLabsWandhAgreement(Agreements).then(() => {
        this.agreement = true;
        this.show = false;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  margin-bottom: 1em;
}
</style>
