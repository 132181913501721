/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { KommentarArticle } from '../model';
// @ts-ignore
import { KommentarCitations } from '../model';
// @ts-ignore
import { KommentarSupplementarySections } from '../model';
/**
 * KommentarApi - axios parameter creator
 * @export
 */
export const KommentarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary コンメンタールに対応する条文のリストを取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKommentarArticles: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getKommentarArticles', 'id', id)
            const localVarPath = `/kommentars/{id}/articles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コンメンタールのドキュメントIDとセクションを指定するとそれに対する補巻セクションを返す 
         * @summary コンメンタールの補巻を取得する。
         * @param {string} id コンメンタールのID
         * @param {Array<number>} keys コンメンタールのセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKommentarSupplementaries: async (id: string, keys: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getKommentarSupplementaries', 'id', id)
            // verify required parameter 'keys' is not null or undefined
            assertParamExists('getKommentarSupplementaries', 'keys', keys)
            const localVarPath = `/kommentars/{id}/supplementaries`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keys) {
                localVarQueryParameter['keys'] = keys.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 法令に対応するコンメンタールの節のリストを取得する
         * @param {string} lawNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawKommentars: async (lawNaturalId: string, keys: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lawNaturalId' is not null or undefined
            assertParamExists('getLawKommentars', 'lawNaturalId', lawNaturalId)
            // verify required parameter 'keys' is not null or undefined
            assertParamExists('getLawKommentars', 'keys', keys)
            const localVarPath = `/laws/{lawNaturalId}/kommentars`
                .replace(`{${"lawNaturalId"}}`, encodeURIComponent(String(lawNaturalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keys) {
                localVarQueryParameter['keys'] = keys.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KommentarApi - functional programming interface
 * @export
 */
export const KommentarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KommentarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary コンメンタールに対応する条文のリストを取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKommentarArticles(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KommentarArticle>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKommentarArticles(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * コンメンタールのドキュメントIDとセクションを指定するとそれに対する補巻セクションを返す 
         * @summary コンメンタールの補巻を取得する。
         * @param {string} id コンメンタールのID
         * @param {Array<number>} keys コンメンタールのセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKommentarSupplementaries(id: string, keys: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KommentarSupplementarySections>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKommentarSupplementaries(id, keys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 法令に対応するコンメンタールの節のリストを取得する
         * @param {string} lawNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLawKommentars(lawNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KommentarCitations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLawKommentars(lawNaturalId, keys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KommentarApi - factory interface
 * @export
 */
export const KommentarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KommentarApiFp(configuration)
    return {
        /**
         * 
         * @summary コンメンタールに対応する条文のリストを取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKommentarArticles(id: string, options?: any): AxiosPromise<Array<KommentarArticle>> {
            return localVarFp.getKommentarArticles(id, options).then((request) => request(axios, basePath));
        },
        /**
         * コンメンタールのドキュメントIDとセクションを指定するとそれに対する補巻セクションを返す 
         * @summary コンメンタールの補巻を取得する。
         * @param {string} id コンメンタールのID
         * @param {Array<number>} keys コンメンタールのセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKommentarSupplementaries(id: string, keys: Array<number>, options?: any): AxiosPromise<KommentarSupplementarySections> {
            return localVarFp.getKommentarSupplementaries(id, keys, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 法令に対応するコンメンタールの節のリストを取得する
         * @param {string} lawNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawKommentars(lawNaturalId: string, keys: Array<number>, options?: any): AxiosPromise<Array<KommentarCitations>> {
            return localVarFp.getLawKommentars(lawNaturalId, keys, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KommentarApi - interface
 * @export
 * @interface KommentarApi
 */
export interface KommentarApiInterface {
    /**
     * 
     * @summary コンメンタールに対応する条文のリストを取得する
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentarApiInterface
     */
    getKommentarArticles(id: string, options?: AxiosRequestConfig): AxiosPromise<Array<KommentarArticle>>;

    /**
     * コンメンタールのドキュメントIDとセクションを指定するとそれに対する補巻セクションを返す 
     * @summary コンメンタールの補巻を取得する。
     * @param {string} id コンメンタールのID
     * @param {Array<number>} keys コンメンタールのセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentarApiInterface
     */
    getKommentarSupplementaries(id: string, keys: Array<number>, options?: AxiosRequestConfig): AxiosPromise<KommentarSupplementarySections>;

    /**
     * 
     * @summary 法令に対応するコンメンタールの節のリストを取得する
     * @param {string} lawNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentarApiInterface
     */
    getLawKommentars(lawNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): AxiosPromise<Array<KommentarCitations>>;

}

/**
 * KommentarApi - object-oriented interface
 * @export
 * @class KommentarApi
 * @extends {BaseAPI}
 */
export class KommentarApi extends BaseAPI implements KommentarApiInterface {
    /**
     * 
     * @summary コンメンタールに対応する条文のリストを取得する
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentarApi
     */
    public getKommentarArticles(id: string, options?: AxiosRequestConfig) {
        return KommentarApiFp(this.configuration).getKommentarArticles(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * コンメンタールのドキュメントIDとセクションを指定するとそれに対する補巻セクションを返す 
     * @summary コンメンタールの補巻を取得する。
     * @param {string} id コンメンタールのID
     * @param {Array<number>} keys コンメンタールのセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentarApi
     */
    public getKommentarSupplementaries(id: string, keys: Array<number>, options?: AxiosRequestConfig) {
        return KommentarApiFp(this.configuration).getKommentarSupplementaries(id, keys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 法令に対応するコンメンタールの節のリストを取得する
     * @param {string} lawNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KommentarApi
     */
    public getLawKommentars(lawNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig) {
        return KommentarApiFp(this.configuration).getLawKommentars(lawNaturalId, keys, options).then((request) => request(this.axios, this.basePath));
    }
}
