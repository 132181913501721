const middleware = {}

middleware['fullstar'] = require('../frontend/middleware/fullstar.ts')
middleware['fullstar'] = middleware['fullstar'].default || middleware['fullstar']

middleware['profile-check'] = require('../frontend/middleware/profile-check.ts')
middleware['profile-check'] = middleware['profile-check'].default || middleware['profile-check']

middleware['redirect-e-gov-pubcom'] = require('../frontend/middleware/redirect-e-gov-pubcom.ts')
middleware['redirect-e-gov-pubcom'] = middleware['redirect-e-gov-pubcom'].default || middleware['redirect-e-gov-pubcom']

middleware['redirect-to-kommentar'] = require('../frontend/middleware/redirect-to-kommentar.ts')
middleware['redirect-to-kommentar'] = middleware['redirect-to-kommentar'].default || middleware['redirect-to-kommentar']

middleware['refresh-login-session'] = require('../frontend/middleware/refresh-login-session.ts')
middleware['refresh-login-session'] = middleware['refresh-login-session'].default || middleware['refresh-login-session']

middleware['verify-device'] = require('../frontend/middleware/verify-device.ts')
middleware['verify-device'] = middleware['verify-device'].default || middleware['verify-device']

export default middleware
