/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetPracticeAreaSnippetsResponse } from '../model';
// @ts-ignore
import { GetTaggedDocumentsResponse } from '../model';
// @ts-ignore
import { Tag } from '../model';
/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary タグをすべて取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
         * @summary プラクティスエリアの各種リソースのスニペットを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeAreaSnippets: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPracticeAreaSnippets', 'id', id)
            const localVarPath = `/practice-areas/{id}/snippets`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ID で指定されたタグを取得する。
         * @param {string} id タグの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTag: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTag', 'id', id)
            const localVarPath = `/tags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 一部の資料にはタグが付与されており、この API ではそのタグを ID 指定することで該当する資料を返却する。 この API のレスポンスには、書誌情報しか存在していない (すなわち Legalscape には未掲載の) 書籍も含まれる。
         * @summary ID で指定されたタグが付与されている資料を取得する。
         * @param {string} id タグの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaggedDocuments: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTaggedDocuments', 'id', id)
            const localVarPath = `/tags/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary タグをすべて取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
         * @summary プラクティスエリアの各種リソースのスニペットを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeAreaSnippets(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPracticeAreaSnippetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPracticeAreaSnippets(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ID で指定されたタグを取得する。
         * @param {string} id タグの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTag(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTag(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 一部の資料にはタグが付与されており、この API ではそのタグを ID 指定することで該当する資料を返却する。 この API のレスポンスには、書誌情報しか存在していない (すなわち Legalscape には未掲載の) 書籍も含まれる。
         * @summary ID で指定されたタグが付与されている資料を取得する。
         * @param {string} id タグの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaggedDocuments(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTaggedDocumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaggedDocuments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagsApiFp(configuration)
    return {
        /**
         * 
         * @summary タグをすべて取得する。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTags(options?: any): AxiosPromise<Array<Tag>> {
            return localVarFp.getAllTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
         * @summary プラクティスエリアの各種リソースのスニペットを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeAreaSnippets(id: string, options?: any): AxiosPromise<GetPracticeAreaSnippetsResponse> {
            return localVarFp.getPracticeAreaSnippets(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ID で指定されたタグを取得する。
         * @param {string} id タグの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTag(id: string, options?: any): AxiosPromise<Tag> {
            return localVarFp.getTag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 一部の資料にはタグが付与されており、この API ではそのタグを ID 指定することで該当する資料を返却する。 この API のレスポンスには、書誌情報しか存在していない (すなわち Legalscape には未掲載の) 書籍も含まれる。
         * @summary ID で指定されたタグが付与されている資料を取得する。
         * @param {string} id タグの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaggedDocuments(id: string, options?: any): AxiosPromise<GetTaggedDocumentsResponse> {
            return localVarFp.getTaggedDocuments(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagsApi - interface
 * @export
 * @interface TagsApi
 */
export interface TagsApiInterface {
    /**
     * 
     * @summary タグをすべて取得する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    getAllTags(options?: AxiosRequestConfig): AxiosPromise<Array<Tag>>;

    /**
     * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
     * @summary プラクティスエリアの各種リソースのスニペットを取得する。
     * @param {string} id プラクティスエリア ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    getPracticeAreaSnippets(id: string, options?: AxiosRequestConfig): AxiosPromise<GetPracticeAreaSnippetsResponse>;

    /**
     * 
     * @summary ID で指定されたタグを取得する。
     * @param {string} id タグの ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    getTag(id: string, options?: AxiosRequestConfig): AxiosPromise<Tag>;

    /**
     * 一部の資料にはタグが付与されており、この API ではそのタグを ID 指定することで該当する資料を返却する。 この API のレスポンスには、書誌情報しか存在していない (すなわち Legalscape には未掲載の) 書籍も含まれる。
     * @summary ID で指定されたタグが付与されている資料を取得する。
     * @param {string} id タグの ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApiInterface
     */
    getTaggedDocuments(id: string, options?: AxiosRequestConfig): AxiosPromise<GetTaggedDocumentsResponse>;

}

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI implements TagsApiInterface {
    /**
     * 
     * @summary タグをすべて取得する。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public getAllTags(options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).getAllTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
     * @summary プラクティスエリアの各種リソースのスニペットを取得する。
     * @param {string} id プラクティスエリア ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public getPracticeAreaSnippets(id: string, options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).getPracticeAreaSnippets(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ID で指定されたタグを取得する。
     * @param {string} id タグの ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public getTag(id: string, options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).getTag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 一部の資料にはタグが付与されており、この API ではそのタグを ID 指定することで該当する資料を返却する。 この API のレスポンスには、書誌情報しか存在していない (すなわち Legalscape には未掲載の) 書籍も含まれる。
     * @summary ID で指定されたタグが付与されている資料を取得する。
     * @param {string} id タグの ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public getTaggedDocuments(id: string, options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).getTaggedDocuments(id, options).then((request) => request(this.axios, this.basePath));
    }
}
