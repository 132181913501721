import { CacheTTL, Document } from '../constants';
import type { SectionsApi } from '@gen/wklr-backend-api/v1/api/sections-api';
import type { DocSection } from '@gen/wklr-backend-api/v1/model';

type SectionQueueItem = {
  docId: string;
  key: number;
  margin: boolean;
  resolve: (value: DocSection[]) => void;
  reject: (reason?: unknown) => void;
};

export class SectionsRepository {
  constructor(private api: SectionsApi) {}

  private sectionsByDocumentId: { [key: string]: DocSection[] } = {};
  private requestQueue: SectionQueueItem[] = [];
  private isRequesting = false;

  /** 次のキューを実行する */
  // シーケンシャルに実行している。margin の有無があるため、並列にする場合は念入りな動作確認が必須
  private async nextQueue(): Promise<void> {
    if (this.isRequesting) return;
    const next = this.requestQueue.pop();
    if (next === undefined) return;

    // キャッシュにあればすぐに返す
    if (
      this.sectionsByDocumentId[next.docId] !== undefined &&
      this.sectionsByDocumentId[next.docId][next.key] !== undefined
    ) {
      next.resolve([this.sectionsByDocumentId[next.docId][next.key]]);
    }

    this.isRequesting = true;
    if (next.margin) {
      // マージンありリクエストの場合は、単体でリクエストする
      try {
        const { data: sections } = await this.api.getDocumentSectionsBySectionKey(
          next.docId,
          next.key.toString(),
          Document.WebView.Margin,
          Document.WebView.Margin,
          {
            cache: {
              ttl: CacheTTL.DEFAULT,
            },
          },
        );
        sections.forEach((section) => {
          // TODO: キューに対応する値があれば resolve してあげると処理がブロッキングされなくて済む
          this.sectionsByDocumentId[next.docId][section.key] = section;
        });
        next.resolve(sections);
      } catch (error) {
        next.reject(error);
      }
    } else {
      // それ以外のリクエストの場合、10個もしくはマージンありのキューの手前までをまとめてリクエストする
      const docId = next.docId;
      // リクエスト用のキューを作成する
      const queueStack = [next];
      while (
        queueStack.length < 10 &&
        this.requestQueue.length > 0 &&
        this.requestQueue[this.requestQueue.length - 1].docId === docId &&
        this.requestQueue[this.requestQueue.length - 1].margin === false
      ) {
        const next = this.requestQueue.pop();
        if (next !== undefined) {
          queueStack.push(next);
        }
      }
      // キューからキーを抽出する
      const keys = queueStack.map((q) => q.key);
      try {
        // リクエストする
        const { data: sections } = await this.api.getDocumentSectionsByMultipleSectionKeys(docId, keys, {
          cache: { ttl: CacheTTL.DEFAULT },
        });
        // 成功したものは resolbe してキーを保持する
        sections.forEach((section) => {
          this.sectionsByDocumentId[docId][section.key] = section;
          const position = queueStack.findIndex((q) => q.key === section.key);
          if (position >= 0) {
            queueStack[position].resolve([section]);
            queueStack.splice(position, 1);
          }
        });
        // 成功した key 以外のキューは reject する
        queueStack.forEach((q) => q.reject(new Error('セクション情報が取得できませんでした')));
      } catch (error) {
        queueStack.forEach((q) => q.reject(error));
      }
    }
    this.isRequesting = false;
    this.nextQueue();
  }

  /** キューに追加する。重複などで追加できなかった場合は false が返る */
  private addToQueue(newQueue: SectionQueueItem): boolean {
    if (this.requestQueue.find((queue) => queue.docId === newQueue.docId && queue.key === newQueue.key)) {
      return false;
    }
    this.requestQueue.push(newQueue);
    return true;
  }

  /**
   * セクションを取得する
   * @param docId
   * @param key
   */
  getSection(docId: string, key: number, margin = false): Promise<DocSection[]> {
    console.log('sectionsByDocumentId: ', this.sectionsByDocumentId);
    return new Promise((resolve, reject) => {
      if (!(docId in this.sectionsByDocumentId)) {
        this.sectionsByDocumentId[docId] = [];
      }
      const section = this.sectionsByDocumentId[docId][key];
      if (section !== undefined) {
        resolve([section]);
      }

      const success = this.addToQueue({
        docId,
        key,
        margin,
        resolve,
        reject,
      });

      if (success) {
        if (!this.isRequesting) {
          this.nextQueue();
        }
      } else {
        console.warn('キューへの登録が重複しています');
      }
    });
  }

  /**
   * キャッシュとキューをクリアする
   * @param docId
   */
  purge(docId: string): void {
    this.requestQueue = this.requestQueue.filter((queue) => queue.docId !== docId);
    this.sectionsByDocumentId[docId] = [];
  }
}
