<template>
  <v-app id="wklr-wrapper" :class="classes">
    <search-bar :layout-type="layoutType" @show-modest-header="modestHeaderVisible = $event" />
    <debug-component v-if="isDevelopment" />
    <nuxt />
    <share-url-dialog ref="shareUrlDialog" />
    <user-profile-wizard-promotion-alert v-if="isUserProfileWizardPromotionAlertVisible" />
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { Route } from 'vue-router';
import SearchBar from '@/components/search-bar.vue';
import DebugComponent from '@/components/debug/debug-component.vue';
import ShareUrlDialog from '@/components/share/share-url-dialog.vue';
import UserProfileWizardPromotionAlert from '@/components/alert/user-profile-wizard-promotion-alert.vue';
import { State as MyState } from '@/store';

const isDevelopment = process.env.NODE_ENV === 'development';

export default Vue.extend({
  components: { DebugComponent, SearchBar, ShareUrlDialog, UserProfileWizardPromotionAlert },
  data: () => ({ isDevelopment, modestHeaderVisible: false, shouldShowInputProfile: false }),
  computed: {
    layoutType(): string {
      if (this.$route.name === 'index') return 'default';
      if (this.$route.name === 'document-id') return 'modest';
      if (this.$route.name === 'profile-wizard') return 'no-interaction';
      return 'floating';
    },
    classes(): { [key: string]: boolean } {
      return {
        '-floatingHeader': this.layoutType === 'floating',
        '-modestHeader': this.layoutType === 'modest',
        '-modestHeaderVisible': this.layoutType === 'modest' && this.modestHeaderVisible,
      };
    },
    isUserProfileWizardPromotionAlertVisible(): boolean {
      if (!this.shouldShowInputProfile) return false;
      if (this.$route.path.startsWith('/profile')) return false;
      return (this.$store.state as MyState).global.firstAccessOfTheDay;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (route: Route) {
        this.modestHeaderVisible = false;
      },
    },
  },
  async mounted() {
    const me = await this.$repositories.users.getMe();
    this.$data.shouldShowInputProfile = me.flags.shouldShowInputProfile;
  },
});
</script>
<style lang="scss">
@import './default.scss';

#wklr-wrapper {
  transition: padding $default-transition-duration linear;

  --header-height: #{$global-header-height};
  --footer-height: #{$global-footer-height};

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    --header-height: #{$global-header-height-sm-xs};
  }

  &.-modestHeader {
    --header-area-offset: 0px;
    --footer-area-offset: 0px;
  }

  &.-floatingHeader {
    --header-area-offset: var(--header-height);
    --footer-area-offset: var(--footer-height);
    padding-top: var(--header-height); // スクロール位置調整用にグローバルヘッダの高さ分

    .v-application--wrap {
      min-height: calc(100vh - var(--header-height));
    }
  }
}
</style>
<style lang="scss" scoped>
// v-dialog がUIのうしろに隠れることの対策
#wklr-wrapper {
  ::v-deep {
    .v-dialog__container {
      z-index: $layer-global-v-dialog + 1 !important;
    }
    .v-overlay {
      z-index: $layer-global-v-dialog + 2 !important;
    }
    .v-dialog__content {
      z-index: $layer-global-v-dialog + 3 !important;
    }
  }
}
</style>
