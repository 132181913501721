<template>
  <v-btn
    depressed
    fab
    small
    text
    class="wklr-icon-btn"
    :class="[sizeClass, rotateClass, selectedClass]"
    @click="$emit('click', $event)"
    @mouseenter="$emit('mouseenter', $event)"
    @mouseleave="$emit('mouseleave', $event)"
    ><wklr-icon :type="$props.type" :size="iconSize"
  /></v-btn>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import WklrIcon from '@/components/shared/wklr-icon.vue';
import { IconTypes, BtnSize } from '@/components/shared/types';

/**
 * アイコンを利用したボタンを表示する
 *
 * ## サイズ
 *
 * 種類|ボタンサイズ|アイコンサイズ
 * ---|---|---
 * normal | 40x40 | 24x24
 * small | 24x24 | 16x16
 * dense | 24x24 | 20x20
 */
@Component({ components: { WklrIcon } })
export default class WklrIconBtn extends Vue {
  /** アイコンの種類 */
  @Prop() type!: IconTypes;
  /** ボタンのサイズ */
  @Prop({ default: 'normal' }) size!: BtnSize;
  /** dense モードで表示する。サイズよりもこちらが優先される */
  @Prop({ default: false }) dense!: boolean;
  /** type が chevron の場合アイコンの向きを指定できる */
  @Prop({ default: 'right' }) direction!: 'right' | 'down' | 'left' | 'up';
  /** 選択済みか */
  @Prop() selected!: boolean;

  get sizeClass(): string {
    if (this.dense) {
      return '-dense';
    }
    switch (this.size) {
      case 'small':
        return '-small';
      case 'normal':
        return '';
    }
  }

  get iconSize(): string {
    if (this.dense) {
      return 'small';
    }
    switch (this.size) {
      case 'small':
        return 'x-small';
      case 'normal':
        return 'normal';
    }
  }

  get rotateClass(): string {
    if (this.type !== 'chevron') return '';
    return `-rotate-${this.direction}`;
  }

  get selectedClass(): string {
    if (this.selected) {
      return '-selected';
    }
    return '';
  }
}
</script>

<style lang="scss" scoped>
.wklr-icon-btn {
  color: #8f8f8f;

  &:hover {
    color: $primary;
  }
  &.-selected,
  &:hover.-selected {
    color: #000;
  }
  &.-selected.-primary-color,
  &:hover.-selected.-primary-color {
    color: $primary;
  }

  &.-small,
  &.-dense {
    height: 24px !important;
    width: 24px !important;
  }

  &.-rotate-right {
    transform: rotate(0deg);
  }
  &.-rotate-down {
    transform: rotate(90deg);
  }
  &.-rotate-left {
    transform: rotate(180deg);
  }
  &.-rotate-up {
    transform: rotate(-90deg);
  }
}
</style>
