/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * バインダーの種類
 * @export
 * @enum {string}
 */

export const BinderTypeEnum = {
    Normal: 'normal',
    QuickAccess: 'quickAccess'
} as const;

export type BinderTypeEnum = typeof BinderTypeEnum[keyof typeof BinderTypeEnum];



