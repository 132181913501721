import "core-js/modules/es.array.includes.js";
export default (function (_ref, inject) {
  var app = _ref.app;
  var _useNuxtApp = useNuxtApp(),
    $auth = _useNuxtApp.$auth;
  inject('wandhGuideline', ['legalscapecstest'].includes($auth.user.organization.oid));
});
export var useWandhGuideline = function useWandhGuideline() {
  var _useNuxtApp2 = useNuxtApp(),
    $wandhGuideline = _useNuxtApp2.$wandhGuideline;
  return $wandhGuideline;
};