import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { getAuth, signInWithCustomToken, inMemoryPersistence } from 'firebase/auth';

export async function refreshSessionCookieIfNeeded($axios: NuxtAxiosInstance): Promise<void> {
  if (process.env.SKIP_AUTH) {
    return;
  }

  if (!(document && document.cookie)) {
    return;
  }

  // ログイントークンをcookieに付与する際にkeepSessionCookieという期限付きcookieも付与される
  // keepSessionCookieの期限が切れたらログイントークンを更新する
  const keepSessionCookie = document.cookie.split('; ').find((row) => row.startsWith('keepSessionCookie'));
  if (keepSessionCookie) {
    return;
  }

  try {
    const sessionId = localStorage.getItem('sessionId');
    const res = await $axios.$get('session/refresh', {
      baseURL: process.env.AUTH_API_BASE_URL,
      params: { sessionId },
    });
    if (!(res && res.token)) {
      return;
    }

    const auth = getAuth();
    await auth.setPersistence(inMemoryPersistence);
    const userCredential = await signInWithCustomToken(auth, res.token);

    const user = userCredential.user;
    if (user === null) {
      console.error('Unauthorized');
      return;
    }

    const idToken = await user.getIdToken();
    await $axios.post('session/login', { idToken }, { baseURL: process.env.AUTH_API_BASE_URL });
  } catch (e) {
    console.error('Failed to refresh session cookie: ', e);
  }
}
