<template>
  <v-card elevation="2" class="mx-auto my-auto" max-width="400">
    <v-card-title>エラーが発生しました。</v-card-title>
    <v-card-text>
      <p>ステータスコード: {{ error.statusCode }}</p>
      <p>エラーメッセージ: {{ error.message }}</p>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn color="primary" x-large>
        <a class="go-back-link" @click="$router.back()"><span class="text">前のページへ戻る</span></a>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { NuxtError } from '@nuxt/types';

@Component
export default class ErrorLayout extends Vue {
  @Prop() error!: NuxtError;
}
</script>

<style lang="scss">
.go-back-link {
  text-decoration: none;
  > .text {
    color: white;
  }
}
</style>
