import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import { VBtn, VCol, VDivider, VIcon, VList, VListItem, VListItemAction, VListItemAvatar, VListItemContent, VListItemIcon, VListItemSubtitle, VListItemTitle, VMenu, VRow, VTooltip } from 'vuetify/lib'

import options from './options'

Vue.use(Vuetify, {
  components: { VBtn, VCol, VDivider, VIcon, VList, VListItem, VListItemAction, VListItemAvatar, VListItemContent, VListItemIcon, VListItemSubtitle, VListItemTitle, VMenu, VRow, VTooltip }
})

export default (ctx) => {
  const vuetifyOptions = typeof options === 'function' ? options(ctx) : options

  const vuetify = new Vuetify(vuetifyOptions)

  ctx.app.vuetify = vuetify
  ctx.$vuetify = vuetify.framework
}
