import type { PracticeAreasApi } from '@gen/wklr-backend-api/v1/api/practice-areas-api';
import type { PracticeAreaGroup, GetPracticeAreaSnippetsResponse } from '@gen/wklr-backend-api/v1/model';
import { CacheTTL } from '../constants';

export class PracticeAreasRepository {
  constructor(private api: PracticeAreasApi) {}

  async getGroups(): Promise<PracticeAreaGroup[]> {
    const res = await this.api.getAllPracticeAreaGroups({
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return res.data;
  }

  async getSnippets(practiceAreaId: string): Promise<GetPracticeAreaSnippetsResponse> {
    const response = await this.api.getPracticeAreaSnippets(practiceAreaId, {
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return response.data;
  }
}
