import type { Toc } from '@gen/wklr-backend-api/v1/model';

/**
 * Dateを「昭和48」「令和元」のような文字列に整形
 * @param date
 * @param era
 */
export function dateToWarekiYear(date: Date, era: 'short' | 'narrow' = 'short') {
  // FIXME: no IE support
  const dateString = date.toLocaleDateString('ja-JP-u-ca-japanese', { era, year: 'numeric' });
  const numericMatch = dateString.match(/(\D+)(\d+)年/);

  if (numericMatch == null) {
    const firstYearMatch = dateString.match(/(\D+)元年$/);
    const [_, e] = firstYearMatch ? firstYearMatch : [];
    return `（${e}元）`;
  }

  const [_, e, y] = numericMatch;
  return '（' + e + y + '）';
}

/**
 * DateあるいはDateに変換できる文字列を「1992（平成4）年9月28日」のような文字列に整形
 * @param date
 */
export function formatYmd(date: Date | string | null) {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const y = date.getFullYear(),
    m = date.getMonth() + 1,
    d = date.getDate(),
    w = dateToWarekiYear(date);

  return `${y}${w}年${m}月${d}日`;
}

/**
 * TOCだけから判断して書籍上のページに対応しうる最初のセクションを求める
 * @param toc TOCデータ
 * @param seq 書籍上のページ (0-index)
 */
export function getKeyFromSeq(toc: Toc | null, seq: number): number | undefined {
  if (!toc || !toc.byKey) {
    return;
  }

  // 指定された seq よりも小さくて最大の pageSeq を持つ TocNode の key をストアする
  let isPageUnique = true;
  let candidate = 0;
  for (const { pageSeq, key } of toc.byKey) {
    if (pageSeq !== toc.byKey[0].pageSeq) {
      isPageUnique = false;
    }
    if (pageSeq <= seq) {
      candidate = key;
    } else {
      break;
    }
  }

  if (isPageUnique) {
    // ページ番号が全て一緒の場合は０を返す。法令などpdfから生成されていないデータが該当する
    return 0;
  } else {
    return candidate;
  }
}
