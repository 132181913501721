import type {
  AccessibleDocRecordTiny,
  DocRecord,
  DocRecordTiny,
  AccessibleDocRecord,
} from '@gen/wklr-backend-api/v1/model';
import type { Viewer } from '../types/Viewer';

/**
 * ドキュメントが AccessibleDocRecordTiny か UnaccessibleDocRecordTiny かを判別して型アサーションして返す
 * @param doc DocRecordTiny
 * @returns
 */

export function isAccessible(doc: DocRecordTiny): doc is AccessibleDocRecordTiny;
export function isAccessible(doc: DocRecord): doc is AccessibleDocRecord;

export function isAccessible(doc: DocRecordTiny | DocRecord): doc is AccessibleDocRecordTiny | AccessibleDocRecord {
  return doc.docAccessible === true;
}

export function hasText(doc: AccessibleDocRecordTiny | AccessibleDocRecord): boolean {
  return doc.isWebViewAvailable || isPdfViewAvailable(doc);
}

export function isPdfViewAvailable(doc: AccessibleDocRecordTiny | AccessibleDocRecord): boolean {
  return doc.pdfFileURI !== null && doc.pdfFileURI !== '';
}

export function isReserved(entry: { availableFrom?: Date; purchased?: boolean }): boolean {
  return !!entry.availableFrom && entry.availableFrom.getTime() > new Date().getTime() && !!entry.purchased;
}

export function getAvailableViewers(doc: DocRecordTiny | DocRecord): Viewer[] {
  const viewers: Viewer[] = [];
  if (!isAccessible(doc)) {
    return viewers;
  }
  if (isPdfViewAvailable(doc)) {
    viewers.push('pdf');
  }

  if (doc.isWebViewAvailable) {
    viewers.push('web');
  }

  return viewers;
}

export type AcquisitionType = 'user_purchased' | 'subscription';

export function getAcquisitionType(doc: DocRecord): AcquisitionType {
  return doc.purchased ? 'user_purchased' : 'subscription';
}

export const isAccessibleDocRecord = (record: DocRecord): record is AccessibleDocRecord => {
  return 'docAccessible' in record && record.docAccessible === true;
};
