/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetLabsWandhAgreement200ResponseInner } from '../model';
// @ts-ignore
import { GetLabsWandhQuota200Response } from '../model';
// @ts-ignore
import { GetQuestionHistoryDetail200Response } from '../model';
// @ts-ignore
import { ListQuestionHistories200ResponseInner } from '../model';
// @ts-ignore
import { PostLabsWandhEvaluationParamsBody } from '../model';
// @ts-ignore
import { PostLabsWandhQuestion200Response } from '../model';
// @ts-ignore
import { PostLabsWandhQuestionParamsBody } from '../model';
/**
 * LabsApi - axios parameter creator
 * @export
 */
export const LabsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 秘密保持誓約に同意した記録をデータベースから取得する
         * @summary 秘密保持誓約の同意記録を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabsWandhAgreement: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/labs/wandh/agreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Watson & Holmesの残クオータを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabsWandhQuota: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/labs/wandh/quota`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 質問履歴一覧を取得する
         * @param {string} id 履歴ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionHistoryDetail: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getQuestionHistoryDetail', 'id', id)
            const localVarPath = `/labs/wandh/question/histories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 質問履歴一覧を取得する
         * @param {number} [page] ページ
         * @param {number} [perPage] 1ページあたりの件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listQuestionHistories: async (page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/labs/wandh/question/histories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['perPage'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 秘密保持誓約に同意したことをデータベースに記録する
         * @summary 秘密保持誓約に同意する
         * @param {Array<string>} id 同意する項目のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLabsWandhAgreement: async (id: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('postLabsWandhAgreement', 'id', id)
            const localVarPath = `/labs/wandh/agreement`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id) {
                localVarQueryParameter['id'] = id.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Watson & Holmesの生成結果に評価を送信する。同一のセッションに複数回評価を送ってもよい（上書きされる）
         * @summary Watson & Holmesの生成結果に評価を送信する
         * @param {PostLabsWandhEvaluationParamsBody} [postLabsWandhEvaluationParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLabsWandhEvaluation: async (postLabsWandhEvaluationParamsBody?: PostLabsWandhEvaluationParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/labs/wandh/evaluation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postLabsWandhEvaluationParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Watson & Holmesに自然言語で書かれた質問文を与え、処理を開始するために必要なトークンと接続先のホストを得る。 GAEではストリーミングができないため、ストリーミングを実現するためにはクライアントサイドから直接labs-wandhに通信する必要があり、その認証のための一時的なトークンを返す。 トークンは十分有効期限を短くするため、質問1回ごとにトークンを再取得させる。 
         * @summary Watson & Holmesに質問をリクエストする。
         * @param {PostLabsWandhQuestionParamsBody} [postLabsWandhQuestionParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLabsWandhQuestion: async (postLabsWandhQuestionParamsBody?: PostLabsWandhQuestionParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/labs/wandh/question`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postLabsWandhQuestionParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LabsApi - functional programming interface
 * @export
 */
export const LabsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LabsApiAxiosParamCreator(configuration)
    return {
        /**
         * 秘密保持誓約に同意した記録をデータベースから取得する
         * @summary 秘密保持誓約の同意記録を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLabsWandhAgreement(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetLabsWandhAgreement200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLabsWandhAgreement(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Watson & Holmesの残クオータを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLabsWandhQuota(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetLabsWandhQuota200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLabsWandhQuota(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 質問履歴一覧を取得する
         * @param {string} id 履歴ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionHistoryDetail(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetQuestionHistoryDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuestionHistoryDetail(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 質問履歴一覧を取得する
         * @param {number} [page] ページ
         * @param {number} [perPage] 1ページあたりの件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listQuestionHistories(page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListQuestionHistories200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listQuestionHistories(page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 秘密保持誓約に同意したことをデータベースに記録する
         * @summary 秘密保持誓約に同意する
         * @param {Array<string>} id 同意する項目のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLabsWandhAgreement(id: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLabsWandhAgreement(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Watson & Holmesの生成結果に評価を送信する。同一のセッションに複数回評価を送ってもよい（上書きされる）
         * @summary Watson & Holmesの生成結果に評価を送信する
         * @param {PostLabsWandhEvaluationParamsBody} [postLabsWandhEvaluationParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLabsWandhEvaluation(postLabsWandhEvaluationParamsBody?: PostLabsWandhEvaluationParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLabsWandhEvaluation(postLabsWandhEvaluationParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Watson & Holmesに自然言語で書かれた質問文を与え、処理を開始するために必要なトークンと接続先のホストを得る。 GAEではストリーミングができないため、ストリーミングを実現するためにはクライアントサイドから直接labs-wandhに通信する必要があり、その認証のための一時的なトークンを返す。 トークンは十分有効期限を短くするため、質問1回ごとにトークンを再取得させる。 
         * @summary Watson & Holmesに質問をリクエストする。
         * @param {PostLabsWandhQuestionParamsBody} [postLabsWandhQuestionParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLabsWandhQuestion(postLabsWandhQuestionParamsBody?: PostLabsWandhQuestionParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostLabsWandhQuestion200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLabsWandhQuestion(postLabsWandhQuestionParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LabsApi - factory interface
 * @export
 */
export const LabsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LabsApiFp(configuration)
    return {
        /**
         * 秘密保持誓約に同意した記録をデータベースから取得する
         * @summary 秘密保持誓約の同意記録を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabsWandhAgreement(options?: any): AxiosPromise<Array<GetLabsWandhAgreement200ResponseInner>> {
            return localVarFp.getLabsWandhAgreement(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Watson & Holmesの残クオータを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLabsWandhQuota(options?: any): AxiosPromise<GetLabsWandhQuota200Response> {
            return localVarFp.getLabsWandhQuota(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 質問履歴一覧を取得する
         * @param {string} id 履歴ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionHistoryDetail(id: string, options?: any): AxiosPromise<GetQuestionHistoryDetail200Response> {
            return localVarFp.getQuestionHistoryDetail(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 質問履歴一覧を取得する
         * @param {number} [page] ページ
         * @param {number} [perPage] 1ページあたりの件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listQuestionHistories(page?: number, perPage?: number, options?: any): AxiosPromise<Array<ListQuestionHistories200ResponseInner>> {
            return localVarFp.listQuestionHistories(page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 秘密保持誓約に同意したことをデータベースに記録する
         * @summary 秘密保持誓約に同意する
         * @param {Array<string>} id 同意する項目のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLabsWandhAgreement(id: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.postLabsWandhAgreement(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Watson & Holmesの生成結果に評価を送信する。同一のセッションに複数回評価を送ってもよい（上書きされる）
         * @summary Watson & Holmesの生成結果に評価を送信する
         * @param {PostLabsWandhEvaluationParamsBody} [postLabsWandhEvaluationParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLabsWandhEvaluation(postLabsWandhEvaluationParamsBody?: PostLabsWandhEvaluationParamsBody, options?: any): AxiosPromise<void> {
            return localVarFp.postLabsWandhEvaluation(postLabsWandhEvaluationParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Watson & Holmesに自然言語で書かれた質問文を与え、処理を開始するために必要なトークンと接続先のホストを得る。 GAEではストリーミングができないため、ストリーミングを実現するためにはクライアントサイドから直接labs-wandhに通信する必要があり、その認証のための一時的なトークンを返す。 トークンは十分有効期限を短くするため、質問1回ごとにトークンを再取得させる。 
         * @summary Watson & Holmesに質問をリクエストする。
         * @param {PostLabsWandhQuestionParamsBody} [postLabsWandhQuestionParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLabsWandhQuestion(postLabsWandhQuestionParamsBody?: PostLabsWandhQuestionParamsBody, options?: any): AxiosPromise<PostLabsWandhQuestion200Response> {
            return localVarFp.postLabsWandhQuestion(postLabsWandhQuestionParamsBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LabsApi - interface
 * @export
 * @interface LabsApi
 */
export interface LabsApiInterface {
    /**
     * 秘密保持誓約に同意した記録をデータベースから取得する
     * @summary 秘密保持誓約の同意記録を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApiInterface
     */
    getLabsWandhAgreement(options?: AxiosRequestConfig): AxiosPromise<Array<GetLabsWandhAgreement200ResponseInner>>;

    /**
     * 
     * @summary Watson & Holmesの残クオータを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApiInterface
     */
    getLabsWandhQuota(options?: AxiosRequestConfig): AxiosPromise<GetLabsWandhQuota200Response>;

    /**
     * 
     * @summary 質問履歴一覧を取得する
     * @param {string} id 履歴ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApiInterface
     */
    getQuestionHistoryDetail(id: string, options?: AxiosRequestConfig): AxiosPromise<GetQuestionHistoryDetail200Response>;

    /**
     * 
     * @summary 質問履歴一覧を取得する
     * @param {number} [page] ページ
     * @param {number} [perPage] 1ページあたりの件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApiInterface
     */
    listQuestionHistories(page?: number, perPage?: number, options?: AxiosRequestConfig): AxiosPromise<Array<ListQuestionHistories200ResponseInner>>;

    /**
     * 秘密保持誓約に同意したことをデータベースに記録する
     * @summary 秘密保持誓約に同意する
     * @param {Array<string>} id 同意する項目のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApiInterface
     */
    postLabsWandhAgreement(id: Array<string>, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Watson & Holmesの生成結果に評価を送信する。同一のセッションに複数回評価を送ってもよい（上書きされる）
     * @summary Watson & Holmesの生成結果に評価を送信する
     * @param {PostLabsWandhEvaluationParamsBody} [postLabsWandhEvaluationParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApiInterface
     */
    postLabsWandhEvaluation(postLabsWandhEvaluationParamsBody?: PostLabsWandhEvaluationParamsBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Watson & Holmesに自然言語で書かれた質問文を与え、処理を開始するために必要なトークンと接続先のホストを得る。 GAEではストリーミングができないため、ストリーミングを実現するためにはクライアントサイドから直接labs-wandhに通信する必要があり、その認証のための一時的なトークンを返す。 トークンは十分有効期限を短くするため、質問1回ごとにトークンを再取得させる。 
     * @summary Watson & Holmesに質問をリクエストする。
     * @param {PostLabsWandhQuestionParamsBody} [postLabsWandhQuestionParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApiInterface
     */
    postLabsWandhQuestion(postLabsWandhQuestionParamsBody?: PostLabsWandhQuestionParamsBody, options?: AxiosRequestConfig): AxiosPromise<PostLabsWandhQuestion200Response>;

}

/**
 * LabsApi - object-oriented interface
 * @export
 * @class LabsApi
 * @extends {BaseAPI}
 */
export class LabsApi extends BaseAPI implements LabsApiInterface {
    /**
     * 秘密保持誓約に同意した記録をデータベースから取得する
     * @summary 秘密保持誓約の同意記録を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public getLabsWandhAgreement(options?: AxiosRequestConfig) {
        return LabsApiFp(this.configuration).getLabsWandhAgreement(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Watson & Holmesの残クオータを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public getLabsWandhQuota(options?: AxiosRequestConfig) {
        return LabsApiFp(this.configuration).getLabsWandhQuota(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 質問履歴一覧を取得する
     * @param {string} id 履歴ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public getQuestionHistoryDetail(id: string, options?: AxiosRequestConfig) {
        return LabsApiFp(this.configuration).getQuestionHistoryDetail(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 質問履歴一覧を取得する
     * @param {number} [page] ページ
     * @param {number} [perPage] 1ページあたりの件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public listQuestionHistories(page?: number, perPage?: number, options?: AxiosRequestConfig) {
        return LabsApiFp(this.configuration).listQuestionHistories(page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 秘密保持誓約に同意したことをデータベースに記録する
     * @summary 秘密保持誓約に同意する
     * @param {Array<string>} id 同意する項目のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public postLabsWandhAgreement(id: Array<string>, options?: AxiosRequestConfig) {
        return LabsApiFp(this.configuration).postLabsWandhAgreement(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Watson & Holmesの生成結果に評価を送信する。同一のセッションに複数回評価を送ってもよい（上書きされる）
     * @summary Watson & Holmesの生成結果に評価を送信する
     * @param {PostLabsWandhEvaluationParamsBody} [postLabsWandhEvaluationParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public postLabsWandhEvaluation(postLabsWandhEvaluationParamsBody?: PostLabsWandhEvaluationParamsBody, options?: AxiosRequestConfig) {
        return LabsApiFp(this.configuration).postLabsWandhEvaluation(postLabsWandhEvaluationParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Watson & Holmesに自然言語で書かれた質問文を与え、処理を開始するために必要なトークンと接続先のホストを得る。 GAEではストリーミングができないため、ストリーミングを実現するためにはクライアントサイドから直接labs-wandhに通信する必要があり、その認証のための一時的なトークンを返す。 トークンは十分有効期限を短くするため、質問1回ごとにトークンを再取得させる。 
     * @summary Watson & Holmesに質問をリクエストする。
     * @param {PostLabsWandhQuestionParamsBody} [postLabsWandhQuestionParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LabsApi
     */
    public postLabsWandhQuestion(postLabsWandhQuestionParamsBody?: PostLabsWandhQuestionParamsBody, options?: AxiosRequestConfig) {
        return LabsApiFp(this.configuration).postLabsWandhQuestion(postLabsWandhQuestionParamsBody, options).then((request) => request(this.axios, this.basePath));
    }
}
