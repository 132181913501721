/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * クイックアクセス要素の PDF ビュー固有のプロパティ 
 * @export
 * @interface QuickAccessItemTypePdfProperties
 */
export interface QuickAccessItemTypePdfProperties {
    /**
     * クイックアクセスに登録されたときのビューを識別する文字列
     * @type {string}
     * @memberof QuickAccessItemTypePdfProperties
     */
    'viewType': QuickAccessItemTypePdfPropertiesViewTypeEnum;
    /**
     * 0-based な PDF のページ番号
     * @type {number}
     * @memberof QuickAccessItemTypePdfProperties
     */
    'pageSeq': number;
    /**
     * この要素が登録された箇所を特定するのに参考情報の文字列表現。 PDF ビューの場合はページ番号を指す。 
     * @type {string}
     * @memberof QuickAccessItemTypePdfProperties
     */
    'headingText': string;
}

export const QuickAccessItemTypePdfPropertiesViewTypeEnum = {
    Pdf: 'pdf'
} as const;

export type QuickAccessItemTypePdfPropertiesViewTypeEnum = typeof QuickAccessItemTypePdfPropertiesViewTypeEnum[keyof typeof QuickAccessItemTypePdfPropertiesViewTypeEnum];


