import { PartialSearchQuery, searchQueryValueLabelMap, publishers } from '@/types/SearchQuery';
import rison from 'rison';
import { DocumentTypeEnum } from 'wklr-backend-sdk/models';
import { SearchQueryType } from '@/types/SearchQuery';
import { PluginExtension } from '@/types/pluginExtension';

type DOCTYPE = Readonly<Required<PartialSearchQuery>['type']>;
const DEFAULT_DOCTYPES: DOCTYPE = [DocumentTypeEnum.Book];

export const parseQuery = (q: string, { $auth }: PluginExtension): PartialSearchQuery => {
  const query = rison.decode_object<PartialSearchQuery>(q);
  const originalType = $auth.permissions.legalweb
    ? query.type
    : query.type && query.type.filter((t) => t === DocumentTypeEnum.Book);
  const type: DOCTYPE = originalType === undefined || originalType.length < 1 ? DEFAULT_DOCTYPES : originalType;
  return { ...query, type: [...type] };
};

const checkIsArrayString = (arr: unknown): arr is string[] =>
  Array.isArray(arr) && arr.every((item) => typeof item === 'string');

const checkIsArrayDocumentType = (arr: unknown): arr is SearchQueryType[] =>
  Array.isArray(arr) && arr.every((t: string) => Object.values(DocumentTypeEnum).some((x) => x === t));

const VALID_KEYS = [
  'id',
  'keyword',
  'title',
  'author',
  'publisher',
  'publishedOn',
  'type',
  'tagId',
  'hideOlderEdition',
  'includeOlderEditions',
  'maxGaps',
] as Readonly<string[]>;

export const parseStringToPartialSearchQuery = (queryString: string): PartialSearchQuery => {
  const query: PartialSearchQuery = JSON.parse(queryString);
  if (typeof query.keyword !== 'string') delete query.keyword;
  if (typeof query.id !== 'string') delete query.id;
  if (typeof query.title !== 'string') delete query.title;
  if (typeof query.author !== 'string') delete query.author;
  if (!checkIsArrayString(query.publisher)) delete query.publisher;
  if (query.publishedOn !== undefined) {
    const gte = query.publishedOn.gte ? query.publishedOn.gte : undefined;
    const lte = query.publishedOn.lte ? query.publishedOn.lte : undefined;
    if (!(gte || lte)) {
      delete query.publishedOn;
    } else {
      if (gte) query.publishedOn.gte = gte;
      if (lte) query.publishedOn.lte = lte;
    }
  }
  if (!checkIsArrayDocumentType(query.type)) delete query.type;
  if (!checkIsArrayString(query.tagId)) delete query.tagId;
  if (typeof query.maxGaps !== 'number') delete query.maxGaps;
  const objKeys = Object.keys(query);
  const validKeys = objKeys.filter((key) => VALID_KEYS.includes(key));

  if (validKeys.length < 1) {
    throw new Error(`クエリ文字列のパースエラー: ${queryString}`);
  }
  if (validKeys.length !== objKeys.length) {
    console.error(`対象外のキーがクエリ文字列に含まれています: ${queryString}`);
  }
  return query;
};

/**
 * キーワードをスペースで分割する
 * @param keyword 分割したいキーワード
 * @returns
 * - keywords string[] キーワードを分割した配列
 * - isLastBlank boolean 文末に空白が入っていたか
 */
export const splitKeyword = (keyword: string): { keywords: string[]; isLastBlank: boolean } => {
  const splitted = keyword
    .split('\u3000') // 全角スペースを
    .join('\u0020') // 半角スペースに置き換えて
    .split('\u0020'); // 半角スペースで分割する
  const filtered = splitted.filter((kw) => kw !== '');
  const isLastBlank = filtered.length > 0 && splitted[splitted.length - 1] === '';
  return {
    keywords: filtered,
    isLastBlank,
  };
};

export const StaticData = {
  types: searchQueryValueLabelMap,
  publishers,
} as const;
