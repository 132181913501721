<template>
  <div class="profile">
    <v-tooltip bottom>
      <template #activator="{ on, attrs }">
        <wklr-icon-btn v-bind="attrs" type="user" size="normal" @click="$router.push('/profile')" v-on="on" />
      </template>
      クリックするとプロフィールページに遷移します
    </v-tooltip>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import WklrIconBtn from '@/components/shared/wklr-icon-btn.vue';

export default defineComponent({ components: { WklrIconBtn } });
</script>

<style lang="scss">
.profile {
  padding-left: 2px;
  padding-top: 2px;
}
</style>
