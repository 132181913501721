import "core-js/modules/es.string.starts-with.js";
export default (function (_ref, inject) {
  var app = _ref.app;
  var router = app.router,
    $telemetry = app.$telemetry;
  if (router === undefined) return;
  router.afterEach(function (to, from) {
    if (to.path.startsWith('/labs/wandh') && to.params.id) {
      $telemetry.sendWandhViewTelemetry({
        historyId: to.params.id
      }, to);
    } else if (to.path.startsWith('/labs/wandh')) {
      $telemetry.sendWandhViewTelemetry({}, to);
    }
  });
});