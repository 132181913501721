import type { NewsApi } from '@gen/wklr-backend-api/v1/api/news-api';
import type { NewsWithCategory } from '@gen/wklr-backend-api/v1/model';
import { formatYmd } from '../utility';

export class NewsRepository {
  constructor(private api: NewsApi) {}

  async getNews(categoryId?: string): Promise<NewsWithCategory[]> {
    const { data } = await this.api.getNews(categoryId);
    data.map((news) => {
      news.publishedOn = formatYmd(news.publishedOn);
    });
    return data;
  }
}
