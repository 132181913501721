import { DocRecord, DocumentInfoForToc, TocNode, UnaccessibleDocRecord } from 'wklr-backend-sdk/models';
import { TocInteractive } from '@/types/Viewer';

export type AllVolumesToc = {
  docId: string;
  docTitle: string;
  volumeInfo: VolumeInfo;
  isSeqUnique: boolean;
  isLaw: boolean;
  folioPerSeq: string[] | null;
  rootNodeKeys: number[];
  totalSearchHits: number;
}[];

export interface VolumeInfo {
  volumeNumber: string;
  volumeNumberSuffix?: string;
  volumeTitle?: string;
  rangeDescription?: string;
}

export const hardcodedVolumeInfo: Record<string, VolumeInfo> = {
  kommentar_shojihomu_kaishaho_1: {
    volumeNumber: '1',
    volumeNumberSuffix: '巻',
    volumeTitle: '総則・設立 ⑴',
    rangeDescription: '1〜31条',
  },
  kommentar_shojihomu_kaishaho_2: {
    volumeNumber: '2',
    volumeNumberSuffix: '巻',
    volumeTitle: '設立 ⑵',
    rangeDescription: '32〜103条',
  },
  kommentar_shojihomu_kaishaho_3: {
    volumeNumber: '3',
    volumeNumberSuffix: '巻',
    volumeTitle: '株式 ⑴',
    rangeDescription: '104〜154の2条',
  },
  kommentar_shojihomu_kaishaho_4: {
    volumeNumber: '4',
    volumeNumberSuffix: '巻',
    volumeTitle: '株式 ⑵',
    rangeDescription: '155〜198条',
  },
  kommentar_shojihomu_kaishaho_5: {
    volumeNumber: '5',
    volumeNumberSuffix: '巻',
    volumeTitle: '株式 ⑶',
    rangeDescription: '199〜235条',
  },
  kommentar_shojihomu_kaishaho_6: {
    volumeNumber: '6',
    volumeNumberSuffix: '巻',
    volumeTitle: '新株予約権',
    rangeDescription: '236〜294条',
  },
  kommentar_shojihomu_kaishaho_7: {
    volumeNumber: '7',
    volumeNumberSuffix: '巻',
    volumeTitle: '機関 ⑴',
    rangeDescription: '295〜347条',
  },
  kommentar_shojihomu_kaishaho_8: {
    volumeNumber: '8',
    volumeNumberSuffix: '巻',
    volumeTitle: '機関 ⑵',
    rangeDescription: '348〜395条',
  },
  kommentar_shojihomu_kaishaho_9: {
    volumeNumber: '9',
    volumeNumberSuffix: '巻',
    volumeTitle: '機関 ⑶',
    rangeDescription: '396〜430条',
  },
  kommentar_shojihomu_kaishaho_10: {
    volumeNumber: '10',
    volumeNumberSuffix: '巻',
    volumeTitle: '計算等 ⑴',
    rangeDescription: '431〜444条',
  },
  kommentar_shojihomu_kaishaho_11: {
    volumeNumber: '11',
    volumeNumberSuffix: '巻',
    volumeTitle: '計算等 ⑵',
    rangeDescription: '445〜465条',
  },
  kommentar_shojihomu_kaishaho_12: {
    volumeNumber: '12',
    volumeNumberSuffix: '巻',
    rangeDescription: '466〜509条',
  },
  kommentar_shojihomu_kaishaho_13: {
    volumeNumber: '13',
    volumeNumberSuffix: '巻',
    volumeTitle: '清算 ⑵',
    rangeDescription: '510〜574条',
  },
  kommentar_shojihomu_kaishaho_14: {
    volumeNumber: '14',
    volumeNumberSuffix: '巻',
    volumeTitle: '持分会社 ⑴',
    rangeDescription: '575〜613条',
  },
  kommentar_shojihomu_kaishaho_15: {
    volumeNumber: '15',
    volumeNumberSuffix: '巻',
    volumeTitle: '持分会社 ⑵',
    rangeDescription: '614〜675条',
  },
  kommentar_shojihomu_kaishaho_16: {
    volumeNumber: '16',
    volumeNumberSuffix: '巻',
    volumeTitle: '社債',
    rangeDescription: '676〜742条',
  },
  kommentar_shojihomu_kaishaho_17: {
    volumeNumber: '17',
    volumeNumberSuffix: '巻',
    rangeDescription: '743〜774条',
  },
  kommentar_shojihomu_kaishaho_18: {
    volumeNumber: '18',
    volumeNumberSuffix: '巻',
    rangeDescription: '775〜816条',
  },
  kommentar_shojihomu_kaishaho_19: {
    volumeNumber: '19',
    volumeNumberSuffix: '巻',
    volumeTitle: '外国会社・雑則 ⑴',
    rangeDescription: '817〜867条',
  },
  kommentar_shojihomu_kaishaho_20: {
    volumeNumber: '20',
    volumeNumberSuffix: '巻',
    volumeTitle: '雑則 ⑵',
    rangeDescription: '868〜938条',
  },
  kommentar_shojihomu_kaishaho_21: {
    volumeNumber: '21',
    volumeNumberSuffix: '巻',
    volumeTitle: '雑則 ⑶・罰則',
    rangeDescription: '939〜979条',
  },
  kommentar_shojihomu_kaishaho_sup: {
    volumeNumber: '補巻',
    volumeTitle: '平成26年改正',
  },
};

/** API から返却された TocNode にフロントエンドで必要な情報を付与したもの */
export interface TocNodeExtended extends TocNode {
  docId: string;
  docTitle: string;
  isLeaf: boolean;
  isRoot: boolean;
  folioLabel: string;
  /**
   * 階層の論理深さ
   *
   * depthは例えばコンメンタールでは条を必ず5にするなど飛び飛びの値をとる
   * これを実際に当該文献に出現する値だけの連番に変換したもの
   * （ただし、以下の例のように必ずしも「論理深さは常に親の論理深さ + 1」ではないことに注意）
   *
   * ```
   * 第1部         (depth = 0, logicalDepth = 0)
   *   ├─ 第1章    (depth = 3, logicalDepth = 1)
   *   │  └─ 第1節 (depth = 5, logicalDepth = 2)
   *   └─ 第2章    (depth = 3, logicalDepth = 1)
   * 第2部         (depth = 0, logicalDepth = 0)
   *   └─ 第1節    (depth = 5, logicalDepth = 2) ← 「節」を一貫して2に割り当てる
   * ```
   */
  logicalDepth: number;
}

/** API から返却された DocRecord にフロントエンドで必要な情報を付与したもの */
export type DocRecordExtended = DocRecord & {
  isSeqUnique?: boolean;
  tocInteractive: Record<string, TocNodeExtended>;
};

/** API から返却された UnaccessibleDocRecord にフロントエンドで必要な情報を付与したもの */
export type UnaccessibleDocRecordExtended = UnaccessibleDocRecord & {
  isSeqUnique?: boolean;
  tocInteractive: Record<string, TocNodeExtended>;
};

export const tocMapper = (
  doc: DocumentInfoForToc,
  tocByKey: TocNode[],
  folioPerSeq: string[] | null,
): [string, TocNodeExtended][] => {
  const { docId, docTitle } = doc;
  const allDepthValues = new Set(tocByKey.map(({ depth }) => depth || 0));
  const logicalDepthMap = new Map(
    Array.from(allDepthValues)
      .sort((a, b) => a - b)
      .map((physical, logical) => [physical, logical]),
  );

  const mapped: [string, TocNodeExtended][] = tocByKey.map((toc, index) => {
    const previousPageSeq = index > 0 ? tocByKey[index - 1].pageSeq : undefined;
    let newPageSeq: string | undefined;
    // folioPerSeq に対応するページがある場合はそれを利用する
    if (folioPerSeq !== null) {
      newPageSeq = folioPerSeq[toc.pageSeq];
    }
    // folioPerSeq に対応するページがない場合と、直前の ToC の pageSeq の値が異なっている場合のみ pageSeq の数値をページ番号とみなす（ 0-indexed なので +1 して利用）
    if (folioPerSeq !== null || toc.pageSeq !== previousPageSeq) {
      newPageSeq ||= String(toc.pageSeq + 1);
    }
    let folioLabel = '';
    if (newPageSeq) {
      const label = newPageSeq && isNaN(parseInt(newPageSeq)) ? newPageSeq : `P${newPageSeq}`;
      folioLabel = ` (${label})`;
    }

    return [
      tocReferenceKey(docId, toc.key),
      {
        ...toc,
        depth: toc.depth || 0,
        // mapの作り方からして安全
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        logicalDepth: logicalDepthMap.get(toc.depth || 0)!,
        // &nbsp;はunicodeで\u00A0に割り当てられている。
        // https://ja.wikipedia.org/wiki/%E3%83%8E%E3%83%BC%E3%83%96%E3%83%AC%E3%83%BC%E3%82%AF%E3%82%B9%E3%83%9A%E3%83%BC%E3%82%B9
        label: toc.label || '\u00A0',
        children: toc.children || [],
        docId,
        docTitle,
        isLeaf: toc.children === undefined || toc.children.length === 0,
        isRoot: toc.parent === -1,
        folioLabel,
      },
    ];
  });
  return mapped;
};

export const generateNewToc = <T>(
  tocOriginal: Record<string, T>,
  processor: (original: T) => TocInteractive,
  docId?: string,
) => {
  return docId === undefined
    ? Object.fromEntries(Object.entries(tocOriginal).map(([key, toc]) => [key, processor(toc)]))
    : Object.fromEntries(
        Object.entries(tocOriginal)
          .filter(([key]) => key.startsWith(tocReferenceKeyPrefix(docId)))
          .map(([key, toc]) => [key, processor(toc)]),
      );
};

export const tocReferenceKeyPrefix = (docId: string) => `${docId}/`;

export const tocReferenceKey = (docId: string, key: number) => `${tocReferenceKeyPrefix(docId)}${key}`;
