import Vue from 'vue';
import axios from 'axios';
import { isAxiosError } from '@/utils/axiosUtis';

const errorHandler = async (error: Error, vm: Vue, info: string): Promise<boolean> => {
  if (axios.isCancel(error)) {
    return true;
  }

  if (isAxiosError(error)) {
    if (error.response != null) {
      const response = error.response;
      if ([403, 401].includes(response.status)) {
        // 描画前にmiddlewareでエラーになった場合vmが無いためチェック
        if (vm) {
          vm.$toast.error('再ログインが必要です。');
        }
        // 403, 401 => login required
        if (response.data.redirectUrl) {
          location.href = response.data.redirectUrl;
        } else {
          location.href = `/auth/logout?return=${encodeURIComponent(location.href)}`;
        }

        return true;
      }
    }
  }

  if (!vm) {
    // Vueの初期化前のエラー → あんまりできることが無い
    console.error('Legalscape pre-rendering error', { error, info });

    return true;
  }

  if (isAxiosError(error) && error.response != null) {
    // Not 403 => most likely application bug
    console.error('Legalscape API error', { error, info }, error.response);

    // Show toast
    const path = (error.response.config.url || '').split(error.response.config.baseURL || '').join('');
    vm.$toast.error(
      `APIエラー: ${error.response.status} ${error.response.statusText} (${error.response.config.method} ${path})`,
    );

    // Send telemetry
    vm.$telemetry
      .sendErrorTelemetry(error, vm.$route)
      .catch(() => console.error('While trying to report the error, another API request failed', { error, info }));
  } else {
    console.error('Legalscape application error', { error, info });

    // Show toast
    if (vm.$nuxt.context.isDev) {
      vm.$toast.error(`アプリケーションエラー: ${error} [${info}]`);
    }

    // Send telemetry
    vm.$telemetry
      .sendErrorTelemetry(error, vm.$route)
      .catch(() => console.error('While trying to report the error, another API request failed', { error, info }));
  }

  // Silently ignore the error
  return true;
};

Vue.config.errorHandler = errorHandler;
window.addEventListener('error', (event) => {
  try {
    errorHandler(event.error, window.$nuxt, '*onerror');
  } catch (_) {
    console.error('While trying to report the error, another API request failed', event, '*onerror');
  }
});
window.addEventListener('unhandledrejection', (event) => {
  try {
    errorHandler(event.reason, window.$nuxt, '*onunhandledrejection');
  } catch (_) {
    console.error('While trying to report the error, another API request failed', event, '*onunhandledrejection');
  }
});
