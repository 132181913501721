import { Context } from '@nuxt/types';
import { Plugin } from '@nuxt/types';
import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';

// 操作をしていないと判断するリクエストの間隔
const INTERVAL_MS = 60 * 60 * 1000;

// 日が変わったと判別するための時刻
const PUNCTUATION_HOUR = 6;

export default <Plugin>function ({ $axios, store }: Context) {
  const now = dayjs();
  let lastCheckedTimestamp = now;
  let firstAccessOfLatestAccessDate = now;

  const hasDayChanged = (currentDatetime: Dayjs): boolean => {
    if (currentDatetime.isSame(firstAccessOfLatestAccessDate, 'date')) return false;
    const lapse = lastCheckedTimestamp.add(INTERVAL_MS, 'millisecond');
    if (currentDatetime.isBefore(lapse)) return false;
    const dateBoundary = currentDatetime.hour(PUNCTUATION_HOUR).minute(0).second(0);
    if (currentDatetime.isBefore(dateBoundary)) return false;
    return true;
  };

  const update = () => {
    const currentDatetime = dayjs();

    if (hasDayChanged(currentDatetime)) {
      store.commit('setFirstAccessOfTheDay', true);
      firstAccessOfLatestAccessDate = currentDatetime.clone();
    }

    lastCheckedTimestamp = currentDatetime;
  };

  // タブフォーカス時に日付変更を検知してリダイレクトを行う
  window.addEventListener('focus', () => {
    update();
  });

  // API リクエスト時に日付変更をチェックする
  $axios.interceptors.response.use((response: unknown) => {
    update();
    return response;
  });
};
