import { render, staticRenderFns } from "./wklr-icon.vue?vue&type=template&id=4087041f"
import script from "./wklr-icon.vue?vue&type=script&lang=ts"
export * from "./wklr-icon.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_cache-loader@4.1.0_webpack@4.47.0__css-loader@4.3_255yk7v3heo6y2fxdgr2hz3tmi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports