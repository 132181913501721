import rison from 'rison';
import dayjs from 'dayjs';
import { isAllPublishersChecked, isAllTypesChecked } from '@/utility';
import * as Constants from '@/constants';
import { PartialSearchQuery, SearchOption } from '@/types/SearchQuery';

type QueryParams = {
  // 検索条件
  q: string;
  // Opensearch経由の検索ワード
  os?: string;
  // ページあたりの表示件数
  n?: string;
  // ソート潤
  o?: string;
  // 開始ページ番号
  p?: string;
};

export type SearchLocation = {
  path: '/search';
  query: QueryParams;
};

/**
 * Location からフルパスを生成する。 Nuxt 外に遷移する時に利用する
 * @param location SearchLocation
 */
export const getPathFromSearchLocation = (location: SearchLocation): string => {
  const numPerPage = location.query.n ? `&n=${location.query.n}` : '';
  const orderId = location.query.o ? `&o=${location.query.o}` : '';
  return `${location.path}?q=${encodeURIComponent(location.query.q)}${numPerPage}${orderId}`;
};

/**
 * クエリ用のオブジェクトをリクエスト用のクエリ文字列に正規化する
 * @param q
 * @returns
 */
const generateQueryObject = (q: PartialSearchQuery): QueryParams => {
  const partialQuery = makeSearchRouteQueryPartial(q);

  const { publishedOn, ...query } = partialQuery;

  // publishedOn.{gte, lte} の値は date.to_string() ではなく 'YYYY-MM-DD' フォーマットなのでそれに合わせる
  const _publishedOn: { gte?: string; lte?: string } = {};
  if (publishedOn && publishedOn.gte !== undefined && publishedOn.gte !== null) {
    _publishedOn.gte = dayjs(publishedOn.gte).format('YYYY-MM-DD');
  }
  if (publishedOn && publishedOn.lte !== undefined && publishedOn.lte !== null) {
    _publishedOn.lte = dayjs(publishedOn.lte).format('YYYY-MM-DD');
  }

  const _query = publishedOn !== undefined ? { ...query, publishedOn: _publishedOn } : query;

  try {
    const queryString = rison.encode_object<PartialSearchQuery>(_query);
    return {
      q: queryString,
    };
  } catch (_) {
    return {
      q: '',
    };
  }
};

/**
 * Nuxt でルーティングするための Location オブジェクトを生成する
 * @param q PartialSearchQuery 検索クエリ
 * @param numParPages number 1ページに表示する数
 */
export const generateSearchRoute = (
  q: PartialSearchQuery,
  currentSearchOption: SearchOption,
  numParPages?: number,
  orderId?: number,
): SearchLocation => {
  const query: QueryParams = generateQueryObject(q);

  // ページ当たりの表示数は指定がなければ現在のものを使う。デフォルト地の場合はパラメータを省略する
  const n = numParPages !== undefined ? numParPages : currentSearchOption.n;
  if (n !== Constants.Search.PerPage) {
    // 渡される値が query をパースしたものであるため、 currentSearchOption に undefined が入るケースがためガードしておく
    query.n = (n || Constants.Search.PerPage).toString();
  }

  // 並び順は指定がなければ現在のものを使う。デフォルト地の場合はパラメータを省略する
  const o = orderId !== undefined ? orderId : currentSearchOption.o;
  if (o !== Constants.Search.OrderId) {
    // 渡される値が query をパースしたものであるため、 currentSearchOption に undefined が入るケースがためガードしておく
    query.o = (o || Constants.Search.OrderId).toString();
  }

  return {
    path: '/search',
    query,
  };
};

/**
 * 検索クエリから空の要素を削除してミニマムなクエリを生成する
 * @param q PartialSearchQuery
 */
export const makeSearchRouteQueryPartial = (q: PartialSearchQuery): PartialSearchQuery => {
  const query: PartialSearchQuery = {};

  if (q.keyword) query.keyword = q.keyword.replace(/\s+/, ' ');
  if (q.title) query.title = q.title;
  if (q.author) query.author = q.author;
  if (q.publisher && q.publisher.length > 0 && !isAllPublishersChecked(q)) query.publisher = q.publisher;
  if (q.publishedOn && (q.publishedOn.gte || q.publishedOn.lte)) {
    query.publishedOn = {};
    if (q.publishedOn.gte) {
      query.publishedOn.gte = q.publishedOn.gte;
    }
    if (q.publishedOn.lte) {
      query.publishedOn.lte = q.publishedOn.lte;
    }
  }
  if (q.type && q.type.length > 0 && !isAllTypesChecked(q)) query.type = q.type;
  if (q.tagId && q.tagId.length > 0) query.tagId = q.tagId;
  if (q.includeOlderEditions) query.includeOlderEditions = q.includeOlderEditions;
  if (typeof q.maxGaps === 'number' && q.maxGaps !== -1) query.maxGaps = q.maxGaps;
  return query;
};

type HrefType =
  /** legalscape 同一ドメイン内のリンク */
  | 'internal'
  /** ハッシュやパラメータを除く部分が一致しているリンク */
  | 'same-path'
  /** ドメイン外への外部リンク */
  | 'external';

/**
 * 渡された URL がどのルートに対応するかを確認する
 * @param url チェックする URL
 */
export const hrefType = (url: URL): HrefType => {
  const current = new URL(location.href);
  if (current.host !== url.host) return 'external';
  if (current.pathname === url.pathname) return 'same-path';
  return 'internal';
};
