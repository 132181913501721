import Vue from 'vue';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
Vue.use(VueToast, {
  dismissible: true,
  duration: 5000
});

// Composition API に対応した VueToast は Vue2 に対応していないのでここで無理やり用意する
export var useToast = function useToast() {
  var _useNuxtApp = useNuxtApp(),
    $toast = _useNuxtApp.$toast;
  return $toast;
};