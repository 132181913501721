/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Subsection
 */
export interface Subsection {
    /**
     * content の通し番号
     * @type {number}
     * @memberof Subsection
     */
    'series': number;
    /**
     * Subsection の場合は \'subsection\' が設定される
     * @type {string}
     * @memberof Subsection
     */
    'type': SubsectionTypeEnum;
    /**
     * Subsection の実体を指し示す key
     * @type {number}
     * @memberof Subsection
     */
    'key': number;
}

export const SubsectionTypeEnum = {
    Subsection: 'subsection'
} as const;

export type SubsectionTypeEnum = typeof SubsectionTypeEnum[keyof typeof SubsectionTypeEnum];


