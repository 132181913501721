/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetPracticeAreaSnippetsResponse } from '../model';
// @ts-ignore
import { PracticeArea } from '../model';
// @ts-ignore
import { PracticeAreaGroup } from '../model';
/**
 * PracticeAreasApi - axios parameter creator
 * @export
 */
export const PracticeAreasApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * プラクティスエリアグループとそれぞれに属するプラクティスエリアをすべて取得する。
         * @summary プラクティスエリアグループをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPracticeAreaGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/practice-area-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary プラクティスエリアをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPracticeAreas: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/practice-areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ID で指定されたプラクティスエリアを取得する。
         * @summary プラクティスエリアを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeArea: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPracticeArea', 'id', id)
            const localVarPath = `/practice-areas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プラクティスエリアグループとそれに属するプラクティスエリアを取得する。
         * @summary ID で指定されたプラクティスエリアグループを取得する
         * @param {string} id Group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeAreaGroup: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPracticeAreaGroup', 'id', id)
            const localVarPath = `/practice-area-groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
         * @summary プラクティスエリアの各種リソースのスニペットを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeAreaSnippets: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPracticeAreaSnippets', 'id', id)
            const localVarPath = `/practice-areas/{id}/snippets`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PracticeAreasApi - functional programming interface
 * @export
 */
export const PracticeAreasApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PracticeAreasApiAxiosParamCreator(configuration)
    return {
        /**
         * プラクティスエリアグループとそれぞれに属するプラクティスエリアをすべて取得する。
         * @summary プラクティスエリアグループをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPracticeAreaGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PracticeAreaGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPracticeAreaGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary プラクティスエリアをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPracticeAreas(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PracticeArea>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPracticeAreas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ID で指定されたプラクティスエリアを取得する。
         * @summary プラクティスエリアを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeArea(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeArea>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPracticeArea(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * プラクティスエリアグループとそれに属するプラクティスエリアを取得する。
         * @summary ID で指定されたプラクティスエリアグループを取得する
         * @param {string} id Group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeAreaGroup(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PracticeAreaGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPracticeAreaGroup(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
         * @summary プラクティスエリアの各種リソースのスニペットを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeAreaSnippets(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPracticeAreaSnippetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPracticeAreaSnippets(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PracticeAreasApi - factory interface
 * @export
 */
export const PracticeAreasApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PracticeAreasApiFp(configuration)
    return {
        /**
         * プラクティスエリアグループとそれぞれに属するプラクティスエリアをすべて取得する。
         * @summary プラクティスエリアグループをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPracticeAreaGroups(options?: any): AxiosPromise<Array<PracticeAreaGroup>> {
            return localVarFp.getAllPracticeAreaGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary プラクティスエリアをすべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPracticeAreas(options?: any): AxiosPromise<Array<PracticeArea>> {
            return localVarFp.getAllPracticeAreas(options).then((request) => request(axios, basePath));
        },
        /**
         * ID で指定されたプラクティスエリアを取得する。
         * @summary プラクティスエリアを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeArea(id: string, options?: any): AxiosPromise<PracticeArea> {
            return localVarFp.getPracticeArea(id, options).then((request) => request(axios, basePath));
        },
        /**
         * プラクティスエリアグループとそれに属するプラクティスエリアを取得する。
         * @summary ID で指定されたプラクティスエリアグループを取得する
         * @param {string} id Group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeAreaGroup(id: string, options?: any): AxiosPromise<PracticeAreaGroup> {
            return localVarFp.getPracticeAreaGroup(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
         * @summary プラクティスエリアの各種リソースのスニペットを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeAreaSnippets(id: string, options?: any): AxiosPromise<GetPracticeAreaSnippetsResponse> {
            return localVarFp.getPracticeAreaSnippets(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PracticeAreasApi - interface
 * @export
 * @interface PracticeAreasApi
 */
export interface PracticeAreasApiInterface {
    /**
     * プラクティスエリアグループとそれぞれに属するプラクティスエリアをすべて取得する。
     * @summary プラクティスエリアグループをすべて取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeAreasApiInterface
     */
    getAllPracticeAreaGroups(options?: AxiosRequestConfig): AxiosPromise<Array<PracticeAreaGroup>>;

    /**
     * 
     * @summary プラクティスエリアをすべて取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeAreasApiInterface
     */
    getAllPracticeAreas(options?: AxiosRequestConfig): AxiosPromise<Array<PracticeArea>>;

    /**
     * ID で指定されたプラクティスエリアを取得する。
     * @summary プラクティスエリアを取得する。
     * @param {string} id プラクティスエリア ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeAreasApiInterface
     */
    getPracticeArea(id: string, options?: AxiosRequestConfig): AxiosPromise<PracticeArea>;

    /**
     * プラクティスエリアグループとそれに属するプラクティスエリアを取得する。
     * @summary ID で指定されたプラクティスエリアグループを取得する
     * @param {string} id Group ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeAreasApiInterface
     */
    getPracticeAreaGroup(id: string, options?: AxiosRequestConfig): AxiosPromise<PracticeAreaGroup>;

    /**
     * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
     * @summary プラクティスエリアの各種リソースのスニペットを取得する。
     * @param {string} id プラクティスエリア ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeAreasApiInterface
     */
    getPracticeAreaSnippets(id: string, options?: AxiosRequestConfig): AxiosPromise<GetPracticeAreaSnippetsResponse>;

}

/**
 * PracticeAreasApi - object-oriented interface
 * @export
 * @class PracticeAreasApi
 * @extends {BaseAPI}
 */
export class PracticeAreasApi extends BaseAPI implements PracticeAreasApiInterface {
    /**
     * プラクティスエリアグループとそれぞれに属するプラクティスエリアをすべて取得する。
     * @summary プラクティスエリアグループをすべて取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeAreasApi
     */
    public getAllPracticeAreaGroups(options?: AxiosRequestConfig) {
        return PracticeAreasApiFp(this.configuration).getAllPracticeAreaGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary プラクティスエリアをすべて取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeAreasApi
     */
    public getAllPracticeAreas(options?: AxiosRequestConfig) {
        return PracticeAreasApiFp(this.configuration).getAllPracticeAreas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ID で指定されたプラクティスエリアを取得する。
     * @summary プラクティスエリアを取得する。
     * @param {string} id プラクティスエリア ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeAreasApi
     */
    public getPracticeArea(id: string, options?: AxiosRequestConfig) {
        return PracticeAreasApiFp(this.configuration).getPracticeArea(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プラクティスエリアグループとそれに属するプラクティスエリアを取得する。
     * @summary ID で指定されたプラクティスエリアグループを取得する
     * @param {string} id Group ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeAreasApi
     */
    public getPracticeAreaGroup(id: string, options?: AxiosRequestConfig) {
        return PracticeAreasApiFp(this.configuration).getPracticeAreaGroup(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
     * @summary プラクティスエリアの各種リソースのスニペットを取得する。
     * @param {string} id プラクティスエリア ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PracticeAreasApi
     */
    public getPracticeAreaSnippets(id: string, options?: AxiosRequestConfig) {
        return PracticeAreasApiFp(this.configuration).getPracticeAreaSnippets(id, options).then((request) => request(this.axios, this.basePath));
    }
}
