import { makeSearchRouteQueryPartial } from '@/utils/routeUtils';
import { searchQueryValueLabelMap, SearchQuery, PartialSearchQuery } from '@/types/SearchQuery';
import { DocumentTypeEnum, Toc } from 'wklr-backend-sdk/models';
import { StaticData } from '@/utils/searchUtils';
import * as Constants from '@/constants';

/**
 * Treat empty array as 'all' by default
 * @param query
 * @param candidates
 */
export function fillWithDefaults<T>(query: T[] | undefined, candidates: T[]) {
  if (query == null || query.length === 0) {
    return [...candidates];
  }

  return query;
}

/**
 * Dateを「昭和48」「令和元」のような文字列に整形
 * @param date
 * @param era
 */
export function dateToWarekiYear(date: Date, era: 'short' | 'narrow' = 'short') {
  // FIXME: no IE support
  const dateString = date.toLocaleDateString('ja-JP-u-ca-japanese', { era, year: 'numeric' });
  const numericMatch = dateString.match(/(\D+)(\d+)年/);

  if (numericMatch == null) {
    const firstYearMatch = dateString.match(/(\D+)元年$/);
    const [_, e] = firstYearMatch ? firstYearMatch : [];
    return `（${e}元）`;
  }

  const [_, e, y] = numericMatch;
  return '（' + e + y + '）';
}

/**
 * DateあるいはDateに変換できる文字列を「1992（平成4）年9月28日」のような文字列に整形
 * @param date
 */
export function formatYmd(date: Date | string | null) {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const y = date.getFullYear(),
    m = date.getMonth() + 1,
    d = date.getDate(),
    w = dateToWarekiYear(date);

  return `${y}${w}年${m}月${d}日`;
}

export function formatYmdHms(date: Date | string | null) {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const h = String(date.getHours()).padStart(2, '0'),
    m = String(date.getMinutes()).padStart(2, '0'),
    s = String(date.getSeconds()).padStart(2, '0'),
    ymd = formatYmd(date);

  return `${ymd}\xA0${h}:${m}:${s}`;
}

/** 配列中の最頻値を求める */
export function mode<T = string | number>(array: T[]): T | null {
  // https://stackoverflow.com/a/1053876 よりNumberを文字列に変えられては困るのでMapに変更
  const counter = new Map<T, number>();
  let max = null;
  let maxCount = 0;

  for (const k of array) {
    if (counter.has(k)) {
      counter.set(k, counter.get(k)! + 1);
    } else {
      counter.set(k, 1);
    }
    if (maxCount < counter.get(k)!) {
      max = k;
      maxCount = counter.get(k)!;
    }
  }

  return max;
}

/** elementが完全にin-viewかどうかを求める */
export function isInView(element: HTMLElement) {
  const { x, y, width, height } = element.getBoundingClientRect();
  return (
    0 <= x &&
    x + width <= document.documentElement.clientWidth &&
    0 <= y &&
    y + height <= document.documentElement.clientHeight
  );
}

/**
 * 文字列を正規表現-safeに変換
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
 */
export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

/**
 * TOCだけから判断して書籍上のページに対応しうる最初のセクションを求める
 * @param toc TOCデータ
 * @param seq 書籍上のページ (0-index)
 */
export function getKeyFromSeq(toc: Toc | null, seq: number): number | undefined {
  if (!toc || !toc.byKey) {
    return;
  }

  // 指定された seq よりも小さくて最大の pageSeq を持つ TocNode の key をストアする
  let isPageUnique = true;
  let candidate = 0;
  for (const { pageSeq, key } of toc.byKey) {
    if (pageSeq !== toc.byKey[0].pageSeq) {
      isPageUnique = false;
    }
    if (pageSeq <= seq) {
      candidate = key;
    } else {
      break;
    }
  }

  if (isPageUnique) {
    // ページ番号が全て一緒の場合は０を返す。法令などpdfから生成されていないデータが該当する
    return 0;
  } else {
    return candidate;
  }
}

/** 2つの配列が同じか */
export function arraysEqual<T>(A: Array<T>, B: Array<T>) {
  return A.length === B.length && A.every((a, i) => B[i] === a);
}

/** クリックしたときのエフェクトを表示する */
export function invokeRippleEffect(element: HTMLElement) {
  const { top, left, height, width } = element.getBoundingClientRect();
  element.dispatchEvent(new MouseEvent('mousedown', { clientX: left + width / 2, clientY: top + height / 2 }));
  setTimeout(() => element.dispatchEvent(new MouseEvent('mouseup')), 50);
}

/**
 * 検索クエリを日本語の文字列にして返す
 * @param query 検索クエリ
 */

export function formatQuery(query: PartialSearchQuery) {
  const itemTranslations: { [key: string]: string } = {
    keyword: '検索キーワード',
    title: 'タイトル',
    author: '著者・編者等',
    type: '資料種別',
    publisher: '発行元',
    publishedOn: '発行年月日',
  };

  let result = '';
  for (const item in itemTranslations) {
    if (query[<keyof SearchQuery>item] === undefined) continue;

    let value = '';

    if (item === 'publishedOn' && query.publishedOn !== undefined) {
      value = [
        query.publishedOn.gte ? formatYmd(query.publishedOn.gte) : '',
        query.publishedOn.lte ? formatYmd(query.publishedOn.lte) : '',
      ].join('～');
    } else if (item === 'publisher' && query.publisher !== undefined) {
      value = query.publisher.join('・');
    } else if (item === 'type' && query.type !== undefined) {
      value = query.type.map((t) => searchQueryValueLabelMap.display[t]).join('・');
    } else {
      value = <string>query[<keyof SearchQuery>item];
    }
    result += itemTranslations[item] + ' : ' + value + '、';
  }
  return result.slice(0, -1);
}
/**
 * Legalscapeの閲覧画面ではなく直リンクで閲覧させる種類を判定する
 * @param type
 */
export function isExternalViewer(type: DocumentTypeEnum): boolean {
  return type === DocumentTypeEnum.Pdf;
}

/**
 * ドキュメント情報とスニペット情報から一意のキーを返す
 * @param string DocRecord.id
 * @param number SearchHits.pageSeq
 */
export function snippetKey(docId: string, pageSeq: number) {
  return `${docId}-${pageSeq}`;
}

/**
 * 取得するたびにカウントアップする id を生成する
 */
export class IncrementalIdGenerator {
  private _uid = 0;

  get newId(): number {
    const uniqueId = this._uid;
    this._uid++;
    return uniqueId;
  }
}

/**
 * now Date() できる文字列を取得して `YYYY/MM/DD` を返却する
 * @param dateString: string
 */
const degit = (digit: number): string => {
  return `0${digit}`.slice(-2);
};

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  return `${date.getFullYear()}/${degit(date.getMonth())}/${degit(date.getDate())}`;
}

/**
 * すべての発行元が選択されているかどうかをチェックする
 * @param PartialSearchQuery
 */
export const isAllPublishersChecked = ({ publisher }: PartialSearchQuery): boolean => {
  if (!publisher) return false;
  return publisher.length === StaticData.publishers.book.length + StaticData.publishers.web.length;
};

/**
 * すべての資料種別が選択されているかどうかチェックする
 * @param PartialSearchQuery
 */
export const isAllTypesChecked = ({ type }: PartialSearchQuery): boolean => {
  if (!type) return false;
  return type.length === StaticData.types.values.length;
};

/**
 * 検索クエリの中身が空かどうかをチェックする
 * @param PartialSearchQuery
 */
export const isSearchQueryEmpty = (q: PartialSearchQuery): boolean => {
  const { type, maxGaps, includeOlderEditions, ...rest } = q;
  return (
    Object.entries(makeSearchRouteQueryPartial(rest)).length === 0 &&
    maxGaps === Constants.Search.DefaultMaxGaps &&
    !!includeOlderEditions
  );
};

/**
 * 検索クエリを受け取って更新があるかどうかを確認する。一つ目の引数として PartialSearchQuery を受け取れる
 * @param q1
 * @param q2
 * @returns
 */
export const isEqualSearchQuery = (q1: PartialSearchQuery, q2: SearchQuery): boolean => {
  const isEqualArray = <T>(arr1: T[] | undefined, arr2: T[]): boolean => {
    if (arr1 === undefined) {
      return arr2.length === 0;
    } else {
      return arr1.length === arr2.length && arr1.every((v, index) => v === arr2[index]);
    }
  };

  if (!(q1.keyword === q2.keyword || (q1.keyword === undefined && q2.keyword === ''))) return false;
  if (!(q1.title === q2.title || (q1.title === undefined && q2.title === ''))) return false;
  if (!(q1.author === q2.author || (q1.author === undefined && q2.author === ''))) return false;
  if (!isEqualArray(q1.publisher, q2.publisher === undefined ? [] : q2.publisher)) return false;
  if (q1.publishedOn === undefined) {
    if (q2.publishedOn.gte !== '' || q2.publishedOn.lte !== '') return false;
  } else {
    if (
      !(q1.publishedOn.gte === q2.publishedOn.gte || (q1.publishedOn.gte === undefined && q2.publishedOn.gte === ''))
    ) {
      return false;
    }
    if (
      !(q1.publishedOn.lte === q2.publishedOn.lte || (q1.publishedOn.lte === undefined && q2.publishedOn.lte === ''))
    ) {
      return false;
    }
  }
  if (!!q1.includeOlderEditions !== !!q2.includeOlderEditions) return false;
  if (q1.maxGaps !== q2.maxGaps) return false;
  // type は今後使われなくなる（どれかで検索する形になる）はずなのでチェック対象から外す
  return true;
};

/**
 * 画面を最上部までスクロールさせる
 */
export const scrollToTop = (): void => {
  // スクロール位置調整
  document.body.scrollTop = 0;
  const results = document.querySelector('.main-container');
  if (results) {
    results.scrollTop = 0;
  }
};

export function removeTags(str: string): string {
  return str.replace(/(<([^>]+)>)/gi, '');
}

/**
 * await を使うために promise を返す scrollTo
 * prototype 拡張はしたくないので、コンテナも外から渡すようにした
 * @param container
 * @param target
 * @param option
 * @returns
 */
export const asyncScrollTo = async (
  container: Element,
  target: HTMLElement | number,
  option?: ScrollToOptions,
): Promise<void> =>
  new Promise((resolve, reject) => {
    const fixedOffset = target instanceof HTMLElement ? target.offsetTop.toFixed() : target.toFixed();
    if (fixedOffset === container.scrollTop.toFixed()) {
      // スクロールが必要ない場合はすぐに resolve する
      resolve();
    } else {
      // 5秒経ってもスクロールが完了しない場合 reject する
      const timer = setTimeout(() => reject(), 5000);
      const onScroll = () => {
        if (container.scrollTop.toFixed() === fixedOffset) {
          container.removeEventListener('scroll', onScroll);
          clearTimeout(timer);
          resolve();
        }
      };

      container.addEventListener('scroll', onScroll);
      container.scrollTo({
        ...option,
        top: Number(fixedOffset),
      });
    }
  });

export async function downloadByURL(url: string): Promise<void> {
  const link = document.createElement('a');
  link.href = url;
  link.click();
}

/** ErrorオブジェクトなどのJSON serializableなcloneを作る */
export function getSerializable<T>(obj: T): Record<string, T[keyof T]> {
  return Object.fromEntries<T[keyof T]>(
    (Object.getOwnPropertyNames(obj) as (keyof T)[]).map((name) => [name, obj[name]]),
  );
}

/**
「ソート済みで重複要素を持たない」という条件を満たす2個の配列をマージして「ソート済みで重複要素を持たない」配列を返します。
第三引数にSet<number>を渡すと、それらの値は返り値の配列から除外されます。
*/
export function mergeSortedArraysToUniqueArray(
  a1: number[],
  a2: number[],
  excluded: Set<number> = new Set(),
): number[] {
  const result = [] as number[];
  let i1 = 0,
    i2 = 0;
  while (i1 < a1.length || i2 < a2.length) {
    const next1 = a1[i1],
      next2 = a2[i2];
    let next: number;
    if (next1 === undefined) {
      next = next2;
      i2++;
    } else if (next2 === undefined) {
      next = next1;
      i1++;
    } else if (next1 === next2) {
      next = next1;
      i1++;
      i2++;
    } else if (next1 < next2) {
      next = next1;
      i1++;
    } else {
      next = next2;
      i2++;
    }

    if (!excluded.has(next)) {
      result.push(next);
    }
  }

  return result;
}
