import { AnnounceKit } from '@/constants';
import { NuxtAxiosInstance } from '@nuxtjs/axios';

const GRAPHQL_URL = 'https://announcekit.app/gq/v2';
export type AnnounceKitPostsResponse = {
  data: {
    posts: {
      list: {
        contents: {
          title: string;
          url: string;
        }[];
        id: string;
        is_draft: boolean;
        updated_at: string;
      }[];
    };
  };
};

export class AnnounceKitApi {
  constructor(private axios: NuxtAxiosInstance) {}

  async getAnnouncementPosts(): Promise<AnnounceKitPostsResponse['data'] | null> {
    if (!AnnounceKit.Enabled) return null;
    const headers = { Authorization: `Basic ${AnnounceKit.BasicAuthToken}` };
    const query = `
  query {
    posts(project_id: ${AnnounceKit.ProjectId}, labels: [${AnnounceKit.LabelId}]) {
      list {
        id
        updated_at
        is_draft
        contents {
          title
          url
        }
      }
    }
  }
    `;
    const feed = await this.axios.$post<AnnounceKitPostsResponse>(GRAPHQL_URL, { query }, { headers });
    return { posts: { list: feed.data.posts.list.filter((item) => !item.is_draft) } };
  }
}
