import type {
  ModifyBinderFolderParamsBody,
  NewBinderFolderParamsBody,
  NewWebBookmarkParamsBody,
  ModifyHighlightParamsBody,
  NewPdfBookmarkParamsBody,
  NewBinderParamsBody,
  ModifyBinderParamsBody,
} from '@gen/wklr-backend-api/v1/model';
import { codePointCount } from './stringUtils';

const FOLDER_NAME_MAX_LENGTH = 64;
const FOLDER_NAME_TOO_SHORT_ERROR_MESSAGE = 'フォルダーの名前は空にはできません';
const FOLDER_NAME_TOO_LONG_ERROR_MESSAGE = 'フォルダーの名前に指定できるのは64文字までです';

const BINDER_NAME_MAX_LENGTH = 256;
const BINDER_NAME_TOO_SHORT_ERROR_MESSAGE = 'バインダーの名前は空にはできません';
const BINDER_NAME_TOO_LONG_ERROR_MESSAGE = 'バインダーの名前に指定できるのは256文字までです';

// このメモ (description) の制約は、バインダー、ブックマーク、ハイライトで共通である
const DESCRIPTION_MAX_LENGTH = 1024;
const DESCRIPTION_LENGTH_ERROR_MESSAGE = 'メモに指定できるのは1024文字までです';

/**
 * folder 関連の API のリクエストの内容をバリデーションして問題なければデータを返す
 * @throws
 */
export const guardFolderRequestData = <T extends ModifyBinderFolderParamsBody | NewBinderFolderParamsBody>(
  data: T,
): T => {
  if (data.name.length === 0) throw new Error(FOLDER_NAME_TOO_SHORT_ERROR_MESSAGE);
  if (codePointCount(data.name) > FOLDER_NAME_MAX_LENGTH) throw new Error(FOLDER_NAME_TOO_LONG_ERROR_MESSAGE);
  return data;
};

/**
 * binder 関連の API のリクエストの内容をバリデーションして問題なければデータを返す
 * @throws
 */
export const guardBinderRequestData = <T extends NewBinderParamsBody | ModifyBinderParamsBody>(data: T): T => {
  if (data.name.length === 0) throw new Error(BINDER_NAME_TOO_SHORT_ERROR_MESSAGE);
  if (codePointCount(data.name) > BINDER_NAME_MAX_LENGTH) throw new Error(BINDER_NAME_TOO_LONG_ERROR_MESSAGE);
  if (data.description && codePointCount(data.description) > DESCRIPTION_MAX_LENGTH)
    throw new Error(DESCRIPTION_LENGTH_ERROR_MESSAGE);
  return data;
};

/**
 * binder item = ハイライト・ブックマーク関連の API のリクエストの内容をバリデーションして問題なければデータを返す
 * @throws
 */
export const guardBinderItemRequestData = <
  T extends NewWebBookmarkParamsBody | ModifyHighlightParamsBody | NewPdfBookmarkParamsBody,
>(
  data: T,
): T => {
  if (codePointCount(data.description) > DESCRIPTION_MAX_LENGTH) throw new Error(DESCRIPTION_LENGTH_ERROR_MESSAGE);
  return data;
};
