/* eslint-disable @typescript-eslint/no-namespace */

import { OrderCondition, SearchOption } from '@/types/SearchQuery';
import { SearchResultOrderByEnum, SearchResultOrderEnum } from 'wklr-backend-sdk/models';

import supportMailTemplateISO2022JpQuotedPrintable from '@/assets/supportMailTemplateISO2022JpQuotedPrintable.txt';
import supportMailTemplateUTF8 from '@/assets/supportMailTemplateUTF8.txt';

export namespace Portal {
  /** ポータルの各タブの一ページ当たりの表示文書数 */
  export const PerPage = 10;
}

export type Order = { text: string; desc?: string; value: OrderCondition };
export namespace Search {
  /** ドロップダウンに隠されている詳細検索オプション */
  export const AdditionalOptions = ['資料種別・発行元', '発行年月日'];

  /** 検索結果画面一ページに表示する文書数の選択肢 */
  export const PerPageOptions: { text: string; value: number }[] = [
    {
      text: '20件表示',
      value: 20,
    },
    {
      text: '50件表示',
      value: 50,
    },
    {
      text: '100件表示',
      value: 100,
    },
  ];

  /** 検索結果画面一ページ当たりの表示文書数のデフォルト値 */
  export const PerPage = PerPageOptions[0].value;

  /** ソート順の選択肢 */
  export const Orders: Order[] = [
    {
      text: '関連度・重要度順',
      value: { id: 0 },
    },
    {
      text: '発行年月日が新しい順',
      value: { id: 1, orderBy: SearchResultOrderByEnum.PublishedOn, order: SearchResultOrderEnum.Desc },
    },
    {
      text: '発行年月日が古い順',
      value: { id: 2, orderBy: SearchResultOrderByEnum.PublishedOn, order: SearchResultOrderEnum.Asc },
    },
    {
      text: 'タイトル順',
      desc: '文字コード順での並び替え',
      value: { id: 3, orderBy: SearchResultOrderByEnum.Title, order: SearchResultOrderEnum.Asc },
    },
    {
      text: '著者名順',
      desc: '筆頭著者名の文字コード順での並び替え',
      value: { id: 4, orderBy: SearchResultOrderByEnum.Authors, order: SearchResultOrderEnum.Asc },
    },
    {
      text: '発行元名順',
      desc: '文字コード順での並び替え',
      value: { id: 5, orderBy: SearchResultOrderByEnum.Publisher, order: SearchResultOrderEnum.Asc },
    },
  ];

  /** 検索結果画面並び順のデフォルト値 */
  export const OrderId = Orders[0].value.id;

  /** 検索結果画面検索オプションのデフォルト値 */
  export const option: SearchOption = {
    o: OrderId,
    n: PerPage,
  };

  /** HEADしてみて、Content-sizeがこれより大きければプリフェッチしない */
  export const PrefetchMaxSize = 20000000;

  /** maxGaps のデフォルト値 */
  export const DefaultMaxGaps = 500;
  /** maxGaps を設定しない場合の値(Elasticsearchの仕様) */
  export const NoMaxGaps = -1;
}

export namespace Document {
  export namespace WebView {
    /**
     * 一回に取ってくる文章の量 (series単位)
     * ※ただし、サーバ側でsectionの数に上限がある
     */
    export const Margin = 20;

    /** マウスホバーからLegalscapeに収録されていますポップアップを出すまでの時間 (ms) */
    export const AvailabilityPopupWait = 500;
  }

  export namespace PdfViewer {
    const viewerPath = process.env.DEV_PDF_JS ? '/dev-pdfjs/web/viewer.html' : '/vendor/pdfjs/viewer.html';
    export const viewerUrl = viewerPath + `?pjver=${process.env.pdfViewerVersion}`;
  }

  export namespace Toc {
    /** コンメンタールで条に対応するTOC項目のdepth */
    export const KommentarArticleDepth = 5;

    export namespace Digest {
      /** 論理深さがこの値以下のTOC項目をダイジェストに含める対象とする */
      export const MaxLogicalDepth = 4;

      /** ダイジェストに含めるTOC項目の個数 */
      export const ItemCount = 4;
    }
  }
}

/**
 * 文字列操作などに使うユニコード値について、重複しないようにここでまとめて管理する
 */

// タグなど前後で囲む要素で利用する
export const REPLACER_TAG_END_CHAR_CODE = 0xe000;
export const REPLACER_TAG_START_CHAR_CODE = 0xe001;
export const REPLACER_TAG_END_UNICODE = '\ue000';
export const REPLACER_TAG_START_UNICODE_RANGE = '\ue001-\ue0ff';
export const REPLACER_TAG_UNICODE_ALL_RANGE = '\ue000-\ue0ff';

// &amp; と & の相互変換に利用する
export const REPLACER_AMPERSAND_CHAR_CODE = 0xf0ff;

// &lt; と < の相互変換に利用する
export const REPLACER_LESS_THAN_CHAR_CODE = 0xf0fe;

// &gt; と > の相互変換に利用する
export const REPLACER_GREATER_THAN_CHAR_CODE = 0xf0fd;

export namespace Feedback {
  export const GoogleFormsURLPattern =
    'https://docs.google.com/forms/d/e/1FAIpQLSddAM_0hkUUmOI-xVW4mzlDxxZq-Bs4n6gd4RfZQqL91l0tpg/viewform?usp=pp_url&entry.1768349130={1}&entry.2073493052={2}&entry.394202401={id}';
  export const MypageTrialRegistrationSSUrl =
    'https://script.google.com/macros/s/AKfycbxjim93-OJfIfb6MBlKMJflRdNCRQOnJp9rEawQRzA4JLlhG3a3imugBvkYR-OltT7G/exec';

  export const RatingAppScriptURL =
    'https://script.google.com/macros/s/AKfycbwseWNqCAtaSgxS21CPMtu1Tt2lmPnB7rO8fDZqOCltuPRLLPEmoOPlgBpQTVPvF5V2/exec';

  export const RatingItems = ['5 - 満足している', '4 - やや満足', '3 - 普通', '2 - やや不満', '1 - 不満'] as const;
}

export namespace CorrectionReport {
  // TODO: 正しいものに差し替える
  export const GoogleFormsURLPattern =
    'https://docs.google.com/forms/d/e/1FAIpQLScts0BhWEqXhb8_v8DAz8-vx0aVGaEXRROrdF_MtXXBGS6TaA/viewform?usp=pp_url&entry.743623916={TITLE}&entry.671598635={ID}&entry.1546556233={SECTION}&entry.491288613={SELECTION}&entry.912959597={USER_ID}';
}

/** CSS などで指定される基本的なトランジションの長さ */
export const DEFAULT_TRANSITION_DURATION_MS = 100;

export namespace AboutLegalscape {
  export const PRIVACY_POLICY = 'https://www.legalscape.jp/privacy-policy';
  export const TERMS_OF_SERVICE_GENERAL = 'https://www.legalscape.jp/terms';
  export const TERMS_OF_SERVICE_SPECIAL_PACK =
    'https://drive.google.com/a/legalscape.co.jp/file/d/10NLRrZYlteB5K2x6Q1q458ZvsLqca6uI/view?usp=sharing';
  export const TERMS_OF_SERVICE_VIA_LEGALON =
    'https://drive.google.com/file/d/1rYvn3cCJdYXBAcSKLIylXg6b-WmczSZu/view?usp=sharing';

  /** MHM Mypage機能制限版向け製品版紹介ページ */
  export const FULLVER_INTRODUCTION_FOR_MHM_MYPAGE =
    'https://legalscape.notion.site/Legalscape-341451e8a4dd4328b4fd00a594566e52';
}

export namespace SESSION_STORAGE_KEY {
  export const FOLDER_EXPROLER_TREE_STACK = 'folder-exproler-tree-stack';
}

export namespace CacheMaxAge {
  export const SHORT = 60 * 1000;
  export const DEFAULT = 3 * 60 * 1000;
}

export namespace AnnounceKit {
  export const WidgetToken = process.env.announceKitWidgetToken;
  export const Enabled = process.env.announceKitEnabled === 'true';
  // GraphQL API を叩くために必要な auth 情報。ログイン情報を https://www.blitter.se/utils/basic-authentication-header-generator/ で加工する
  export const BasicAuthToken = process.env.announceKitBasicAuthToken;
  // 対象のプロジェクト ID
  export const ProjectId = process.env.announceKitProjectId;
  // 表示する記事のラベル ID
  export const LabelId = process.env.announceKitLabelId;
}

export namespace WebViewer {
  export const TOOLBAR_HEIGHT = 32;
  export const KOMMENTAR_ARTICLE_BAR_HEIGHT = 210;
  export const KOMMENTAR_ARTICLE_BAR_LINE_HEIGHT = 1.5;
  export const KOMMENTAR_ARTICLE_BAR_PADDING = 12;
  export const GAP = 10;
}

/**
 * サポートメールの文言
 * オリジナル以外の符号化されたファイルは以下の要領で生成してください
 * FIXME: 動作確認が取れないため手動で作成された初期の文字列が入っています。更新時には動作確認してください ref: https://github.com/legalscape/wklr/pull/765
 *
 * nkf -jW -MQ frontend/assets/supportMailTemplateUTF8.txt | tr = % | sed -z 's/\%\r\n//g' | sed -z 's/\%\n//g' | sed -z 's/\r\n/%0D%0A/g' | sed -z 's/\n/%0D%0A/g' | cat > frontend/assets/supportMailTemplateISO2022JpQuotedPrintable.txt
 *
 * 1. nkf で文字コードを変換しつつ quoted-printable に変換する
 * 2. 符号文字を = から % に置き換える
 * 3. quoted-printable の改行コードを除外する（ % と改行コードの組み合わせは quoted-printable においては無視される）
 * 4. 改行コードを %0D%0A に置き換える
 *
 * mac os の場合は gnu-sed をインストールして利用してください
 */
export const supportMailTemplate = {
  original: supportMailTemplateUTF8,
  // オリジナルを email の body に入れられるように encodeURIComponent で符号化したもの
  UTF8: encodeURIComponent(supportMailTemplateUTF8),
  // UTF8 ユーザーに主に使われている Outlook for Microsoft 365 で文字化けが発生するために、 ISO-2022-jp の文字コードのものを Quoted-Printable 形式で符号化したもの
  ISO2022Jp: supportMailTemplateISO2022JpQuotedPrintable,
};

export const bookListURL =
  'https://docs.google.com/spreadsheets/d/1T2NASimvfXnvEkm1JK6bozIq3w9Y_YEQO_mM5c3Nzlc/edit?gid=713644664#gid=713644664';
