import type { AccountsApi } from '@gen/wklr-backend-api/v1/api/accounts-api';
import type { GetAccountResponse } from '@gen/wklr-backend-api/v1/model';
import { CacheTTL } from '../constants';

export class AccountsRepository {
  constructor(private api: AccountsApi) {}

  async getAccount(): Promise<GetAccountResponse> {
    const res = await this.api.getAccount({
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return res.data;
  }
}
