import { Component, State, Vue } from 'nuxt-property-decorator';
import { State as MyState } from '@/store';
import { Nullable } from '@/types/nullable';
import { BookSnippet, DocRecord, DocumentTypeEnum } from 'wklr-backend-sdk/models';

interface CopyParams {
  text: string;
  successMessage: string;
  errorMessage: string;
}

@Component
export default class ShareUrlDialog extends Vue {
  @State((state: MyState) => state.global.shareUrlDialogDocument) shareUrlDialogDocument!: Nullable<
    DocRecord | BookSnippet
  >;

  get dialog(): boolean {
    return this.shareUrlDialogDocument !== null;
  }
  set dialog(val: boolean) {
    if (val) {
      throw new Error("you shouldn't set model directly");
    } else {
      this.$store.commit('clearShareUrlDialogDocument');
    }
  }

  get documentUrl(): string {
    if (this.shareUrlDialogDocument === null) return '';
    return `${location.origin}/document/${this.shareUrlDialogDocument.id}`;
  }

  get documentMetadata(): string {
    if (this.shareUrlDialogDocument) {
      const quotes =
        'type' in this.shareUrlDialogDocument && this.shareUrlDialogDocument.type === DocumentTypeEnum.Book
          ? '『』'
          : '「」';

      const authors = (this.shareUrlDialogDocument.authors || []).join(',');
      const year = new Date(this.shareUrlDialogDocument.publishedOn || 0).getFullYear();
      return `${authors}${quotes[0]}${this.shareUrlDialogDocument.title}${quotes[1]}（${this.shareUrlDialogDocument.publisher}、${year}） ${this.documentUrl}`;
    } else {
      return '書誌情報を取得できませんでした。';
    }
  }

  async copyDocumentInfo({ text, successMessage, errorMessage }: CopyParams): Promise<void> {
    try {
      await navigator.clipboard.writeText(text);
      this.$toast.success(successMessage);
    } catch (e) {
      this.$toast.error(errorMessage);
    } finally {
      this.dialog = false;
    }
  }

  async copyDocumentMetadata(): Promise<void> {
    await this.copyDocumentInfo({
      text: this.documentMetadata,
      successMessage: '書誌情報をコピーしました',
      errorMessage: '書誌情報をコピーできませんでした',
    });
  }

  async copyDocumentUrl(): Promise<void> {
    await this.copyDocumentInfo({
      text: this.documentUrl,
      successMessage: 'リンクをコピーしました',
      errorMessage: 'リンクをコピーできませんでした。アドレスバーからコピーしてください',
    });
  }
}
