/* eslint-disable @typescript-eslint/no-namespace */

import type { OrderCondition } from './types/SearchQuery';

export type Order = { text: string; desc?: string; value: OrderCondition };

export namespace Document {
  export namespace WebView {
    /**
     * 一回に取ってくる文章の量 (series単位)
     * ※ただし、サーバ側でsectionの数に上限がある
     */
    export const Margin = 20;

    /** マウスホバーからLegalscapeに収録されていますポップアップを出すまでの時間 (ms) */
    export const AvailabilityPopupWait = 500;
  }
}

export namespace CacheTTL {
  export const SHORT = 60 * 1000;
  export const DEFAULT = 3 * 60 * 1000;
}
