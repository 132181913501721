import type { AxiosInstance, AxiosRequestConfig } from 'axios';

export function applyCustomHeadersInterceptors(axios: AxiosInstance) {
  axios.interceptors.request.use(function (config: AxiosRequestConfig) {
    config.headers['X-Requested-With'] = 'XMLHttpRequest';

    // tree shakingによりdevelopment以外では削除される
    if (process.env.NODE_ENV === 'development' && sessionStorage) {
      // intentionalErrorはDeveloper Toolsを使用して設定すること
      const intentionalError = sessionStorage.getItem('intentionalError');
      if (intentionalError) {
        config.headers['X-Intentional-Error'] = intentionalError;
      }
    }

    return config;
  });
}
