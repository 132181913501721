import type { DevicesApi } from '@gen/wklr-backend-api/v1/api';
import type { UpdateDevicesParamsBody, Device } from '@gen/wklr-backend-api/v1/model';

export class DevicesRepository {
  constructor(private api: DevicesApi) {}

  async verifyDevice(): Promise<void> {
    await this.api.verifyDevice();
    return;
  }

  async updateDevices(name: string): Promise<Device> {
    const requestData: UpdateDevicesParamsBody = {
      name,
    };
    const { data } = await this.api.updateDevices(requestData);
    return data.device;
  }

  async deleteSessionDevice(): Promise<void> {
    await this.api.deleteSessionDevice();
    return;
  }
}
