import { Plugin } from '@nuxt/types';
import createPersistedState from 'vuex-persistedstate';

export default <Plugin>(({ store }) => {
  (<any>window).onNuxtReady(() => {
    createPersistedState({
      key: 'vuex',
      paths: ['persistent'],
    })(store);
  });
});
