/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const LSPermissionNameEnum = {
    PracticeArea: 'practiceArea',
    Print: 'print',
    Binder: 'binder',
    Legalweb: 'legalweb',
    LabsQa: 'labsQA',
    LabsQad1: 'labsQAD1',
    LabsQaWatson: 'labsQAWatson'
} as const;

export type LSPermissionNameEnum = typeof LSPermissionNameEnum[keyof typeof LSPermissionNameEnum];



