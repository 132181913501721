import { CacheTTL } from '../constants';
import type { AttachmentsApi } from '@gen/wklr-backend-api/v1/api/attachments-api';
import type { Attachment } from '@gen/wklr-backend-api/v1/model';
export class AttachmentsRepository {
  constructor(private api: AttachmentsApi) {}

  async getAttachment(docId: string, attachmentId: string, downloadAs?: string): Promise<Attachment> {
    const resp = await this.api.getAttachment(docId, attachmentId, downloadAs, {
      cache: { ttl: CacheTTL.SHORT },
    });
    return resp.data;
  }
}
