import { PluginExtension } from '@/types/pluginExtension';
import { AboutLegalscape } from '@/constants';

// TODO: oidで分岐するようにしたい
export function getManualURL({ $domain, $auth }: PluginExtension): string | null {
  // kenpon はマニュアル無し
  if ($domain.isKenpon) {
    return null;
  }

  // Liteはマニュアル無し
  if ($domain.isLite) {
    return null;
  }

  if ($domain.isMHMMypage) {
    return AboutLegalscape.FULLVER_INTRODUCTION_FOR_MHM_MYPAGE;
  }

  // MHM向けマニュアル、NO&Tマニュアル、企業(practiceAreaが使用できるorganization)向けマニュアル、法律事務所向けマニュアル、その他
  return 'https://faq.legalscape.jp';
}
