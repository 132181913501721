import { Context } from '@nuxt/types';

/* eslint-disable @typescript-eslint/no-explicit-any */
declare let fs: any;

const fullstarId = process.env.FULLSTAR_ID;

export default async function ({ app }: Context) {
  try {
    if (!fs) return;
    if (!fullstarId) return;
    const user = await app.$repositories.users.getMe();
    const tags = [];
    if (user.organization.organizationType) {
      tags.push(`organization-type-${user.organization.organizationType.id}`);
    }
    user.organization.permissions.forEach((permission) => {
      tags.push(`permission-${permission.toString()}`);
    });
    fs.clear();
    fs.show(fullstarId, user.id, {
      company_name: user.organization.name,
      user_name: user.profile.name,
      user_email: user.email,
      plan_name: user.organization.pricingType,
      tags: tags.join(','),
    });
    fs.tracking();
    fs.survey();
    fs.guide();
    fs.checklist();
    fs.tooltip();
  } catch {
    //失敗を無視する
  }
}
