import { CacheTTL } from '../constants';
import type { KommentarApi } from '@gen/wklr-backend-api/v1/api/kommentar-api';
import type {
  KommentarArticle,
  KommentarCitations,
  KommentarSupplementarySections,
} from '@gen/wklr-backend-api/v1/model';

export class KommentarRepository {
  constructor(private api: KommentarApi) {}

  async getKommentarArticles(docId: string): Promise<KommentarArticle[]> {
    const { data } = await this.api.getKommentarArticles(docId, {
      cache: { ttl: CacheTTL.SHORT },
    });
    return data;
  }

  async getKommentarSupplementarySections(docId: string, keys: number[]): Promise<KommentarSupplementarySections> {
    const { data } = await this.api.getKommentarSupplementaries(docId, keys, {
      cache: { ttl: CacheTTL.SHORT },
    });
    return data;
  }

  async getLawKommentars(docId: string, keys: number[]): Promise<KommentarCitations[]> {
    const { data } = await this.api.getLawKommentars(docId, keys, {
      cache: { ttl: CacheTTL.SHORT },
    });
    return data;
  }
}
