import Axios, { AxiosError, Cancel } from 'axios';

// https://qiita.com/SoraKumo/items/1d593796de973095f101
// 本質的にここの `_` prefix は必要ない. しかしこれをつけないと typescript-eslint が no-unused-vars のエラーを出してしまう.
// typescript-eslint のバージョンを v6 系以上にまで上げて仕舞えば治るはず
function hasProperty<K extends string>(x: unknown, ...name: K[]): x is { [_M in K]: unknown } {
  return x instanceof Object && name.every((prop) => prop in x);
}
/**
 * axiosのエラーなのかチェック
 * https://zenn.dev/wintyo/articles/b417e310efc67e
 * @param error - エラーオブジェクト
 * @returns axiosエラーか
 */
export function isAxiosError(error: unknown): error is AxiosError {
  return (
    error instanceof Object &&
    hasProperty(error, 'isAxiosError') &&
    typeof error.isAxiosError === 'boolean' &&
    error.isAxiosError
  );
}

export function isAxiosCancelError(error: unknown): error is Cancel {
  return Axios.isCancel(error);
}
