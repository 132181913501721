import { CacheTTL } from '../constants';
import type {
  BookCitations,
  LawCitations,
  GuidelineCitations,
  PublicCommentCitations,
  CitationCounts,
} from '@gen/wklr-backend-api/v1/model';
import type { CitationsApi } from '@gen/wklr-backend-api/v1/api/citations-api';

export class CitationsRepository {
  constructor(private api: CitationsApi) {}

  async getCitationCount(docNaturalId: string, keys: number[]): Promise<CitationCounts> {
    const resp = await this.api.getCitationsCount(docNaturalId, keys, {
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return resp.data;
  }

  async getAllCitationCount(docNaturalId: string): Promise<{ [key: string]: CitationCounts }> {
    const resp = await this.api.getCitationsCounts(docNaturalId, {
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return resp.data;
  }

  async getCitationBook(docNaturalId: string, keys: number[]): Promise<BookCitations[]> {
    const resp = await this.api.getCitationsInBook(docNaturalId, keys, {
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return resp.data;
  }

  async getCitationLaw(docNaturalId: string, keys: number[]): Promise<LawCitations[]> {
    const resp = await this.api.getCitationsInLaw(docNaturalId, keys, {
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return resp.data;
  }

  async getCitationGuideline(docNaturalId: string, keys: number[]): Promise<GuidelineCitations[]> {
    const resp = await this.api.getCitationsInGuideline(docNaturalId, keys, {
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return resp.data;
  }

  async getCitationPublicComment(docNaturalId: string, keys: number[]): Promise<PublicCommentCitations[]> {
    const resp = await this.api.getCitationsInPublicComment(docNaturalId, keys, {
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return resp.data;
  }
}
