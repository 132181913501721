import {
  DocumentTypeEnum,
  SearchResultOrderByEnum,
  SearchResultOrderEnum,
  RequestSearchQuery,
  RequestSearchQueryPublishedOn,
} from 'wklr-backend-sdk/models';

/**
 * cf. https://stackoverflow.com/a/51365037
 * FIXME: 別ファイルにして import すると利用側でも明示的に import が必要になってしまうので同じ場所に置いている。型定義整理したい
 */
type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends Record<string, unknown>
    ? RecursivePartial<T[P]>
    : T[P];
};

// スキーマ定義では nullable だが search api に `null` を設定してリクエストすると 404 が返ってくるので、 NonNull にする
type PublishedOn = {
  [P in keyof RequestSearchQueryPublishedOn]: NonNullable<RequestSearchQueryPublishedOn[P]>;
};

export type OrderCondition =
  | {
      id: number;
      orderBy: SearchResultOrderByEnum;
      order: SearchResultOrderEnum;
    }
  | { id: number };

export type SearchQueryType = DocumentTypeEnum;

export type SearchQuery = Omit<RequestSearchQuery, 'publishedOn' | 'practiceAreaId'> & {
  publishedOn: PublishedOn;
};

export type SearchQueryParams = Omit<SearchQuery, 'keyword'>;

export interface SearchOption {
  n: number;
  o: number;
}

export type PartialSearchQuery = RecursivePartial<SearchQuery>;

export const searchQueryValueLabelMap: {
  values: SearchQueryType[];
  // 本質的にここの `_` prefix は必要ない. しかしこれをつけないと typescript-eslint が no-unused-vars のエラーを出してしまう.
  // typescript-eslint のバージョンを v6 系以上にまで上げて仕舞えば治るはず
  display: { [_key in SearchQueryType]: string };
} = {
  values: [
    DocumentTypeEnum.Book,
    DocumentTypeEnum.Pdf,
    DocumentTypeEnum.Pubcom,
    DocumentTypeEnum.Case,
    DocumentTypeEnum.Law,
    DocumentTypeEnum.Guideline,
    DocumentTypeEnum.Extinv,
  ],
  display: {
    book: '書籍',
    pdf: 'PDF',
    pubcom: 'パブコメ',
    case: '判例',
    law: '法令',
    guideline: 'ガイドライン',
    extinv: '第三者委員会報告書',
  },
};

export const publishers = {
  book: [
    'ぎょうせい',
    '勁草書房',
    '商事法務',
    '商事法務研究会',
    '新日本法規出版',
    '中央経済社',
    '東京証券取引所',
    '東京大学出版会',
    '日本加除出版',
    '有斐閣',
  ],
  web: [
    '総務省',
    '法務省',
    '外務省',
    '財務省',
    '文部科学省',
    '厚生労働省',
    '農林水産省',
    '経済産業省',
    '国土交通省',
    '環境省',
    '防衛省',
  ],
};
