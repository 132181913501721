export default (function (_ref, inject) {
  var app = _ref.app;
  // const { $auth } = useNuxtApp();
  // inject('renewalCSTest', $auth.user.organization.oid === 'legalscapecstest');
  inject('renewalCSTest', false);
});
export var useRenewalCSTest = function useRenewalCSTest() {
  var _useNuxtApp = useNuxtApp(),
    $renewalCSTest = _useNuxtApp.$renewalCSTest;
  return $renewalCSTest;
};