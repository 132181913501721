import { Context, Plugin } from '@nuxt/types';
import { refreshSessionCookieIfNeeded } from '@/utils/authUtils';

// セッションクッキーの更新を試みる間隔 (秒)
// (実際に更新処理が走るのは keepSessionCookie のクッキーが expire しているときだけとなる)
const SECONDS_TO_TRY_REFRESHING = 60;

export default <Plugin>(({ $axios }: Context) => {
  if (!process.env.SKIP_AUTH) {
    setInterval(() => refreshSessionCookieIfNeeded($axios), SECONDS_TO_TRY_REFRESHING * 1000);
  }
});
