import { AnnounceKitApi } from '@/api/accounceKitApi';
import { byKeySorter } from '@/utils/arrayUtil';

const sorterUpdatedAt = byKeySorter('updated_at');

/** アナウンスメントが有効な期間 */
const ANNOUNCE_DURATION = 24 * 60 * 60 * 1000 * 7;

export class AnnouncementRepository {
  constructor(private api: AnnounceKitApi) {}

  /**
   * サイト横断で表示する必要があるアナウンスメント（障害情報や重要な告知など）の情報を取得する
   *
   * @returns 表示するべきアナウンスメント
   */
  async getAnnouncement(): Promise<{ title: string; url: string } | null> {
    const feed = await this.api.getAnnouncementPosts();
    if (feed === null) return null;
    const today = new Date();
    const validAnnouncements = feed.posts.list
      .map((post) => ({ ...post, updated_at: new Date(post.updated_at) }))
      .filter((post) => today.getTime() - post.updated_at.getTime() < ANNOUNCE_DURATION)
      .sort(sorterUpdatedAt);
    if (validAnnouncements.length < 1) return null;
    return validAnnouncements[validAnnouncements.length - 1].contents[0] || null;
  }
}
