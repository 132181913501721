import { CacheTTL } from '../constants';
import type { PublishersApi } from '@gen/wklr-backend-api/v1/api';
import type { DocumentTypeEnum } from '@gen/wklr-backend-api/v1/model';

export class PublishersRepository {
  constructor(private api: PublishersApi) {}

  async listPublisherNames(type: DocumentTypeEnum): Promise<string[]> {
    const res = await this.api.listPublishers(type, {
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return res.data.map(({ name }) => name);
  }
}
