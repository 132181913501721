/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocSection } from '../model';
/**
 * SectionsApi - axios parameter creator
 * @export
 */
export const SectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * doc-sections を取得する他の API とは異なり、marginBefore/marginAfter のパラメータはサポートしない
         * @summary 複数の key で指定されたセクションに対応する doc-sections を一括取得する
         * @param {string} id 文献を指し示す ID (ISBN など)
         * @param {Array<number>} keys doc-sections を取得しようとしているセクションを指し示す key (複数)。 対応するセクションが存在しない key が混在していてもエラーにはならず、なかったものとして処理される。ただし keys で指定されたいずれのセクションも存在しない場合は 404 が返される。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentSectionsByMultipleSectionKeys: async (id: string, keys: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentSectionsByMultipleSectionKeys', 'id', id)
            // verify required parameter 'keys' is not null or undefined
            assertParamExists('getDocumentSectionsByMultipleSectionKeys', 'keys', keys)
            const localVarPath = `/documents/{id}/sections/bulk`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keys) {
                localVarQueryParameter['keys'] = keys.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IDで指定された書籍の中からページ番号によって指定された章の内容コンテンツ全てを取得する。 取得した章の内容部分の先頭コンテンツから、パラメータで指定された数だけ移動したコンテンツを検索し、ヒットしたコンテンツを含む章全体を取得する。 これによってヒットした章とその内容部分全てを含む配列を返す。
         * @summary 指定された書籍内の、指定されたページ番号を含む章とその前後いくつかの章を取得する
         * @param {string} id 
         * @param {string} seq 
         * @param {number} marginBefore keyで指定した章よりいくつ前まで取得するのか
         * @param {number} marginAfter keyで指定した章よりいくつ後まで取得するのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentSectionsByPageSeq: async (id: string, seq: string, marginBefore: number, marginAfter: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentSectionsByPageSeq', 'id', id)
            // verify required parameter 'seq' is not null or undefined
            assertParamExists('getDocumentSectionsByPageSeq', 'seq', seq)
            // verify required parameter 'marginBefore' is not null or undefined
            assertParamExists('getDocumentSectionsByPageSeq', 'marginBefore', marginBefore)
            // verify required parameter 'marginAfter' is not null or undefined
            assertParamExists('getDocumentSectionsByPageSeq', 'marginAfter', marginAfter)
            const localVarPath = `/documents/{id}/sections/page-seq/{seq}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"seq"}}`, encodeURIComponent(String(seq)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (marginBefore !== undefined) {
                localVarQueryParameter['marginBefore'] = marginBefore;
            }

            if (marginAfter !== undefined) {
                localVarQueryParameter['marginAfter'] = marginAfter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IDで指定された書籍の中からキーによって指定された章の内容コンテンツ全てを取得する。 取得した章の内容部分の先頭コンテンツから、パラメータで指定された数だけ移動したコンテンツを検索し、ヒットしたコンテンツを含む章全体を取得する。 これによってヒットした章とその内容部分全てを含む配列を返す。
         * @summary 書籍内の特定の章とその前後いくつかの内容部分を取得する
         * @param {string} id 
         * @param {string} key 
         * @param {number} marginBefore keyで指定した章よりいくつ前まで取得するのか
         * @param {number} marginAfter keyで指定した章よりいくつ後まで取得するのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentSectionsBySectionKey: async (id: string, key: string, marginBefore: number, marginAfter: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentSectionsBySectionKey', 'id', id)
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getDocumentSectionsBySectionKey', 'key', key)
            // verify required parameter 'marginBefore' is not null or undefined
            assertParamExists('getDocumentSectionsBySectionKey', 'marginBefore', marginBefore)
            // verify required parameter 'marginAfter' is not null or undefined
            assertParamExists('getDocumentSectionsBySectionKey', 'marginAfter', marginAfter)
            const localVarPath = `/documents/{id}/sections/{key}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (marginBefore !== undefined) {
                localVarQueryParameter['marginBefore'] = marginBefore;
            }

            if (marginAfter !== undefined) {
                localVarQueryParameter['marginAfter'] = marginAfter;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SectionsApi - functional programming interface
 * @export
 */
export const SectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * doc-sections を取得する他の API とは異なり、marginBefore/marginAfter のパラメータはサポートしない
         * @summary 複数の key で指定されたセクションに対応する doc-sections を一括取得する
         * @param {string} id 文献を指し示す ID (ISBN など)
         * @param {Array<number>} keys doc-sections を取得しようとしているセクションを指し示す key (複数)。 対応するセクションが存在しない key が混在していてもエラーにはならず、なかったものとして処理される。ただし keys で指定されたいずれのセクションも存在しない場合は 404 が返される。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentSectionsByMultipleSectionKeys(id: string, keys: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocSection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentSectionsByMultipleSectionKeys(id, keys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * IDで指定された書籍の中からページ番号によって指定された章の内容コンテンツ全てを取得する。 取得した章の内容部分の先頭コンテンツから、パラメータで指定された数だけ移動したコンテンツを検索し、ヒットしたコンテンツを含む章全体を取得する。 これによってヒットした章とその内容部分全てを含む配列を返す。
         * @summary 指定された書籍内の、指定されたページ番号を含む章とその前後いくつかの章を取得する
         * @param {string} id 
         * @param {string} seq 
         * @param {number} marginBefore keyで指定した章よりいくつ前まで取得するのか
         * @param {number} marginAfter keyで指定した章よりいくつ後まで取得するのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentSectionsByPageSeq(id: string, seq: string, marginBefore: number, marginAfter: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocSection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentSectionsByPageSeq(id, seq, marginBefore, marginAfter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * IDで指定された書籍の中からキーによって指定された章の内容コンテンツ全てを取得する。 取得した章の内容部分の先頭コンテンツから、パラメータで指定された数だけ移動したコンテンツを検索し、ヒットしたコンテンツを含む章全体を取得する。 これによってヒットした章とその内容部分全てを含む配列を返す。
         * @summary 書籍内の特定の章とその前後いくつかの内容部分を取得する
         * @param {string} id 
         * @param {string} key 
         * @param {number} marginBefore keyで指定した章よりいくつ前まで取得するのか
         * @param {number} marginAfter keyで指定した章よりいくつ後まで取得するのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentSectionsBySectionKey(id: string, key: string, marginBefore: number, marginAfter: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocSection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentSectionsBySectionKey(id, key, marginBefore, marginAfter, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SectionsApi - factory interface
 * @export
 */
export const SectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SectionsApiFp(configuration)
    return {
        /**
         * doc-sections を取得する他の API とは異なり、marginBefore/marginAfter のパラメータはサポートしない
         * @summary 複数の key で指定されたセクションに対応する doc-sections を一括取得する
         * @param {string} id 文献を指し示す ID (ISBN など)
         * @param {Array<number>} keys doc-sections を取得しようとしているセクションを指し示す key (複数)。 対応するセクションが存在しない key が混在していてもエラーにはならず、なかったものとして処理される。ただし keys で指定されたいずれのセクションも存在しない場合は 404 が返される。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentSectionsByMultipleSectionKeys(id: string, keys: Array<number>, options?: any): AxiosPromise<Array<DocSection>> {
            return localVarFp.getDocumentSectionsByMultipleSectionKeys(id, keys, options).then((request) => request(axios, basePath));
        },
        /**
         * IDで指定された書籍の中からページ番号によって指定された章の内容コンテンツ全てを取得する。 取得した章の内容部分の先頭コンテンツから、パラメータで指定された数だけ移動したコンテンツを検索し、ヒットしたコンテンツを含む章全体を取得する。 これによってヒットした章とその内容部分全てを含む配列を返す。
         * @summary 指定された書籍内の、指定されたページ番号を含む章とその前後いくつかの章を取得する
         * @param {string} id 
         * @param {string} seq 
         * @param {number} marginBefore keyで指定した章よりいくつ前まで取得するのか
         * @param {number} marginAfter keyで指定した章よりいくつ後まで取得するのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentSectionsByPageSeq(id: string, seq: string, marginBefore: number, marginAfter: number, options?: any): AxiosPromise<Array<DocSection>> {
            return localVarFp.getDocumentSectionsByPageSeq(id, seq, marginBefore, marginAfter, options).then((request) => request(axios, basePath));
        },
        /**
         * IDで指定された書籍の中からキーによって指定された章の内容コンテンツ全てを取得する。 取得した章の内容部分の先頭コンテンツから、パラメータで指定された数だけ移動したコンテンツを検索し、ヒットしたコンテンツを含む章全体を取得する。 これによってヒットした章とその内容部分全てを含む配列を返す。
         * @summary 書籍内の特定の章とその前後いくつかの内容部分を取得する
         * @param {string} id 
         * @param {string} key 
         * @param {number} marginBefore keyで指定した章よりいくつ前まで取得するのか
         * @param {number} marginAfter keyで指定した章よりいくつ後まで取得するのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentSectionsBySectionKey(id: string, key: string, marginBefore: number, marginAfter: number, options?: any): AxiosPromise<Array<DocSection>> {
            return localVarFp.getDocumentSectionsBySectionKey(id, key, marginBefore, marginAfter, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SectionsApi - interface
 * @export
 * @interface SectionsApi
 */
export interface SectionsApiInterface {
    /**
     * doc-sections を取得する他の API とは異なり、marginBefore/marginAfter のパラメータはサポートしない
     * @summary 複数の key で指定されたセクションに対応する doc-sections を一括取得する
     * @param {string} id 文献を指し示す ID (ISBN など)
     * @param {Array<number>} keys doc-sections を取得しようとしているセクションを指し示す key (複数)。 対応するセクションが存在しない key が混在していてもエラーにはならず、なかったものとして処理される。ただし keys で指定されたいずれのセクションも存在しない場合は 404 が返される。 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApiInterface
     */
    getDocumentSectionsByMultipleSectionKeys(id: string, keys: Array<number>, options?: AxiosRequestConfig): AxiosPromise<Array<DocSection>>;

    /**
     * IDで指定された書籍の中からページ番号によって指定された章の内容コンテンツ全てを取得する。 取得した章の内容部分の先頭コンテンツから、パラメータで指定された数だけ移動したコンテンツを検索し、ヒットしたコンテンツを含む章全体を取得する。 これによってヒットした章とその内容部分全てを含む配列を返す。
     * @summary 指定された書籍内の、指定されたページ番号を含む章とその前後いくつかの章を取得する
     * @param {string} id 
     * @param {string} seq 
     * @param {number} marginBefore keyで指定した章よりいくつ前まで取得するのか
     * @param {number} marginAfter keyで指定した章よりいくつ後まで取得するのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApiInterface
     */
    getDocumentSectionsByPageSeq(id: string, seq: string, marginBefore: number, marginAfter: number, options?: AxiosRequestConfig): AxiosPromise<Array<DocSection>>;

    /**
     * IDで指定された書籍の中からキーによって指定された章の内容コンテンツ全てを取得する。 取得した章の内容部分の先頭コンテンツから、パラメータで指定された数だけ移動したコンテンツを検索し、ヒットしたコンテンツを含む章全体を取得する。 これによってヒットした章とその内容部分全てを含む配列を返す。
     * @summary 書籍内の特定の章とその前後いくつかの内容部分を取得する
     * @param {string} id 
     * @param {string} key 
     * @param {number} marginBefore keyで指定した章よりいくつ前まで取得するのか
     * @param {number} marginAfter keyで指定した章よりいくつ後まで取得するのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApiInterface
     */
    getDocumentSectionsBySectionKey(id: string, key: string, marginBefore: number, marginAfter: number, options?: AxiosRequestConfig): AxiosPromise<Array<DocSection>>;

}

/**
 * SectionsApi - object-oriented interface
 * @export
 * @class SectionsApi
 * @extends {BaseAPI}
 */
export class SectionsApi extends BaseAPI implements SectionsApiInterface {
    /**
     * doc-sections を取得する他の API とは異なり、marginBefore/marginAfter のパラメータはサポートしない
     * @summary 複数の key で指定されたセクションに対応する doc-sections を一括取得する
     * @param {string} id 文献を指し示す ID (ISBN など)
     * @param {Array<number>} keys doc-sections を取得しようとしているセクションを指し示す key (複数)。 対応するセクションが存在しない key が混在していてもエラーにはならず、なかったものとして処理される。ただし keys で指定されたいずれのセクションも存在しない場合は 404 が返される。 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public getDocumentSectionsByMultipleSectionKeys(id: string, keys: Array<number>, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).getDocumentSectionsByMultipleSectionKeys(id, keys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IDで指定された書籍の中からページ番号によって指定された章の内容コンテンツ全てを取得する。 取得した章の内容部分の先頭コンテンツから、パラメータで指定された数だけ移動したコンテンツを検索し、ヒットしたコンテンツを含む章全体を取得する。 これによってヒットした章とその内容部分全てを含む配列を返す。
     * @summary 指定された書籍内の、指定されたページ番号を含む章とその前後いくつかの章を取得する
     * @param {string} id 
     * @param {string} seq 
     * @param {number} marginBefore keyで指定した章よりいくつ前まで取得するのか
     * @param {number} marginAfter keyで指定した章よりいくつ後まで取得するのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public getDocumentSectionsByPageSeq(id: string, seq: string, marginBefore: number, marginAfter: number, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).getDocumentSectionsByPageSeq(id, seq, marginBefore, marginAfter, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IDで指定された書籍の中からキーによって指定された章の内容コンテンツ全てを取得する。 取得した章の内容部分の先頭コンテンツから、パラメータで指定された数だけ移動したコンテンツを検索し、ヒットしたコンテンツを含む章全体を取得する。 これによってヒットした章とその内容部分全てを含む配列を返す。
     * @summary 書籍内の特定の章とその前後いくつかの内容部分を取得する
     * @param {string} id 
     * @param {string} key 
     * @param {number} marginBefore keyで指定した章よりいくつ前まで取得するのか
     * @param {number} marginAfter keyで指定した章よりいくつ後まで取得するのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SectionsApi
     */
    public getDocumentSectionsBySectionKey(id: string, key: string, marginBefore: number, marginAfter: number, options?: AxiosRequestConfig) {
        return SectionsApiFp(this.configuration).getDocumentSectionsBySectionKey(id, key, marginBefore, marginAfter, options).then((request) => request(this.axios, this.basePath));
    }
}
