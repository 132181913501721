import { CacheTTL } from '../constants';
import type { FiguresApi } from '@gen/wklr-backend-api/v1/api/figures-api';
import type { DocumentFigureURL } from '@gen/wklr-backend-api/v1/model';

export class FiguresRepository {
  constructor(private api: FiguresApi) {}

  async getFigures(docId: string, figureId: string): Promise<DocumentFigureURL> {
    const { data } = await this.api.getDocumentFigureUrl(docId, figureId, {
      cache: { ttl: CacheTTL.SHORT },
    });
    return data;
  }
}
