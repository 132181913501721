import { getApps, initializeApp } from 'firebase/app';

if (!getApps().length) {
  initializeApp({
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_CONFIG_PROJECT_ID,
    appId: process.env.FIREBASE_APP_ID,
  });
}
