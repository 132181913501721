import { CacheTTL } from '../constants';
import type { EGovApi } from '@gen/wklr-backend-api/v1/api/egov-api';

export class EGovRepository {
  constructor(private api: EGovApi) {}

  async getEGovPublicCommentURL(id: string): Promise<string> {
    const { data } = await this.api.getEGovPublicCommentURL(id, {
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return data.url;
  }
}
