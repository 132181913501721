import { getStorage } from '@/plugins/session-storage';
import rison from 'rison';

export const FEATURE_TOGGLE_DEBUG_STORAGE_KEY = 'feature-toggle-debug-storage-key';
export const MODIFIED_HOST_DEBUG_STORAGE_KEY = 'modified-host-debug-storage-key';

export type FeatureSettings = {
  [key: string]: boolean;
};

export type FeatureKeys = keyof FeatureSettings;

export const getDebugSetting = (key: keyof FeatureSettings): boolean | undefined => {
  const storage = getStorage('sessionStorage');
  if (!storage) return;
  const value = storage.getItem(FEATURE_TOGGLE_DEBUG_STORAGE_KEY);
  if (value) {
    const setting = rison.decode_object<FeatureSettings>(value);
    return setting[key];
  }
};

export const getDebugHost = (): string | undefined => {
  const storage = getStorage('sessionStorage');
  if (!storage) return;
  const host = storage.getItem(MODIFIED_HOST_DEBUG_STORAGE_KEY);
  if (host) {
    return host;
  }
  return undefined;
};

export const isDebugModeEnabled = (): boolean => {
  const storage = getStorage('sessionStorage');
  if (!storage) return false;
  if (storage.getItem(FEATURE_TOGGLE_DEBUG_STORAGE_KEY) !== null) return true;
  if (storage.getItem(MODIFIED_HOST_DEBUG_STORAGE_KEY) !== null) return true;
  return false;
};
