/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 文書単位クイックアクセス要素の固有プロパティ 
 * @export
 * @interface QuickAccessItemTypeDocumentProperties
 */
export interface QuickAccessItemTypeDocumentProperties {
    /**
     * クイックアクセスに登録されたときのビューを識別する文字列
     * @type {string}
     * @memberof QuickAccessItemTypeDocumentProperties
     */
    'viewType': QuickAccessItemTypeDocumentPropertiesViewTypeEnum;
}

export const QuickAccessItemTypeDocumentPropertiesViewTypeEnum = {
    Document: 'document'
} as const;

export type QuickAccessItemTypeDocumentPropertiesViewTypeEnum = typeof QuickAccessItemTypeDocumentPropertiesViewTypeEnum[keyof typeof QuickAccessItemTypeDocumentPropertiesViewTypeEnum];


