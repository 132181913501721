/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentTypeEnum } from '../model';
// @ts-ignore
import { PublicPublisher } from '../model';
// @ts-ignore
import { Publisher } from '../model';
// @ts-ignore
import { PurchaseTypeEnum } from '../model';
/**
 * PublishersApi - axios parameter creator
 * @export
 */
export const PublishersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 出版社の一覧を取得する
         * @param {DocumentTypeEnum} type 資料の種類を表す文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublishers: async (type: DocumentTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('listPublishers', 'type', type)
            const localVarPath = `/publishers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 出版社の一覧を取得する
         * @param {DocumentTypeEnum} type 資料の種類を表す文字列
         * @param {PurchaseTypeEnum} [purchaseType] 資料の購買形式を表す文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListPublishers: async (type: DocumentTypeEnum, purchaseType?: PurchaseTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('publicListPublishers', 'type', type)
            const localVarPath = `/public/publishers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (purchaseType !== undefined) {
                localVarQueryParameter['purchaseType'] = purchaseType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublishersApi - functional programming interface
 * @export
 */
export const PublishersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublishersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 出版社の一覧を取得する
         * @param {DocumentTypeEnum} type 資料の種類を表す文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPublishers(type: DocumentTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Publisher>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPublishers(type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 出版社の一覧を取得する
         * @param {DocumentTypeEnum} type 資料の種類を表す文字列
         * @param {PurchaseTypeEnum} [purchaseType] 資料の購買形式を表す文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicListPublishers(type: DocumentTypeEnum, purchaseType?: PurchaseTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicPublisher>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicListPublishers(type, purchaseType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublishersApi - factory interface
 * @export
 */
export const PublishersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublishersApiFp(configuration)
    return {
        /**
         * 
         * @summary 出版社の一覧を取得する
         * @param {DocumentTypeEnum} type 資料の種類を表す文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPublishers(type: DocumentTypeEnum, options?: any): AxiosPromise<Array<Publisher>> {
            return localVarFp.listPublishers(type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 出版社の一覧を取得する
         * @param {DocumentTypeEnum} type 資料の種類を表す文字列
         * @param {PurchaseTypeEnum} [purchaseType] 資料の購買形式を表す文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListPublishers(type: DocumentTypeEnum, purchaseType?: PurchaseTypeEnum, options?: any): AxiosPromise<Array<PublicPublisher>> {
            return localVarFp.publicListPublishers(type, purchaseType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublishersApi - interface
 * @export
 * @interface PublishersApi
 */
export interface PublishersApiInterface {
    /**
     * 
     * @summary 出版社の一覧を取得する
     * @param {DocumentTypeEnum} type 資料の種類を表す文字列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublishersApiInterface
     */
    listPublishers(type: DocumentTypeEnum, options?: AxiosRequestConfig): AxiosPromise<Array<Publisher>>;

    /**
     * 
     * @summary 出版社の一覧を取得する
     * @param {DocumentTypeEnum} type 資料の種類を表す文字列
     * @param {PurchaseTypeEnum} [purchaseType] 資料の購買形式を表す文字列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublishersApiInterface
     */
    publicListPublishers(type: DocumentTypeEnum, purchaseType?: PurchaseTypeEnum, options?: AxiosRequestConfig): AxiosPromise<Array<PublicPublisher>>;

}

/**
 * PublishersApi - object-oriented interface
 * @export
 * @class PublishersApi
 * @extends {BaseAPI}
 */
export class PublishersApi extends BaseAPI implements PublishersApiInterface {
    /**
     * 
     * @summary 出版社の一覧を取得する
     * @param {DocumentTypeEnum} type 資料の種類を表す文字列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublishersApi
     */
    public listPublishers(type: DocumentTypeEnum, options?: AxiosRequestConfig) {
        return PublishersApiFp(this.configuration).listPublishers(type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 出版社の一覧を取得する
     * @param {DocumentTypeEnum} type 資料の種類を表す文字列
     * @param {PurchaseTypeEnum} [purchaseType] 資料の購買形式を表す文字列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublishersApi
     */
    public publicListPublishers(type: DocumentTypeEnum, purchaseType?: PurchaseTypeEnum, options?: AxiosRequestConfig) {
        return PublishersApiFp(this.configuration).publicListPublishers(type, purchaseType, options).then((request) => request(this.axios, this.basePath));
    }
}
