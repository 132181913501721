<template>
  <v-icon :size="sizeInPixel" :color="color" :left="left" :disabled="disabled">{{ icon }}</v-icon>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { IconTypes, IconSize } from './types';

/**
 * 機能別のアイコンを表示する
 *
 * ## アイコン
 *
 * 種類|機能|オリジナル
 * ---|---|---
 * share | 共有機能 | mdi-link
 * quickAccess | クイックアクセス | mdi-star
 * bookmark | ブックマーク機能 | mdi-bookmark
 * add | 何かに追加する | mdi-plus
 * chevron | 開く・閉じる | mdi-chevron-right
 * changelog | チェンジログ | mdi-bell
 * user | ユーザー | mdi-account
 *
 * ## サイズ
 *
 * 種類|サイズ
 * ---|---
 * large | 36px
 * normal | 24px
 * small | 20px
 * x-small | 16px
 */
@Component
export default class WklrIcon extends Vue {
  /**
   * アイコンの種類
   */
  @Prop()
  type!: IconTypes;

  /**
   * アイコンのサイズ
   */
  @Prop({ default: 'small' })
  size!: IconSize;

  /**
   * アイコンのカラー。v-icon の color に渡せる値を文字列で入れる
   */
  @Prop({ default: 'default' })
  color!: string;

  @Prop({ default: false })
  left!: boolean;

  /**
   * アイコンが disalbled な場合に true にする
   */
  @Prop()
  disabled!: boolean;

  get sizeInPixel(): string {
    switch (this.size) {
      case 'normal':
        return '24';
      case 'small':
        return '20';
      case 'x-small':
        return '16';
    }
  }

  get icon(): string {
    switch (this.type) {
      case 'share':
        return 'mdi-link';
      case 'bookmark':
        return 'mdi-bookmark';
      case 'quickAccess':
        return 'mdi-star';
      case 'add':
        return 'mdi-plus';
      case 'chevron':
        return 'mdi-chevron-right';
      case 'copy':
        return 'mdi-content-copy';
      case 'highlight':
        return 'mdi-marker';
      case 'report':
        return 'mdi-bullhorn';
      case 'changelog':
        return 'mdi-bell';
      case 'user':
        return 'mdi-account';
    }
  }
}
</script>
