/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocumentTypeEnum } from '../model';
// @ts-ignore
import { HandleShopifyWebhookParamsBody } from '../model';
// @ts-ignore
import { PublicDocRecord } from '../model';
// @ts-ignore
import { PublicPublisher } from '../model';
// @ts-ignore
import { PublicRequestSearchQuery } from '../model';
// @ts-ignore
import { PublicSearchResult } from '../model';
// @ts-ignore
import { PurchaseTypeEnum } from '../model';
/**
 * PublicApi - axios parameter creator
 * @export
 */
export const PublicApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * IDで指定されたドキュメントを取得する。（書誌情報のみ）
         * @summary IDで指定された書籍を取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPublic: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentPublic', 'id', id)
            const localVarPath = `/public/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Shopify Webhook用エンドポイント(ユーザが購入した書籍を読めるよう登録)
         * @param {HandleShopifyWebhookParamsBody} [handleShopifyWebhookParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleShopifyWebhook: async (handleShopifyWebhookParamsBody?: HandleShopifyWebhookParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/shopify/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(handleShopifyWebhookParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 出版社の一覧を取得する
         * @param {DocumentTypeEnum} type 資料の種類を表す文字列
         * @param {PurchaseTypeEnum} [purchaseType] 資料の購買形式を表す文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListPublishers: async (type: DocumentTypeEnum, purchaseType?: PurchaseTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('publicListPublishers', 'type', type)
            const localVarPath = `/public/publishers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (purchaseType !== undefined) {
                localVarQueryParameter['purchaseType'] = purchaseType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 検索ワードや条件に合致する書籍のリストが取得され、配列として返される。 `/search` とは異なりパブリックなアクセスを前提とする
         * @summary 検索条件に合致する書籍の配列を取得する公開エンドポイント
         * @param {PublicRequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
         * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicSearchDocuments: async (q?: PublicRequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicApi - functional programming interface
 * @export
 */
export const PublicApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicApiAxiosParamCreator(configuration)
    return {
        /**
         * IDで指定されたドキュメントを取得する。（書誌情報のみ）
         * @summary IDで指定された書籍を取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentPublic(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicDocRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentPublic(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Shopify Webhook用エンドポイント(ユーザが購入した書籍を読めるよう登録)
         * @param {HandleShopifyWebhookParamsBody} [handleShopifyWebhookParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleShopifyWebhook(handleShopifyWebhookParamsBody?: HandleShopifyWebhookParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleShopifyWebhook(handleShopifyWebhookParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 出版社の一覧を取得する
         * @param {DocumentTypeEnum} type 資料の種類を表す文字列
         * @param {PurchaseTypeEnum} [purchaseType] 資料の購買形式を表す文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicListPublishers(type: DocumentTypeEnum, purchaseType?: PurchaseTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicPublisher>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicListPublishers(type, purchaseType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 検索ワードや条件に合致する書籍のリストが取得され、配列として返される。 `/search` とは異なりパブリックなアクセスを前提とする
         * @summary 検索条件に合致する書籍の配列を取得する公開エンドポイント
         * @param {PublicRequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
         * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicSearchDocuments(q?: PublicRequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicSearchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicSearchDocuments(q, from, size, orderBy, order, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicApi - factory interface
 * @export
 */
export const PublicApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicApiFp(configuration)
    return {
        /**
         * IDで指定されたドキュメントを取得する。（書誌情報のみ）
         * @summary IDで指定された書籍を取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPublic(id: string, options?: any): AxiosPromise<PublicDocRecord> {
            return localVarFp.getDocumentPublic(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Shopify Webhook用エンドポイント(ユーザが購入した書籍を読めるよう登録)
         * @param {HandleShopifyWebhookParamsBody} [handleShopifyWebhookParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleShopifyWebhook(handleShopifyWebhookParamsBody?: HandleShopifyWebhookParamsBody, options?: any): AxiosPromise<void> {
            return localVarFp.handleShopifyWebhook(handleShopifyWebhookParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 出版社の一覧を取得する
         * @param {DocumentTypeEnum} type 資料の種類を表す文字列
         * @param {PurchaseTypeEnum} [purchaseType] 資料の購買形式を表す文字列
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicListPublishers(type: DocumentTypeEnum, purchaseType?: PurchaseTypeEnum, options?: any): AxiosPromise<Array<PublicPublisher>> {
            return localVarFp.publicListPublishers(type, purchaseType, options).then((request) => request(axios, basePath));
        },
        /**
         * 検索ワードや条件に合致する書籍のリストが取得され、配列として返される。 `/search` とは異なりパブリックなアクセスを前提とする
         * @summary 検索条件に合致する書籍の配列を取得する公開エンドポイント
         * @param {PublicRequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
         * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicSearchDocuments(q?: PublicRequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options?: any): AxiosPromise<PublicSearchResult> {
            return localVarFp.publicSearchDocuments(q, from, size, orderBy, order, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PublicApi - interface
 * @export
 * @interface PublicApi
 */
export interface PublicApiInterface {
    /**
     * IDで指定されたドキュメントを取得する。（書誌情報のみ）
     * @summary IDで指定された書籍を取得する
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    getDocumentPublic(id: string, options?: AxiosRequestConfig): AxiosPromise<PublicDocRecord>;

    /**
     * 
     * @summary Shopify Webhook用エンドポイント(ユーザが購入した書籍を読めるよう登録)
     * @param {HandleShopifyWebhookParamsBody} [handleShopifyWebhookParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    handleShopifyWebhook(handleShopifyWebhookParamsBody?: HandleShopifyWebhookParamsBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary 出版社の一覧を取得する
     * @param {DocumentTypeEnum} type 資料の種類を表す文字列
     * @param {PurchaseTypeEnum} [purchaseType] 資料の購買形式を表す文字列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    publicListPublishers(type: DocumentTypeEnum, purchaseType?: PurchaseTypeEnum, options?: AxiosRequestConfig): AxiosPromise<Array<PublicPublisher>>;

    /**
     * 検索ワードや条件に合致する書籍のリストが取得され、配列として返される。 `/search` とは異なりパブリックなアクセスを前提とする
     * @summary 検索条件に合致する書籍の配列を取得する公開エンドポイント
     * @param {PublicRequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
     * @param {number} [from] 検索結果の何件目から表示するのか
     * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
     * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
     * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApiInterface
     */
    publicSearchDocuments(q?: PublicRequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options?: AxiosRequestConfig): AxiosPromise<PublicSearchResult>;

}

/**
 * PublicApi - object-oriented interface
 * @export
 * @class PublicApi
 * @extends {BaseAPI}
 */
export class PublicApi extends BaseAPI implements PublicApiInterface {
    /**
     * IDで指定されたドキュメントを取得する。（書誌情報のみ）
     * @summary IDで指定された書籍を取得する
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public getDocumentPublic(id: string, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).getDocumentPublic(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Shopify Webhook用エンドポイント(ユーザが購入した書籍を読めるよう登録)
     * @param {HandleShopifyWebhookParamsBody} [handleShopifyWebhookParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public handleShopifyWebhook(handleShopifyWebhookParamsBody?: HandleShopifyWebhookParamsBody, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).handleShopifyWebhook(handleShopifyWebhookParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 出版社の一覧を取得する
     * @param {DocumentTypeEnum} type 資料の種類を表す文字列
     * @param {PurchaseTypeEnum} [purchaseType] 資料の購買形式を表す文字列
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicListPublishers(type: DocumentTypeEnum, purchaseType?: PurchaseTypeEnum, options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicListPublishers(type, purchaseType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 検索ワードや条件に合致する書籍のリストが取得され、配列として返される。 `/search` とは異なりパブリックなアクセスを前提とする
     * @summary 検索条件に合致する書籍の配列を取得する公開エンドポイント
     * @param {PublicRequestSearchQuery} [q] URLエンコードされたJSON形式の文字列
     * @param {number} [from] 検索結果の何件目から表示するのか
     * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
     * @param {'title' | 'authors' | 'publisher' | 'publishedOn'} [orderBy] 並び順の基準となる項目
     * @param {'asc' | 'desc'} [order] 並び順を昇順か降順のいずれにするのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicApi
     */
    public publicSearchDocuments(q?: PublicRequestSearchQuery, from?: number, size?: number, orderBy?: 'title' | 'authors' | 'publisher' | 'publishedOn', order?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return PublicApiFp(this.configuration).publicSearchDocuments(q, from, size, orderBy, order, options).then((request) => request(this.axios, this.basePath));
    }
}
