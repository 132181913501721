/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DocRecordTiny } from '../model';
// @ts-ignore
import { GetAccountResponse } from '../model';
// @ts-ignore
import { GetMeResponse } from '../model';
// @ts-ignore
import { GetPerDocumentQuotaResponse } from '../model';
// @ts-ignore
import { ListPracticesForUserProfile200Response } from '../model';
// @ts-ignore
import { PutUserNewsCategoryParamsBody } from '../model';
// @ts-ignore
import { PutUserProfileParamsBody } from '../model';
// @ts-ignore
import { TotalCount } from '../model';
// @ts-ignore
import { UserNewsCategoriesResponse } from '../model';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 自分のユーザのアカウント情報を取得する
         * @summary 自分のユーザのアカウント情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 自分のユーザー情報を取得する
         * @summary 自分のユーザー情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウントの個別書籍に対する直近180日間の残クオータを取得する
         * @summary アカウントの個別書籍に対する直近180日間の残クオータを取得する
         * @param {string} docId 文献を指し示す ID (ISBN など)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerDocumentQuota: async (docId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'docId' is not null or undefined
            assertParamExists('getPerDocumentQuota', 'docId', docId)
            const localVarPath = `/users/me/per-document-quota/{docId}`
                .replace(`{${"docId"}}`, encodeURIComponent(String(docId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウントの購入済書籍の配列を、発行年月日降順で返す
         * @summary 最近追加された書籍一覧を取得する
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasedDocuments: async (from?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/purchased-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウントの購入済み書籍の全数カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasedDocumentsCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/purchased-documents/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーが指定した並び順でニュースカテゴリを取得する
         * @summary ユーザーが指定した並び順でニュースカテゴリを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNewsCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/news-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザのプロフィールとして指定できるプラクティスの一覧を返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPracticesForUserProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user-profile/practices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザーが指定した並び順でニュースカテゴリを保存する
         * @summary ユーザーが指定した並び順でニュースカテゴリを保存する
         * @param {PutUserNewsCategoryParamsBody} [putUserNewsCategoryParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserNewsCategories: async (putUserNewsCategoryParamsBody?: PutUserNewsCategoryParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/news-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putUserNewsCategoryParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ユーザプロフィールを登録する
         * @param {PutUserProfileParamsBody} [putUserProfileParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserProfile: async (putUserProfileParamsBody?: PutUserProfileParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putUserProfileParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 既存ユーザによるユーザプロフィール入力のスキップを受け入れる
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipInputUserProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me/profile/skip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 自分のユーザのアカウント情報を取得する
         * @summary 自分のユーザのアカウント情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 自分のユーザー情報を取得する
         * @summary 自分のユーザー情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウントの個別書籍に対する直近180日間の残クオータを取得する
         * @summary アカウントの個別書籍に対する直近180日間の残クオータを取得する
         * @param {string} docId 文献を指し示す ID (ISBN など)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPerDocumentQuota(docId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPerDocumentQuotaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPerDocumentQuota(docId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウントの購入済書籍の配列を、発行年月日降順で返す
         * @summary 最近追加された書籍一覧を取得する
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPurchasedDocuments(from?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocRecordTiny>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPurchasedDocuments(from, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * アカウントの購入済み書籍の全数カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPurchasedDocumentsCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPurchasedDocumentsCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーが指定した並び順でニュースカテゴリを取得する
         * @summary ユーザーが指定した並び順でニュースカテゴリを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserNewsCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserNewsCategoriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserNewsCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザのプロフィールとして指定できるプラクティスの一覧を返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPracticesForUserProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPracticesForUserProfile200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPracticesForUserProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザーが指定した並び順でニュースカテゴリを保存する
         * @summary ユーザーが指定した並び順でニュースカテゴリを保存する
         * @param {PutUserNewsCategoryParamsBody} [putUserNewsCategoryParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserNewsCategories(putUserNewsCategoryParamsBody?: PutUserNewsCategoryParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserNewsCategories(putUserNewsCategoryParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ユーザプロフィールを登録する
         * @param {PutUserProfileParamsBody} [putUserProfileParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserProfile(putUserProfileParamsBody?: PutUserProfileParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserProfile(putUserProfileParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 既存ユーザによるユーザプロフィール入力のスキップを受け入れる
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async skipInputUserProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.skipInputUserProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 自分のユーザのアカウント情報を取得する
         * @summary 自分のユーザのアカウント情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(options?: any): AxiosPromise<GetAccountResponse> {
            return localVarFp.getAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 自分のユーザー情報を取得する
         * @summary 自分のユーザー情報を取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options?: any): AxiosPromise<GetMeResponse> {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
        /**
         * アカウントの個別書籍に対する直近180日間の残クオータを取得する
         * @summary アカウントの個別書籍に対する直近180日間の残クオータを取得する
         * @param {string} docId 文献を指し示す ID (ISBN など)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPerDocumentQuota(docId: string, options?: any): AxiosPromise<GetPerDocumentQuotaResponse> {
            return localVarFp.getPerDocumentQuota(docId, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウントの購入済書籍の配列を、発行年月日降順で返す
         * @summary 最近追加された書籍一覧を取得する
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasedDocuments(from?: number, size?: number, options?: any): AxiosPromise<Array<DocRecordTiny>> {
            return localVarFp.getPurchasedDocuments(from, size, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウントの購入済み書籍の全数カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPurchasedDocumentsCount(options?: any): AxiosPromise<TotalCount> {
            return localVarFp.getPurchasedDocumentsCount(options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーが指定した並び順でニュースカテゴリを取得する
         * @summary ユーザーが指定した並び順でニュースカテゴリを取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNewsCategories(options?: any): AxiosPromise<UserNewsCategoriesResponse> {
            return localVarFp.getUserNewsCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザのプロフィールとして指定できるプラクティスの一覧を返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPracticesForUserProfile(options?: any): AxiosPromise<ListPracticesForUserProfile200Response> {
            return localVarFp.listPracticesForUserProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザーが指定した並び順でニュースカテゴリを保存する
         * @summary ユーザーが指定した並び順でニュースカテゴリを保存する
         * @param {PutUserNewsCategoryParamsBody} [putUserNewsCategoryParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserNewsCategories(putUserNewsCategoryParamsBody?: PutUserNewsCategoryParamsBody, options?: any): AxiosPromise<void> {
            return localVarFp.putUserNewsCategories(putUserNewsCategoryParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * ユーザプロフィールを登録する
         * @param {PutUserProfileParamsBody} [putUserProfileParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserProfile(putUserProfileParamsBody?: PutUserProfileParamsBody, options?: any): AxiosPromise<void> {
            return localVarFp.putUserProfile(putUserProfileParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 既存ユーザによるユーザプロフィール入力のスキップを受け入れる
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        skipInputUserProfile(options?: any): AxiosPromise<void> {
            return localVarFp.skipInputUserProfile(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - interface
 * @export
 * @interface UsersApi
 */
export interface UsersApiInterface {
    /**
     * 自分のユーザのアカウント情報を取得する
     * @summary 自分のユーザのアカウント情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getAccount(options?: AxiosRequestConfig): AxiosPromise<GetAccountResponse>;

    /**
     * 自分のユーザー情報を取得する
     * @summary 自分のユーザー情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getMe(options?: AxiosRequestConfig): AxiosPromise<GetMeResponse>;

    /**
     * アカウントの個別書籍に対する直近180日間の残クオータを取得する
     * @summary アカウントの個別書籍に対する直近180日間の残クオータを取得する
     * @param {string} docId 文献を指し示す ID (ISBN など)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getPerDocumentQuota(docId: string, options?: AxiosRequestConfig): AxiosPromise<GetPerDocumentQuotaResponse>;

    /**
     * アカウントの購入済書籍の配列を、発行年月日降順で返す
     * @summary 最近追加された書籍一覧を取得する
     * @param {number} [from] 検索結果の何件目から表示するのか
     * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getPurchasedDocuments(from?: number, size?: number, options?: AxiosRequestConfig): AxiosPromise<Array<DocRecordTiny>>;

    /**
     * アカウントの購入済み書籍の全数カウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getPurchasedDocumentsCount(options?: AxiosRequestConfig): AxiosPromise<TotalCount>;

    /**
     * ユーザーが指定した並び順でニュースカテゴリを取得する
     * @summary ユーザーが指定した並び順でニュースカテゴリを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    getUserNewsCategories(options?: AxiosRequestConfig): AxiosPromise<UserNewsCategoriesResponse>;

    /**
     * ユーザのプロフィールとして指定できるプラクティスの一覧を返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    listPracticesForUserProfile(options?: AxiosRequestConfig): AxiosPromise<ListPracticesForUserProfile200Response>;

    /**
     * ユーザーが指定した並び順でニュースカテゴリを保存する
     * @summary ユーザーが指定した並び順でニュースカテゴリを保存する
     * @param {PutUserNewsCategoryParamsBody} [putUserNewsCategoryParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    putUserNewsCategories(putUserNewsCategoryParamsBody?: PutUserNewsCategoryParamsBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * ユーザプロフィールを登録する
     * @param {PutUserProfileParamsBody} [putUserProfileParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    putUserProfile(putUserProfileParamsBody?: PutUserProfileParamsBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 既存ユーザによるユーザプロフィール入力のスキップを受け入れる
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApiInterface
     */
    skipInputUserProfile(options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI implements UsersApiInterface {
    /**
     * 自分のユーザのアカウント情報を取得する
     * @summary 自分のユーザのアカウント情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAccount(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 自分のユーザー情報を取得する
     * @summary 自分のユーザー情報を取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMe(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウントの個別書籍に対する直近180日間の残クオータを取得する
     * @summary アカウントの個別書籍に対する直近180日間の残クオータを取得する
     * @param {string} docId 文献を指し示す ID (ISBN など)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPerDocumentQuota(docId: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getPerDocumentQuota(docId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウントの購入済書籍の配列を、発行年月日降順で返す
     * @summary 最近追加された書籍一覧を取得する
     * @param {number} [from] 検索結果の何件目から表示するのか
     * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPurchasedDocuments(from?: number, size?: number, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getPurchasedDocuments(from, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウントの購入済み書籍の全数カウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getPurchasedDocumentsCount(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getPurchasedDocumentsCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーが指定した並び順でニュースカテゴリを取得する
     * @summary ユーザーが指定した並び順でニュースカテゴリを取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserNewsCategories(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserNewsCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザのプロフィールとして指定できるプラクティスの一覧を返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listPracticesForUserProfile(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).listPracticesForUserProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザーが指定した並び順でニュースカテゴリを保存する
     * @summary ユーザーが指定した並び順でニュースカテゴリを保存する
     * @param {PutUserNewsCategoryParamsBody} [putUserNewsCategoryParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putUserNewsCategories(putUserNewsCategoryParamsBody?: PutUserNewsCategoryParamsBody, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).putUserNewsCategories(putUserNewsCategoryParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ユーザプロフィールを登録する
     * @param {PutUserProfileParamsBody} [putUserProfileParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public putUserProfile(putUserProfileParamsBody?: PutUserProfileParamsBody, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).putUserProfile(putUserProfileParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 既存ユーザによるユーザプロフィール入力のスキップを受け入れる
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public skipInputUserProfile(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).skipInputUserProfile(options).then((request) => request(this.axios, this.basePath));
    }
}
