import Vue from 'vue';
import VueLazyLoad from 'vue-lazyload';
import error from '@/assets/noimage.png';
import loading from '@/assets/loading.png';

Vue.use(VueLazyLoad, {
  error,
  loading,
  attempt: 1,
  observer: true,
  observerOptions: {
    threshold: 0.1,
  },
});
