/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface TextContent
 */
export interface TextContent {
    /**
     * content の通し番号
     * @type {number}
     * @memberof TextContent
     */
    'series': number;
    /**
     * TextContent の場合は \'text\' が設定される
     * @type {string}
     * @memberof TextContent
     */
    'type': TextContentTypeEnum;
    /**
     * content を構成する本文
     * @type {string}
     * @memberof TextContent
     */
    'text': string;
    /**
     * フィギュア情報が ES のオブジェクトに含まれているか
     * @type {boolean}
     * @memberof TextContent
     */
    'hasFigureInfo': boolean;
}

export const TextContentTypeEnum = {
    Text: 'text'
} as const;

export type TextContentTypeEnum = typeof TextContentTypeEnum[keyof typeof TextContentTypeEnum];


