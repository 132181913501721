/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BookCitations } from '../model';
// @ts-ignore
import { CitationCounts } from '../model';
// @ts-ignore
import { GuidelineCitations } from '../model';
// @ts-ignore
import { KommentarCitations } from '../model';
// @ts-ignore
import { LawCitations } from '../model';
// @ts-ignore
import { PublicCommentCitations } from '../model';
/**
 * CitationsApi - axios parameter creator
 * @export
 */
export const CitationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した文書のセクションを参照している件数を文書タイプ別に取得する。 件数は文書数ではなく、参照回数。DBでいうと、doc_referenceのレコード数に相当する。 
         * @summary 指定した文書のセクションを参照している件数を文書タイプ別に取得する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationsCount: async (docNaturalId: string, keys: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'docNaturalId' is not null or undefined
            assertParamExists('getCitationsCount', 'docNaturalId', docNaturalId)
            // verify required parameter 'keys' is not null or undefined
            assertParamExists('getCitationsCount', 'keys', keys)
            const localVarPath = `/citations/{docNaturalId}/count`
                .replace(`{${"docNaturalId"}}`, encodeURIComponent(String(docNaturalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keys) {
                localVarQueryParameter['keys'] = keys.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した文書を参照しているセクション別の件数を配列で文書タイプ別に取得する。 件数は文書数ではなく、参照回数。DBでいうと、doc_referenceのレコード数に相当する。 
         * @summary 指定した文書を参照しているセクション別の件数を配列で文書タイプ別に取得する
         * @param {string} docNaturalId 参照される文書のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationsCounts: async (docNaturalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'docNaturalId' is not null or undefined
            assertParamExists('getCitationsCounts', 'docNaturalId', docNaturalId)
            const localVarPath = `/citations/{docNaturalId}/counts`
                .replace(`{${"docNaturalId"}}`, encodeURIComponent(String(docNaturalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した文書のセクションを参照している書籍を検索する
         * @summary 指定した文書のセクションを参照している書籍を検索する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationsInBook: async (docNaturalId: string, keys: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'docNaturalId' is not null or undefined
            assertParamExists('getCitationsInBook', 'docNaturalId', docNaturalId)
            // verify required parameter 'keys' is not null or undefined
            assertParamExists('getCitationsInBook', 'keys', keys)
            const localVarPath = `/citations/{docNaturalId}/book`
                .replace(`{${"docNaturalId"}}`, encodeURIComponent(String(docNaturalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keys) {
                localVarQueryParameter['keys'] = keys.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した文書のセクションを参照しているガイドラインを検索する
         * @summary 指定した文書のセクションを参照しているガイドラインを検索する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationsInGuideline: async (docNaturalId: string, keys: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'docNaturalId' is not null or undefined
            assertParamExists('getCitationsInGuideline', 'docNaturalId', docNaturalId)
            // verify required parameter 'keys' is not null or undefined
            assertParamExists('getCitationsInGuideline', 'keys', keys)
            const localVarPath = `/citations/{docNaturalId}/guideline`
                .replace(`{${"docNaturalId"}}`, encodeURIComponent(String(docNaturalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keys) {
                localVarQueryParameter['keys'] = keys.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した文書のセクションを参照している法令を検索する
         * @summary 指定した文書のセクションを参照している法令を検索する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationsInLaw: async (docNaturalId: string, keys: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'docNaturalId' is not null or undefined
            assertParamExists('getCitationsInLaw', 'docNaturalId', docNaturalId)
            // verify required parameter 'keys' is not null or undefined
            assertParamExists('getCitationsInLaw', 'keys', keys)
            const localVarPath = `/citations/{docNaturalId}/law`
                .replace(`{${"docNaturalId"}}`, encodeURIComponent(String(docNaturalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keys) {
                localVarQueryParameter['keys'] = keys.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した文書のセクションを参照しているパブリックコメントを検索する
         * @summary 指定した文書のセクションを参照しているパブリックコメントを検索する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationsInPublicComment: async (docNaturalId: string, keys: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'docNaturalId' is not null or undefined
            assertParamExists('getCitationsInPublicComment', 'docNaturalId', docNaturalId)
            // verify required parameter 'keys' is not null or undefined
            assertParamExists('getCitationsInPublicComment', 'keys', keys)
            const localVarPath = `/citations/{docNaturalId}/public-comment`
                .replace(`{${"docNaturalId"}}`, encodeURIComponent(String(docNaturalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keys) {
                localVarQueryParameter['keys'] = keys.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary 法令に対応するコンメンタールの節のリストを取得する
         * @param {string} lawNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawKommentars: async (lawNaturalId: string, keys: Array<number>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lawNaturalId' is not null or undefined
            assertParamExists('getLawKommentars', 'lawNaturalId', lawNaturalId)
            // verify required parameter 'keys' is not null or undefined
            assertParamExists('getLawKommentars', 'keys', keys)
            const localVarPath = `/laws/{lawNaturalId}/kommentars`
                .replace(`{${"lawNaturalId"}}`, encodeURIComponent(String(lawNaturalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keys) {
                localVarQueryParameter['keys'] = keys.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CitationsApi - functional programming interface
 * @export
 */
export const CitationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CitationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した文書のセクションを参照している件数を文書タイプ別に取得する。 件数は文書数ではなく、参照回数。DBでいうと、doc_referenceのレコード数に相当する。 
         * @summary 指定した文書のセクションを参照している件数を文書タイプ別に取得する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitationsCount(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CitationCounts>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitationsCount(docNaturalId, keys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した文書を参照しているセクション別の件数を配列で文書タイプ別に取得する。 件数は文書数ではなく、参照回数。DBでいうと、doc_referenceのレコード数に相当する。 
         * @summary 指定した文書を参照しているセクション別の件数を配列で文書タイプ別に取得する
         * @param {string} docNaturalId 参照される文書のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitationsCounts(docNaturalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: CitationCounts; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitationsCounts(docNaturalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した文書のセクションを参照している書籍を検索する
         * @summary 指定した文書のセクションを参照している書籍を検索する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitationsInBook(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BookCitations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitationsInBook(docNaturalId, keys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した文書のセクションを参照しているガイドラインを検索する
         * @summary 指定した文書のセクションを参照しているガイドラインを検索する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitationsInGuideline(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GuidelineCitations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitationsInGuideline(docNaturalId, keys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した文書のセクションを参照している法令を検索する
         * @summary 指定した文書のセクションを参照している法令を検索する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitationsInLaw(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LawCitations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitationsInLaw(docNaturalId, keys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した文書のセクションを参照しているパブリックコメントを検索する
         * @summary 指定した文書のセクションを参照しているパブリックコメントを検索する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCitationsInPublicComment(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicCommentCitations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCitationsInPublicComment(docNaturalId, keys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary 法令に対応するコンメンタールの節のリストを取得する
         * @param {string} lawNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLawKommentars(lawNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KommentarCitations>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLawKommentars(lawNaturalId, keys, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CitationsApi - factory interface
 * @export
 */
export const CitationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CitationsApiFp(configuration)
    return {
        /**
         * 指定した文書のセクションを参照している件数を文書タイプ別に取得する。 件数は文書数ではなく、参照回数。DBでいうと、doc_referenceのレコード数に相当する。 
         * @summary 指定した文書のセクションを参照している件数を文書タイプ別に取得する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationsCount(docNaturalId: string, keys: Array<number>, options?: any): AxiosPromise<CitationCounts> {
            return localVarFp.getCitationsCount(docNaturalId, keys, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した文書を参照しているセクション別の件数を配列で文書タイプ別に取得する。 件数は文書数ではなく、参照回数。DBでいうと、doc_referenceのレコード数に相当する。 
         * @summary 指定した文書を参照しているセクション別の件数を配列で文書タイプ別に取得する
         * @param {string} docNaturalId 参照される文書のID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationsCounts(docNaturalId: string, options?: any): AxiosPromise<{ [key: string]: CitationCounts; }> {
            return localVarFp.getCitationsCounts(docNaturalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した文書のセクションを参照している書籍を検索する
         * @summary 指定した文書のセクションを参照している書籍を検索する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationsInBook(docNaturalId: string, keys: Array<number>, options?: any): AxiosPromise<Array<BookCitations>> {
            return localVarFp.getCitationsInBook(docNaturalId, keys, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した文書のセクションを参照しているガイドラインを検索する
         * @summary 指定した文書のセクションを参照しているガイドラインを検索する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationsInGuideline(docNaturalId: string, keys: Array<number>, options?: any): AxiosPromise<Array<GuidelineCitations>> {
            return localVarFp.getCitationsInGuideline(docNaturalId, keys, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した文書のセクションを参照している法令を検索する
         * @summary 指定した文書のセクションを参照している法令を検索する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationsInLaw(docNaturalId: string, keys: Array<number>, options?: any): AxiosPromise<Array<LawCitations>> {
            return localVarFp.getCitationsInLaw(docNaturalId, keys, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した文書のセクションを参照しているパブリックコメントを検索する
         * @summary 指定した文書のセクションを参照しているパブリックコメントを検索する
         * @param {string} docNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCitationsInPublicComment(docNaturalId: string, keys: Array<number>, options?: any): AxiosPromise<Array<PublicCommentCitations>> {
            return localVarFp.getCitationsInPublicComment(docNaturalId, keys, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary 法令に対応するコンメンタールの節のリストを取得する
         * @param {string} lawNaturalId 参照される文書のID
         * @param {Array<number>} keys 参照される文書のセクション
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLawKommentars(lawNaturalId: string, keys: Array<number>, options?: any): AxiosPromise<Array<KommentarCitations>> {
            return localVarFp.getLawKommentars(lawNaturalId, keys, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CitationsApi - interface
 * @export
 * @interface CitationsApi
 */
export interface CitationsApiInterface {
    /**
     * 指定した文書のセクションを参照している件数を文書タイプ別に取得する。 件数は文書数ではなく、参照回数。DBでいうと、doc_referenceのレコード数に相当する。 
     * @summary 指定した文書のセクションを参照している件数を文書タイプ別に取得する
     * @param {string} docNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApiInterface
     */
    getCitationsCount(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): AxiosPromise<CitationCounts>;

    /**
     * 指定した文書を参照しているセクション別の件数を配列で文書タイプ別に取得する。 件数は文書数ではなく、参照回数。DBでいうと、doc_referenceのレコード数に相当する。 
     * @summary 指定した文書を参照しているセクション別の件数を配列で文書タイプ別に取得する
     * @param {string} docNaturalId 参照される文書のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApiInterface
     */
    getCitationsCounts(docNaturalId: string, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: CitationCounts; }>;

    /**
     * 指定した文書のセクションを参照している書籍を検索する
     * @summary 指定した文書のセクションを参照している書籍を検索する
     * @param {string} docNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApiInterface
     */
    getCitationsInBook(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): AxiosPromise<Array<BookCitations>>;

    /**
     * 指定した文書のセクションを参照しているガイドラインを検索する
     * @summary 指定した文書のセクションを参照しているガイドラインを検索する
     * @param {string} docNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApiInterface
     */
    getCitationsInGuideline(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): AxiosPromise<Array<GuidelineCitations>>;

    /**
     * 指定した文書のセクションを参照している法令を検索する
     * @summary 指定した文書のセクションを参照している法令を検索する
     * @param {string} docNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApiInterface
     */
    getCitationsInLaw(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): AxiosPromise<Array<LawCitations>>;

    /**
     * 指定した文書のセクションを参照しているパブリックコメントを検索する
     * @summary 指定した文書のセクションを参照しているパブリックコメントを検索する
     * @param {string} docNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApiInterface
     */
    getCitationsInPublicComment(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): AxiosPromise<Array<PublicCommentCitations>>;

    /**
     * 
     * @summary 法令に対応するコンメンタールの節のリストを取得する
     * @param {string} lawNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApiInterface
     */
    getLawKommentars(lawNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig): AxiosPromise<Array<KommentarCitations>>;

}

/**
 * CitationsApi - object-oriented interface
 * @export
 * @class CitationsApi
 * @extends {BaseAPI}
 */
export class CitationsApi extends BaseAPI implements CitationsApiInterface {
    /**
     * 指定した文書のセクションを参照している件数を文書タイプ別に取得する。 件数は文書数ではなく、参照回数。DBでいうと、doc_referenceのレコード数に相当する。 
     * @summary 指定した文書のセクションを参照している件数を文書タイプ別に取得する
     * @param {string} docNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApi
     */
    public getCitationsCount(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig) {
        return CitationsApiFp(this.configuration).getCitationsCount(docNaturalId, keys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した文書を参照しているセクション別の件数を配列で文書タイプ別に取得する。 件数は文書数ではなく、参照回数。DBでいうと、doc_referenceのレコード数に相当する。 
     * @summary 指定した文書を参照しているセクション別の件数を配列で文書タイプ別に取得する
     * @param {string} docNaturalId 参照される文書のID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApi
     */
    public getCitationsCounts(docNaturalId: string, options?: AxiosRequestConfig) {
        return CitationsApiFp(this.configuration).getCitationsCounts(docNaturalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した文書のセクションを参照している書籍を検索する
     * @summary 指定した文書のセクションを参照している書籍を検索する
     * @param {string} docNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApi
     */
    public getCitationsInBook(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig) {
        return CitationsApiFp(this.configuration).getCitationsInBook(docNaturalId, keys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した文書のセクションを参照しているガイドラインを検索する
     * @summary 指定した文書のセクションを参照しているガイドラインを検索する
     * @param {string} docNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApi
     */
    public getCitationsInGuideline(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig) {
        return CitationsApiFp(this.configuration).getCitationsInGuideline(docNaturalId, keys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した文書のセクションを参照している法令を検索する
     * @summary 指定した文書のセクションを参照している法令を検索する
     * @param {string} docNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApi
     */
    public getCitationsInLaw(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig) {
        return CitationsApiFp(this.configuration).getCitationsInLaw(docNaturalId, keys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した文書のセクションを参照しているパブリックコメントを検索する
     * @summary 指定した文書のセクションを参照しているパブリックコメントを検索する
     * @param {string} docNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApi
     */
    public getCitationsInPublicComment(docNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig) {
        return CitationsApiFp(this.configuration).getCitationsInPublicComment(docNaturalId, keys, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary 法令に対応するコンメンタールの節のリストを取得する
     * @param {string} lawNaturalId 参照される文書のID
     * @param {Array<number>} keys 参照される文書のセクション
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CitationsApi
     */
    public getLawKommentars(lawNaturalId: string, keys: Array<number>, options?: AxiosRequestConfig) {
        return CitationsApiFp(this.configuration).getLawKommentars(lawNaturalId, keys, options).then((request) => request(this.axios, this.basePath));
    }
}
