<template>
  <v-app>
    <search-bar ref="searchBar" layout-type="document" />

    <nuxt />
  </v-app>
</template>

<script setup lang="ts">
import { ref, provide } from 'vue';
import SearchBar from '@/components/search-bar.vue';

const searchBar = ref<InstanceType<typeof SearchBar>>();
// /document/_id ではドロワーの表示非表示と連動して search-bar も表示非表示を切り替えたいため provide する
provide('$search-bar', searchBar);
</script>

<style lang="scss" src="./default.scss" />
